import React, { memo } from "react";
import SpinnerWrapper from "../../Views/SpinnerWrapper";
import Spinner from "../../Views/SpinnerWrapper/Spinner";

const LoadingPage = ({...props}) => {

    return (
            <SpinnerWrapper>
                {
                    JSON.stringify(props, null, 4)
                }
                <Spinner />
            </SpinnerWrapper>
    )
}

export default (LoadingPage);
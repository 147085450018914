import React, { Suspense } from 'react';
import LoadingPage from '../LoadingPage';
import BlockSpinner from '../../Views/SpinnerWrapper';

const LazyProductDetails = React.lazy(()=> import('../ProductDetailsPage'))
const LazyNewsDetailsPage = React.lazy(()=> import('../NewsPage/NewsDetailsPage'))
const LazySearchPage = React.lazy(()=> import('../SearchPage/SearchPage'))

const Combine = ({type,...props}) => {
    const PAGE_TYPES = {
        'loading_page': LoadingPage,
        27 : LazyNewsDetailsPage,   //?! страница деталки новости
        4: LazyProductDetails, //? детальная страница продукта http://localhost:3000/product-181-shapkaia-pukhovaia-1
        // 4: ProductDetailsPage, //? детальная страница продукта http://localhost:3000/product-181-shapkaia-pukhovaia-1
        // 30: OrderDetailsPersonalPage, //? деталка заказа
        15: LazySearchPage,
    }
    console.log('test combine')
    let Page = PAGE_TYPES[type];
    if((type === 27 ||
        type === 4 ||
        type === 15) ) {
        return <Suspense fallback={
                    <BlockSpinner.SpinnerWrapper>
                        <BlockSpinner.SpinnerCenter>
                            <BlockSpinner.Spinner sizeWidth={30} sizeHeight={30}/>
                        </BlockSpinner.SpinnerCenter>
                    </BlockSpinner.SpinnerWrapper>
                    }
                >
                    <Page context={props.context} />
                </Suspense>
    }

    Page = PAGE_TYPES['loading_page'];

    // return null;
    return <>kfdhlkhfdlkfdlkjfldjfl</>
}
export default (Combine);

import api from "../../api/api";

export const observerError = store => {
    
    store.on('fixErrorBot', async ({context}, obj) => {
        try{

            const username = context?.init_state?.profile?.user?.username;
            
            const role = context?.init_state?.profile?.role;
            const timeStamp = new Date();
            let params = {
                user: username,
                role: role,
                timeError: timeStamp,
                ...obj
            }
            
            await api.errorApi.errorSendBot(params);
        }catch(err){
            console.log(err)
        }
    })
}

// 
import api from "../../api/api";
import Text from "../../helpers/Text";
import { errorAlertIcon } from "../../images";
import { textErrorMessage } from "../modalStorage/modalWindow/modalWindow";

export const chat = store => {
  const orderApi = api.orderApi;

  store.on('sendMessageProduct', async ({ context, closeModalState }, obj, { dispatch }) => {
    try {
      const { files, message, idProduct, setFieldValue } = obj;

      const fd = new FormData();
      fd.set('order_item_id', idProduct);
      fd.set('message', message);

      if (files && files?.length) {
        for (let i = 0; i < files?.length; i++) {
          fd.append("files", files[i]);
        }
        typeof obj?.func === 'function' && setFieldValue('func', obj?.func([]));
        typeof obj?.funcImage === 'function' && setFieldValue('funcImage', obj?.funcImage([]));
      }
      setFieldValue('upDownBtn', false);
      setFieldValue('message', '');
      setFieldValue('files', []);
      setFieldValue('activeBtnMessageForProduct', true);
      obj.setIsLoaderSendMessage(true)
      await orderApi.postCorrespondence_order_item(fd);
      const timer = setTimeout(()=>{
        console.log('send chat message')
        dispatch('updateGetContextPage',{url: window.location.pathname})
        return clearTimeout(timer)
      },1000)
      obj.setIsLoaderSendMessage(false)
    } catch (err) {
      obj.setIsLoaderSendMessage(false)
      console.log('ERROR send message product f(sendMessageProduct) ', err);
      let error = [Text({ text: 'error-on-server' }), 'ERROR send message product f(sendMessageProduct) '];
      if (err?.data) {
        const errors = err.data;
        if (typeof errors !== 'object') {
          error.push(`${errors}`)
        } else {
          error.push(`${errors[0]}`)
        }
      }
      // dispatch('fixErrorBot',{...error, err})

      dispatch('setModalState', {
        show: true,
        content: textErrorMessage(error),
        iconImage: errorAlertIcon,
        addClass: 'modal-alert-error',
        action: {
          title: ['продолжить', null]
        },
        onClick: () => closeModalState()
      })
    }
  })

  store.on('sendMessageChatProduct', async ({ context, closeModalState }, obj, { dispatch }) => {

    try {
      const { files, message, idProduct, setFieldValue } = obj;
      const fd = new FormData();
      fd.set('order', idProduct);
      fd.set('message', message);
      if (files && files?.length) {
        for (let i = 0; i < files?.length; i++) {
          fd.append("files", files[i]);
        }
      }
      setFieldValue('activeBtnMessageForProduct', true);
      setFieldValue('message', '');
      setFieldValue('upDownBtn', false);
      setFieldValue('files', []);
      typeof obj?.func === 'function' && setFieldValue('func', obj?.func([]));
      typeof obj?.funcImage === 'function' && setFieldValue('funcImage', obj?.funcImage([]));
      obj.setIsLoaderSendMessage(true)
      const res = await orderApi.postCorrespondence(fd);
      const timer = setTimeout(()=>{
        console.log('send chat message')
        dispatch('updateGetContextPage',{url: window.location.pathname})
        return clearTimeout(timer)
      },1000)
      obj.setIsLoaderSendMessage(false)
    } catch (err) {
      console.log('ERROR send message chat product f(sendMessageChatProduct) ', err);
      let error = [Text({ text: 'error-on-server' }), 'ERROR send message chat product f(sendMessageChatProduct) '];
      if (err?.data) {
        const errors = err.data;
        if (typeof errors !== 'object') {
          error.push(`${errors}`)
        } else {
          error.push(`${errors[0]}`)
        }
      }
      // dispatch('fixErrorBot',{...error, err})

      dispatch('setModalState', {
        show: true,
        content: textErrorMessage(error),
        iconImage: errorAlertIcon,
        addClass: 'modal-alert-error',
        action: {
          title: ['продолжить', null]
        },
        onClick: () => closeModalState()
      })
    }
  })
}

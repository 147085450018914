import "./App.scss";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { routes } from "./routes/routes";
import React, {Suspense, useEffect, useState} from "react";
import Layout from "../src/Pages/Layout";
import { useStoreon } from "storeon/react";
import { getCookie, setCookie } from "./helpers/helpers";
import api from "./api/api";
import BlockSpinner from './Views/SpinnerWrapper';
import axios from "axios";

let source;

const App = () => {
  const { context, dispatch } = useStoreon("context");
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const [newRequest, setNewRequest] = useState('');

  const token_ios = getCookie("token_ios");
  const ft_token = getCookie("ft_token");
  const location = useLocation();
  const navigate = useNavigate();
  const regimIos = async (token, model = "safari") => {
    const params = {
      browser: "safari",
      registration_iosid: token,
    };
    const regIos = await api.registerIosToken(params);
    console.log({ regIos });
  };

  useEffect(() => {
    document.querySelector(".goto")?.scrollIntoView({ block: "center", behavior: "smooth" });
    if (ft_token && token_ios) {
      regimIos(token_ios);
    }
  }, [token_ios, ft_token]);

  useEffect(() => {
    let timerReset;
    let path = location.pathname;
    dispatch("setIsMobile", { isMobile });

    if (window.sessionStorage.getItem("ft_token"))
      setCookie("ft_token", window.sessionStorage.getItem("ft_token"));
    if (!!location.search) {
      path = `${location.pathname}${location.search}`;
    }

    if (newRequest !== path) {
      setNewRequest(path);
    }
    timerReset = setTimeout(()=>{
      document.querySelector(".goto")?.scrollIntoView({ block: "start", behavior: "smooth" });  
    },1500)
    return () => {
      dispatch('closeNotification');
      dispatch('closeCorrespondence');
      clearTimeout(timerReset);
    };
  }, [location.pathname, location.search, newRequest]);

  useEffect(() => {
    if (newRequest) {
      if (Object.keys(context).length) {
       let newContext = {
              "init_state": {
                  ...JSON.parse(JSON.stringify(context.init_state)),
                  page_info: {
                      ...JSON.parse(JSON.stringify(context.init_state.page_info)),
                      content: ''
                  }
              }
          }
          dispatch('context', newContext);
      }


      source = axios.CancelToken.source();
      dispatch("getContextPage", {
        url: newRequest,
        redirectTo: (path) => navigate(path),
        source: source
      });
    }
    return function cleanup() {
      source && source.cancel('пробуем отменить предыдущий')
    };
  }, [newRequest])
  return (
    <Routes>
      <Route path={"/"} element={<Layout context={context} />}>
        {routes.routes.map(
          ({ path, index, fetchInitialData, component: C }) => {
            return (
              <Route
                key={path}
                index={index}
                path={path}
                element={
                  <Suspense fallback={
                    <BlockSpinner.SpinnerWrapper>
                    <BlockSpinner.SpinnerCenter>
                      <BlockSpinner.Spinner size={30} />
                    </BlockSpinner.SpinnerCenter>
                  </BlockSpinner.SpinnerWrapper>
                    // <Spinner />
                  }>
                    <C
                      context={context.init_state}
                      type={context.type}
                      url={location.pathname}
                      fetchInitialData={fetchInitialData}
                    />
                    {/* <BlockSpinner.SpinnerWrapper>
                    <BlockSpinner.SpinnerCenter>
                      <BlockSpinner.Spinner size={40} />
                    </BlockSpinner.SpinnerCenter>
                  </BlockSpinner.SpinnerWrapper> */}
                  </Suspense>
                }
              />
            );
          }
        )}
      </Route>
    </Routes>
  );
};

export default App;

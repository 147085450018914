import React from 'react';
import style from '../styles/grid.module.scss';

const BlockAddAddressContainerPhone = ({ children, label, helpText }) => {

    return <React.Fragment>

        <label
          className={style['block-add-address__label']}
        >{label}</label>
    <div
        className = {style['block-add-address__conteiner-phone']}
        >{children}
    {helpText? helpText : null}
    </div>
        </React.Fragment>
}

export default BlockAddAddressContainerPhone;
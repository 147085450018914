import api from "../../api/api";
import { ROLE } from "../../const";
import Text from "../../helpers/Text";
import { errorAlertIcon } from "../../images";
import { textErrorMessage } from "../modalStorage/modalWindow/modalWindow";

export const wishList = store => {
    const apiProfile = api.profileApi;

    store.on('@init', () => ({ countWishList: 0 }));
    store.on('@init', () => ({
        paramsWishList: {
            page_size: 30,
            page: 1
        }
    }));

    store.on('setParamsWishlist', ({ paramsWishList }, obj, { dispatch }) => {
        return (
            {
                paramsWishList: {
                    ...paramsWishList,
                    page_size: obj.page_size,
                    page: obj.page
                }
            }
        )
    })

    store.on('getWishlist', async ({ context, closeModalState, wishListStore, paramsWishList }, obj, { dispatch }) => {
        try {
            const params = {
                page_size: paramsWishList.page_size ?? 30,
                page: obj?.page ?? paramsWishList.page ?? 1
            }
            let res = await apiProfile.getWishlist(params);

            dispatch('setParamsWishlist', { ...params });
            dispatch('setDataWishListStore', {
                count: res?.count ?? 0,
                results: [...res?.results] ?? [],
                isLoadingListMyWish: true,
                isLoadingMywish: false,
            });
        } catch (err) {
            console.log('ERROR IN GET DATA list_wishes STORE f(getWishlist)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR IN GET DATA list_wishes STORE f(getWishlist)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }

    })

    store.on('addWishList', async ({ context, closeModalState, wishListStore, productStore, productYouAlredyWatchStore, recommendedProductsStore, paramsDataProduct }, obj, { dispatch }) => {

        try {
            const { role } = context.init_state.profile;
            if (role === ROLE.UNREGISTRED) return (
                dispatch('setModalState', {
                    show: true,
                    content: (
                        <div className={'modal-message'}>
                            Чтобы полноценно воспользоваться всеми возможностями сотрудничества, необходимо пройти регистрацию
                        </div>
                    ),
                    iconImage: errorAlertIcon,
                    action: {
                        title: ['Пройти регистрацию', null]
                    },
                    onClick: () => {
                        obj.redirectTo('/registration')
                        closeModalState()
                    }
                })
            )
            let newDataProductsResults = productStore?.results || [];
            let newDataProductsResultsYouAlredyWatch = productYouAlredyWatchStore?.results || [];
            let recommended = recommendedProductsStore.results || [];
            const params = {
                product: obj.id,
            }
            let newContext = {
                ...context,
                "init_state": {
                    ...context.init_state,
                }
            }

            dispatch('setDataCatalogStore', {
                isLoadingMyWish: true,
            })
            dispatch('setDataYouAlredyWatchStore', {
                isLoadingMyWish: true,
            })

            dispatch('setDataproducRecomendetStore', {
                isLoadingMyWish: true,
            })
            dispatch('setDataWishListStore', {
                isLoadingMyWish: true,
            })
            await apiProfile.postWishlist(params);

            switch (obj.whereLike) {
                case 'product':
                    newDataProductsResults = newDataProductsResults.map(el => el.id === obj.id ? { ...el, is_liked: true } : el);
                    newDataProductsResultsYouAlredyWatch = newDataProductsResultsYouAlredyWatch.map(el => el.product.id === obj.id ? {
                        ...el,
                        product: {
                            ...el.product,
                            is_liked: true
                        }
                    }
                        : el);
                    recommended = recommended.map(el => el.id === obj.id ? { ...el, is_liked: true } : el);

                    if (newDataProductsResults && newDataProductsResults.map(el => el.id === obj.id && el.is_liked).includes(true)) {
                        dispatch('setDataCatalogStore', {
                            results: [...newDataProductsResults],
                            isLoadingMyWish: false,
                        });
                        dispatch('setDataYouAlredyWatchStore', {
                            isLoadingMyWish: false,
                        });
                        dispatch('setDataproducRecomendetStore', {
                            isLoadingMyWish: false,
                        });
                        dispatch('setDataWishListStore', {
                            isLoadingMyWish: false,
                        })
                    };

                    if (newDataProductsResultsYouAlredyWatch && newDataProductsResultsYouAlredyWatch.map(el => el.product.id === obj.id && el.product.is_liked).includes(true)) {
                        dispatch('setDataYouAlredyWatchStore', {
                            results: [...newDataProductsResultsYouAlredyWatch],
                            isLoadingMyWish: false,
                        });
                        dispatch('setDataCatalogStore', {
                            isLoadingMyWish: false,
                        });
                        dispatch('setDataproducRecomendetStore', {
                            isLoadingMyWish: false,
                        });
                        dispatch('setDataWishListStore', {
                            isLoadingMyWish: false,
                        })
                    };

                    if (recommended && recommended.map(el => el.id === obj.id).includes(true)) {
                        dispatch('setDataproducRecomendetStore', {
                            results: [...recommended],
                            isLoadingMyWish: false,
                        });
                        dispatch('setDataCatalogStore', {
                            isLoadingMyWish: false,
                        });
                        dispatch('setDataYouAlredyWatchStore', {
                            isLoadingMyWish: false,
                        });
                        dispatch('setDataWishListStore', {
                            isLoadingMyWish: false,
                        })
                    };
                    break;
                case 'detail-product':
                    dispatch('setDataProductDetailsStore', { ...paramsDataProduct, is_liked: true })
                    dispatch('setDataCatalogStore', {
                        isLoadingMyWish: false,
                    });
                    dispatch('setDataYouAlredyWatchStore', {
                        isLoadingMyWish: false,

                    });
                    dispatch('setDataproducRecomendetStore', {
                        isLoadingMyWish: false,
                    });
                    dispatch('setDataWishListStore', {
                        isLoadingMyWish: false,
                    })
                    break;
                default:

            }

            newContext = {
                ...newContext,
                "init_state": {
                    ...newContext.init_state,
                    profile: {
                        ...newContext.init_state.profile,
                        wishlist: +newContext.init_state.profile.wishlist + 1
                    }
                },
            }

            dispatch('context', newContext);


            if (obj?.pathname === '/wishlist') {
                const timerSetTimout = setTimeout(() => {
                    dispatch('getWishlist')
                    return () => clearTimeout(timerSetTimout)
                }, 400)
            }



        } catch (err) {
            console.log('Error add wish list f(addWishList)', err)
            let error = [Text({ text: 'error-on-server' }), 'Error add wish list f(addWishList)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setDataCatalogStore', {
                ...productStore,
                isLoadingMyWish: false,
            })
            dispatch('setDataYouAlredyWatchStore', {
                ...productYouAlredyWatchStore,
                isLoadingMyWish: false,
            })

            dispatch('setDataproducRecomendetStore', {
                ...recommendedProductsStore,
                isLoadingMyWish: false,
            })
        }
    })

    store.on('removeWishList', async ({ context, closeModalState, wishListStore, productStore, productYouAlredyWatchStore, recommendedProductsStore, paramsDataProduct }, obj, { dispatch }) => {

        try {
            const { role } = context.init_state.profile;
            if (role === ROLE.UNREGISTRED) return (
                dispatch('setModalState', {
                    show: true,
                    content: (
                        <div className={'modal-message'}>
                            Чтобы полноценно воспользоваться всеми возможностями сотрудничества, необходимо пройти регистрацию
                        </div>
                    ),
                    iconImage: errorAlertIcon,
                    action: {
                        title: ['Пройти регистрацию', null]
                    },
                    onClick: () => {
                        obj.redirectTo('/registration')
                        closeModalState()
                    }
                })
            )
            let newDataProductsResults = productStore?.results || [];
            let newDataProductsResultsYouAlredyWatch = productYouAlredyWatchStore?.results || [];
            let recommended = recommendedProductsStore.results || [];
            let wishList = wishListStore.results;
            let newContext = {
                ...context,
                "init_state": {
                    ...context.init_state,
                }
            }

            dispatch('setDataCatalogStore', {
                isLoadingMyWish: true,
            })
            dispatch('setDataYouAlredyWatchStore', {
                isLoadingMyWish: true,
            })
            dispatch('setDataproducRecomendetStore', {
                isLoadingMyWish: true,
            })
            dispatch('setDataWishListStore', {
                isLoadingMyWish: true,
            })
            await apiProfile.deleteWishlist(obj.id);
            switch (obj.whereLike) {
                case 'product':
                    newDataProductsResults = newDataProductsResults.map(el => el.id === obj.id ? { ...el, is_liked: false } : el);
                    newDataProductsResultsYouAlredyWatch = newDataProductsResultsYouAlredyWatch.map(el => el.product.id === obj.id ? {
                        ...el,
                        product: {
                            ...el.product,
                            is_liked: false
                        }
                    } : el);
                    recommended = recommended.map(el => el.id === obj.id ? { ...el, is_liked: false } : el)

                    if (newDataProductsResults && newDataProductsResults.map(el => el.id === obj.id && !el.is_liked).includes(true)) {
                        dispatch('setDataCatalogStore', {
                            results: [...newDataProductsResults],
                            isLoadingMyWish: false,
                        });
                        dispatch('setDataYouAlredyWatchStore', {
                            isLoadingMyWish: false,
                        });
                        dispatch('setDataproducRecomendetStore', {
                            isLoadingMyWish: false,
                        });
                    }



                    if (newDataProductsResultsYouAlredyWatch && newDataProductsResultsYouAlredyWatch.map(el => el.product.id === obj.id && !el.product.is_liked).includes(true)) {
                        dispatch('setDataYouAlredyWatchStore', {
                            results: [...newDataProductsResultsYouAlredyWatch],
                            isLoadingMyWish: false,
                        });
                        dispatch('setDataCatalogStore', {
                            isLoadingMyWish: false,
                        });
                        dispatch('setDataproducRecomendetStore', {
                            isLoadingMyWish: false,
                        });
                    }
                    if (recommended && recommended.map(el => el.id === obj.id).includes(true)) {
                        dispatch('setDataproducRecomendetStore', {
                            results: [...recommended],
                            isLoadingMyWish: false,
                        });
                        dispatch('setDataCatalogStore', {
                            isLoadingMyWish: false,
                        });
                        dispatch('setDataYouAlredyWatchStore', {
                            isLoadingMyWish: false,
                        });
                    }


                    if (wishList && wishList.map(el => el.product.id === obj.id).includes(true)) {
                        dispatch('setDataWishListStore', {
                            results: [...wishList.filter(el => el.product.id !== obj.id)],
                            count: +wishListStore.count - 1,
                            isLoadingMyWish: false,
                        });
                    }
                    break;
                case 'detail-product':
                    dispatch('setDataProductDetailsStore', { ...paramsDataProduct, is_liked: false })
                    dispatch('setDataCatalogStore', {
                        isLoadingMyWish: false,
                    })
                    dispatch('setDataYouAlredyWatchStore', {
                        isLoadingMyWish: false,
                    })

                    dispatch('setDataproducRecomendetStore', {
                        isLoadingMyWish: false,
                    })
                    dispatch('setDataWishListStore', {
                        results: [...wishList.filter(el => el.product.id !== obj.id)],
                        count: +wishListStore.count - 1,
                        isLoadingMyWish: false,
                    })
                    break;
                default:
            }
            newContext = {
                ...newContext,
                "init_state": {
                    ...newContext.init_state,
                    profile: {
                        ...newContext.init_state.profile,
                        wishlist: +newContext.init_state.profile.wishlist - 1
                    }
                },
            }
            dispatch('context', newContext);
            if (obj?.pathname === '/wishlist' && wishList.length === 1) {
                dispatch('updateDataRecommendedStore');
            }

        } catch (err) {
            console.log('Error delete from wish list f(removeWishList)', err)
            let error = [Text({ text: 'error-on-server' }), 'Error delete from wish list f(removeWishList)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setDataCatalogStore', {
                isLoadingMyWish: false,
            })
            dispatch('setDataYouAlredyWatchStore', {
                isLoadingMyWish: false,
            })

            dispatch('setDataproducRecomendetStore', {
                isLoadingMyWish: false,
            })

        }
    })

    // ------------------------------------------------------------------
    const initValueWishList = {
        count: 0,
        results: [],
        isLoadingMyWish: false,
        isLoadingListMyWish: false,
    }
    store.on('@init', () => ({ wishListStore: { ...initValueWishList } }));
    store.on('setDataWishListStore', ({ wishListStore }, obj) => ({
        wishListStore:
        {
            ...wishListStore,
            ...obj,
        }
    }))
}
import api from '../../api/api';
import { initialValuesRegistration } from '../../helpers/initialValues/initialValues';
import Text from '../../helpers/Text';
import { errorAlertIcon } from '../../images';
import { textErrorMessage } from '../modalStorage/modalWindow/modalWindow';

export const authorization = store => {
    const apiUser = api.userApi;
    const initAuth = {};
    store.on('@init', () => ({ logout: false }));
    store.on('@init', () => ({ authorization: initAuth }))
    //?! вход в аккаунт
    store.on('loginIn', async ({ closeModalState }, obj, { dispatch }) => {
        const { username, password, redirectTo } = obj;
        try {
            const params = {
                username: username,
                password: password
            }
            const res = await apiUser.loginByUsername(params);
            if (!!res?.token) {
                redirectTo('/catalog')
            } else {
                obj?.setLoading(false);
                let error = ['Произошла ошибка', 'не корректно введены данные для авторизации'];
                if (res?.error) {
                    const errors = res?.error;
                    if (typeof errors !== 'object') {
                        error.push(`${errors}`)
                    } else {
                        error.push(`${errors[0]}`)
                    }
                }
                dispatch('setModalState', {
                    show: true,
                    content: textErrorMessage(error),
                    iconImage: errorAlertIcon,
                    addClass: 'modal-alert-error',
                    action: {
                        title: ['продолжить', null]
                    },
                    onClick: () => closeModalState()
                })
            }

        } catch (err) {
            console.log('ERROR log in f(loginIn)', err);
            obj?.setLoading(false)
            if (err.status === 400) {
                let error = ['Произошла ошибка', 'ERROR log in f(loginIn)', 'не корректно введены данные для авторизации'];
                if (err?.error) {
                    const errors = err?.error;
                    if (typeof errors !== 'object') {
                        error.push(`${errors}`)
                    } else {
                        error.push(`${errors[0]}`)
                    }
                }
                dispatch('fixErrorBot', { ...error, err, username })
                dispatch('setModalState', {
                    show: true,
                    content: textErrorMessage(error),
                    iconImage: errorAlertIcon,
                    addClass: 'modal-alert-error',
                    action: {
                        title: ['продолжить', null]
                    },
                    onClick: () => closeModalState()
                })
            }
        }
    })
    //?! Выход с аккаунта
    store.on('logoutOut', async ({ logout, closeModalState }, obj, { dispatch }) => {
        try {
            const { redirectTo } = obj;
            dispatch('setModalState', {
                show: true
            })
            logout = await apiUser.logout();
            window.sessionStorage.setItem('profile', '');
            window.sessionStorage.setItem('ft_token', '');
            dispatch('setDataRegistration', initialValuesRegistration)
            redirectTo('/authorization');
            dispatch('setModalState', {
                show: false
            })
        } catch (err) {
            console.log('ERROR EXIT LOGOUT f(logoutOut)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR EXIT LOGOUT f(logoutOut)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})
            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

}
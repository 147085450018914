import React from 'react';
import classNames from 'classnames';

import styleIcon from './icon.module.scss';


        /**
         * @param {
         * pointerEvents - действие кликабельности по иконе
         * width - ширина иконки (изображения)
         * height - высота иконки (изображения)
         * invert - в процентах, инвинтирование изображения
         * }
         */


const Icon = ({
    invert = '0%', 
    opacity = '1',
    brightness = '1',
    fill = '',
    onClick, 
    className,
    slot,
    bodrad,
    disabled = false,
    id,
    ref, 
    src, 
    width = 50, 
    height = 50, 
    filter, 
    pointerEvents,
    rotateY = 0,
    rotateX = 0,
    rotate = 0,
    scale=1,
    mr=0,
    ml=0,
    mt=0,
    mb=0,
    ...props}) => {

    const  styleIcons = classNames({
        [styleIcon['icons']]: true,
        [styleIcon['disabled']] : disabled,
        [styleIcon[slot]]: !!slot,
        [className] : !!className,
    })

    return(
        <div 
            id = { id }
            ref={ ref }
            onClick={ onClick }
            className={ styleIcons }
            style={{
                backgroundImage: `url(${src})`,
                width: `${width}px`,
                height:`${height}px`,
                filter: `invert(${invert}) opacity(${opacity}) brightness(${brightness})`,
                pointerEvents: pointerEvents,
                borderRadius: `${bodrad}%`,
                fill: `${fill}`,
                transform: `rotateY(${rotateY}deg) rotateX(${rotateX}deg) rotate(${rotate}deg) scale(${scale})`,
                marginRight: `${mr}px`,
                marginLeft: `${ml}px`,
                marginTop: `${mt}px`,
                marginBottom: `${mb}px`,
            }}            
        >
        </div>
    )
}

export default Icon;


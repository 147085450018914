import api from '../../api/api';
import Text from '../../helpers/Text';
import { errorAlertIcon } from '../../images';
import { textErrorMessage } from '../modalStorage/modalWindow/modalWindow';
import { delay } from '../../helpers/helpers';
import { ROLE } from '../../const';

export const balance = store => {
    const apiOrder = api.orderApi;
    const initBalanceStore = {
        balance: 0,
        confirm_payments_cost: 0,
        currency: "RUB",
        opt_minimum_price: 0,
        opt_text: "Здесь будет то то",
        passive_balance: 0,
        total_orders_price_paid: 0,
        total_orders_price_unpaid: 0,
    }
    store.on('@init', () => ({ balanceStore: initBalanceStore }));
    store.on('setBalanceStore', ({ }, obj, { }) => ({ balanceStore: { ...obj } }));


    store.on('updateObserverBalace', async ({ context, closeModalState, currency, orderStore, checkPrevObject }, obj, { dispatch }) => {
        try {
            if (context.init_state.profile.role === ROLE.UNREGISTRED) return;
            dispatch('setCheckPrevObject', obj);
            if (checkPrevObject === JSON.stringify(obj)) return;
            const timer = setTimeout(() => {
                console.log('обнуляем повторение')
                dispatch('setCheckPrevObject', {});
                return clearTimeout(timer);
            }, 3000);

            const res = await api.getUserBalance({ "currency": obj?.currency ?? currency });

            dispatch('setBalanceStore', res);

            // dispatch('context', newContext);
            if (window.location.pathname === '/orders') {
                await delay(5000)
                // dispatch('getOrders');
            }
            if (window.location.pathname === '/notifications') {
                await delay(3000)
                dispatch('notification');
            }
            if (window.location.pathname === '/balance') {
                dispatch('getPayments')
            }
            if (window.location.pathname.includes('/orders/20') || window.location.pathname.includes('/20')) {
                await delay(3000)
                dispatch('updateGetDataOrder')
            }
            if (!orderStore.deleteProductInOrder) {
                // todo check chat after deleting
                //await delay(3000)
                //dispatch('correspondence');
            }

        } catch (err) {
            console.log('ERROR GET BALANCE', err);
            let error = [Text({ text: 'error-on-server' }), 'ERROR GET BALANCE func(updateObserverBalace)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })
    store.on('getBalace', async ({ context, closeModalState, currency, checkPrevObject }, obj, { dispatch }) => {
        try {
            dispatch('setCheckPrevObject', obj);
            if (window.sessionStorage.getItem('profile')?.role === ROLE.UNREGISTRED) return;

            if (checkPrevObject === JSON.stringify(obj)) return;
            const timer = setTimeout(() => {
                console.log('обнуляем повторение')
                dispatch('setCheckPrevObject', {});
                return clearTimeout(timer);
            }, 3000);

            const res = await api.getUserBalance({ "currency": obj?.currency ?? currency });

            dispatch('setBalanceStore', res);

        } catch (err) {
            console.log('ERROR GET BALANCE func(getBalace)', err);
            let error = [Text({ text: 'error-on-server' }), 'ERROR GET BALANCE func(getBalace)', JSON.stringify(err)];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            dispatch('fixErrorBot', { ...error })

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    // ---------------------------------------------------------------------------------------------------------------
    const initPaymentHistory = {
        count: 0,
        currentPage: 1,
        results: [],
        isLoadingArchivePayments: false,
    }
    store.on('@init', () => ({ paymentsHistory: initPaymentHistory }))
    store.on('setPaymentsHistory', ({ _ }, obj) => ({
        paymentsHistory: {
            ...obj,
            results: [...obj.results]
        }
    }))
    store.on('getPayments', async ({ context, closeModalState }, obj, { dispatch }) => {
        try {
            const params = {
                page: obj?.page ?? 1,
                page_size: 10
            };
            const res = await apiOrder.getPaymentsProfile(params);
            dispatch('setPaymentsHistory', {
                ...res,
                currentPage: obj?.page ?? 1,
                isLoadingArchivePayments: true
            });

        } catch (err) {
            console.log('ERROR GET PAYMENTS', err)
            let error = [Text({ text: 'error-on-server' }), 'setPaymentsHistory func(getPayments)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`);
                } else {
                    error.push(`${errors[0]}`);
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    });
}

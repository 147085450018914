import axios from "axios";
import api from "../../api/api";
import { ROLE } from "../../const";
import { delay } from "../../helpers/helpers";

import { initValueCheckBoxFilters } from "../../helpers/initialValues/initialValues";
import Text from "../../helpers/Text";
import { errorAlertIcon } from "../../images";
import { textErrorMessage } from "../modalStorage/modalWindow/modalWindow";
import { currency } from "../currency/currency";

export const catalog = store => {
    const apiContent = api.contentApi;
    const apiProfile = api.profileApi;

    store.on('@init', ({ page }) => ({ page: 1 }))
    store.on('setPage', ({ _ }, obj, { dispatch }) => ({ page: obj.page }));

    store.on('@init', () => ({ pageCatalogIsInStock: 1 }))
    store.on('setPageCatalogIsInStock', ({ _ }, obj, { dispatch }) => ({ pageCatalogIsInStock: obj.page }));

    store.on('@init', () => ({ pageCatalog: 1 }))
    store.on('setPageCatalog', ({ }, obj, { dispatch }) => ({ pageCatalog: obj.page }));

    store.on('@init', () => ({ valueFilters: initValueCheckBoxFilters }));
    store.on('changeParamsFiltersNew', ({ valueFilters }, obj, { dispatch }) => {
        try {
            const params = {
                ...valueFilters,
                ...obj
            };

            dispatch('setDataCatalogStore', {
                isLoadingCatalog: false,
            });

            console.log('get catalog for change filters ', !obj?.saveFilters)
            if(!obj?.saveFilters){
                console.log('get catalog for change filters 2')
                dispatch('getCatalog', params);
            }
            
            dispatch('setDataCatalogStore', {
                isLoadingCatalog: true,
            });
            return ({ valueFilters: { ...valueFilters, ...obj, saveFilters:false } })
        } catch (err) {
            console.log('ERROR changeParamsFilters func(changeParamsFiltersNew)', err)
            dispatch('fixErrorBot', { error: 'ERROR changeParamsFilters func(changeParamsFiltersNew)', err })
        }
    })

    store.on('changeParamsFiltersPhoto', ({ context, productStore }, obj, { dispatch }) => {
        try {
            const newContext = {
                ...context,
                "init_state": {
                    ...context.init_state,
                    filters_params: {
                        ...context.init_state.filters_params,
                        ...obj.valueCheckBoxFilters,

                        is_in_stock: obj.valueCheckBoxFilters.is_in_stock,
                        is_new: obj.valueCheckBoxFilters.is_new,
                        is_bestseller: obj.valueCheckBoxFilters.is_bestseller,
                        is_closeout: obj.valueCheckBoxFilters.is_closeout,

                        is_polish: obj.valueCheckBoxFilters.is_polish,
                        is_import: obj.valueCheckBoxFilters.is_import,

                        categories: [...obj.valueCheckBoxFilters.categories],
                        brands: [...obj.valueCheckBoxFilters.brands],
                        colors: [...obj.valueCheckBoxFilters.colors],
                        sizes: [...obj.valueCheckBoxFilters.sizes],
                        type: [...obj.valueCheckBoxFilters.type]
                    }
                },
            }
            dispatch('context', newContext)
            dispatch('getExportCatalog', { ...obj.valueCheckBoxFilters })
        } catch (err) {
            console.log('ERROR changeParamsFiltersPhoto func(changeParamsFiltersPhoto)', err);
            dispatch('fixErrorBot', { error: 'ERROR changeParamsFiltersPhoto func(changeParamsFiltersPhoto)', err })
        }

    })

    store.on('getCatalog', async ({ context, pageCatalog, productYouAlredyWatchStore, valueFilters, isMobile }, obj, { dispatch }) => {
        try {
            obj?.setIsLoadingPagination && obj?.setIsLoadingPagination(true)
            let params = {};
            console.log({valueFilters},{obj})
            if ( obj && Object.keys(obj)){
                dispatch('changeParamsFiltersNew', {
                    ...obj,
                    saveFilters: true
                })
            }
           
            if (!!!obj) obj = { ...valueFilters };
            if (obj) {
                if (obj['is_import'] && !obj['is_polish']) {
                    delete obj['is_polish']
                }
                if (obj['is_polish'] && !obj['is_import']) {
                    delete obj['is_import']
                }
                if (obj?.is_import && obj?.is_polish) {
                    delete obj['is_polish']
                    delete obj['is_import']
                }
                if (!obj?.is_import && !obj?.is_polish && obj?.is_polish !== undefined && obj?.is_import !== undefined) {
                    obj['is_import'] = true
                    obj['is_polish'] = true
                }

                if (obj['is_not_range'] && !obj['is_in_collection']) {
                    delete obj['is_in_collection']
                }
                if (obj['is_in_collection'] && !obj['is_not_range']) {
                    delete obj['is_not_range']
                }
                if (obj?.is_in_collection && obj?.is_not_range) {
                    obj['is_not_range'] = true
                    obj['is_in_collection'] = true
                }
                if (!obj?.is_in_collection && !obj?.is_not_range && obj?.is_in_collection !== undefined && obj?.is_not_range !== undefined) {
                    delete obj['is_in_collection']
                    delete obj['is_not_range']
                }

                
                params = {
                    page: obj?.page ? obj.page : pageCatalog ?? 1,
                    page_size: obj?.page_size ? obj.page_size : isMobile ? 10 : 30,
                    ...obj,
                }
                // categories
                if(!!obj?.categories.length){
                    params = {
                        ...params,
                        categories: obj.categories.join(',')
                    }
                }
                // brands
                if(!!obj?.brands.length){
                    params = {
                        ...params,
                        brands: obj.brands.join(',')
                    }
                }
                // colors
                if(!!obj?.colors.length){
                    params = {
                        ...params,
                        colors: obj.colors.join(',')
                    }
                }
                // sizes
                if(!!obj?.sizes.length){
                    params = {
                        ...params,
                        sizes: obj.sizes.join(',')
                    }
                }
                // type
                if(!!obj?.type.length){
                    params = {
                        ...params,
                        type: obj.type.join(',')
                    }
                }
            }

            let curPage = 1;
            if (obj?.page > 1) {
                dispatch('setPageCatalog', { page: obj.page })
                curPage = obj.page
                if (curPage !== pageCatalog) {
                    dispatch('setDataCatalogStore', {
                        count: 0,
                        results: [],
                        currentPage: curPage,
                        isLoadingCatalog: true,
                        isLoadingMywish: true,
                    });
                }
            } else {
                dispatch('setPageCatalog', { page: 1 });
            }
            console.time('получение каталога на странице каталог')
            console.log({params})
            const products = await apiContent.getCatalogData(params);
            console.timeEnd('получение каталога на странице каталог')
            obj?.setIsLoadingPagination && obj?.setIsLoadingPagination(false)
            dispatch('setDataCatalogStore', {
                ...products,
                currentPage: curPage,
                isLoadingCatalog: true,
                isLoadingMywish: true,
            });
            // dispatch('context', newContext)
            await delay(7000);
            if (!productYouAlredyWatchStore.length){
                dispatch('getYouAlreadyWatch');
            }
        } catch (err) {
            if (err === undefined) dispatch('getCatalog')
            if (obj?.page > 1) dispatch('setPageCatalog', { page: obj.page - 1 });

            console.log('ERROR getCatalog STORE f(getCatalog)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR getCatalog STORE f(getCatalog)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // // dispatch('fixErrorBot',{...error, err})

            if (context.init_state.profile.role === ROLE.UNREGISTRED) return;
            // dispatch('setModalState', {
            //     show: true,
            //     content: textErrorMessage(error),
            //     iconImage: errorAlertIcon,
            //     addClass: 'modal-alert-error',
            //     action: {
            //         title: ['продолжить', null]
            //     },
            //     onClick: () => closeModalState()
            // })
        }
    })



    store.on('getCatalogIsInStock', async ({ context, closeModalState, inStockStore, pageCatalogIsInStock }, obj, { dispatch }) => {
        try {
            let params = {};
            params = {
                page: obj?.page ? obj.page : 1,
                page_size: obj?.page_size ? obj.page_size : 30,
                ...obj,
            }
            obj?.page > 1 ? dispatch('setPageCatalogIsInStock', { page: obj.page }) : dispatch('setPageCatalogIsInStock', { page: 1 });
            dispatch('setInStockStore', { ...inStockStore, isLoading: true });
            const products = await apiContent.getCatalogData(params);
            dispatch('setInStockParams', params);
            dispatch('setInStockStore', {
                ...products,
                currentPage: pageCatalogIsInStock,
                isLoading: false,
            });
        } catch (err) {
            if (obj?.page > 1) dispatch('setPageCatalogIsInStock', { page: obj.page - 1 });

            console.log('ERROR getCatalog STORE f(getCatalogIsInStock)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR getCatalog STORE f(getCatalogIsInStock)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})
            if (context.init_state.profile.role === ROLE.UNREGISTRED) return;
            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('getYouAlreadyWatch', async ({ context, closeModalState, productYouAlredyWatchStore, currency }, obj, { dispatch }) => {
        try {
            const role = context?.init_state?.profile?.role;
            if (role === ROLE.UNREGISTRED || role === undefined) return;
            if (productYouAlredyWatchStore.results.length && !obj?.currency) return;
            const res = await apiProfile.getAlreadySaw();

            dispatch('setDataYouAlredyWatchStore', {
                ...res,
                isLoadingMywish: true
            });
        } catch (err) {
            console.log('Sorry something went wrong :) f(getYouAlreadyWatch) ', err)
            if (err === undefined) return;
            let error = [Text({ text: 'error-on-server' }), 'Sorry something went wrong :) f(getYouAlreadyWatch) '];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            dispatch('fixErrorBot', { role: context.init_state.profile.role, ...error, err })
            if (context.init_state.profile.role === ROLE.UNREGISTRED) return;
            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('getExportCatalog', async ({ context, closeModalState, productStore }, obj, { dispatch }) => {
        try {
            let params = {};
            let paramsExport = {};
            let statusPolish = true;
            let statusImport = true;
            let statusNotRange = false;
            let statusCollection = false;
            if (obj) {
                // delete obj['ordering'];
                if (obj['is_import'] && !obj['is_polish']) {
                    delete obj['is_polish']
                    statusPolish = false;
                }
                if (obj['is_polish'] && !obj['is_import']) {
                    delete obj['is_import']
                    statusImport = false;
                }
                if (obj?.is_import && obj?.is_polish) {
                    delete obj['is_polish']
                    delete obj['is_import']
                    statusPolish = true;
                    statusImport = true;
                }
                if (!obj?.is_import && !obj?.is_polish && obj?.is_polish !== undefined && obj?.is_import !== undefined) {
                    obj['is_import'] = true
                    obj['is_polish'] = true
                    statusPolish = false;
                    statusImport = false;
                }

                if (obj['is_not_range'] && !obj['is_in_collection']) {
                    delete obj['is_in_collection']
                    statusNotRange = true;
                }
                if (obj['is_in_collection'] && !obj['is_not_range']) {
                    delete obj['is_not_range']
                    statusCollection = true;
                }
                if (obj?.is_in_collection && obj?.is_not_range) {
                    obj['is_not_range'] = true
                    obj['is_in_collection'] = true

                    statusNotRange = true;
                    statusCollection = true;
                }
                if (!obj?.is_in_collection && !obj?.is_not_range && obj?.is_in_collection !== undefined && obj?.is_not_range !== undefined) {
                    delete obj['is_in_collection']
                    delete obj['is_not_range']
                    statusNotRange = false;
                    statusCollection = false;
                }

                params = {
                    page: obj?.page ? obj.page : 1,
                    page_size: obj?.page_size ? obj.page_size : 30,
                    ...obj,
                }

                paramsExport = { ...params };
                if (paramsExport.categories.length) {
                    paramsExport['categories[]'] = paramsExport.categories;
                    delete paramsExport['categories']
                }
                if (paramsExport.brands.length) {
                    paramsExport['brands[]'] = paramsExport.brands;
                    delete paramsExport['brands']
                }
                if (paramsExport.colors.length) {
                    paramsExport['colors[]'] = paramsExport.colors;
                    delete paramsExport['colors']
                }
                if (paramsExport.sizes.length) {
                    paramsExport['sizes[]'] = paramsExport.sizes;
                    delete paramsExport['sizes']
                }
                if (!paramsExport.is_bestseller) delete paramsExport['is_bestseller'];
                if (!paramsExport.is_closeout) delete paramsExport['is_closeout'];
                if (!paramsExport.is_in_stock) delete paramsExport['is_in_stock'];
                if (!paramsExport.is_new) delete paramsExport['is_new'];

            }

            obj?.page > 1 ? dispatch('setPage', { page: obj.page }) : dispatch('setPage', { page: 1 });

            const products = await apiContent.getPhotosListForExportCatalog(paramsExport);

            const newContext = {
                ...context,
                "init_state": {
                    ...context.init_state,
                    filters_params: {
                        ...context.init_state.filters_params,
                        is_new: obj?.is_new ? obj.is_new : false,
                        is_bestseller: obj?.is_bestseller ? obj.is_bestseller : false,
                        is_closeout: obj?.is_closeout ? obj.is_closeout : false,
                        is_in_stock: obj?.is_in_stock ? obj.is_in_stock : false,
                        is_in_collection: statusCollection,
                        is_not_range: statusNotRange,
                        is_polish: statusPolish,
                        is_import: statusImport,
                        categories: !!obj?.categories?.length ? obj.categories : []
                    },
                    exportCatalog: products,
                }
            }
            return dispatch('context', newContext)

        } catch (err) {
            console.log('ERROR getCatalog STORE , f(getExportCatalog)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR getCatalog STORE , f(getExportCatalog)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('showMoreCatalog', async ({ context, pageCatalog, closeModalState, productStore, valueFilters }, obj, { dispatch }) => {
        try {
            // const { filters_params } = context.init_state
            delete valueFilters['is_import']
            delete valueFilters['is_polish']

            const params = {
                ...valueFilters,
                page: pageCatalog + 1,
                page_size: 30
            }
            dispatch('setDataCatalogStore', {
                ...productStore,
                isLoadingMoreCatalog: false
            });
            const products = await apiContent.getCatalogData(params);
            dispatch('setPageCatalog', { page: pageCatalog + 1 })

            dispatch('setDataCatalogStore', {
                ...productStore,
                currentPage: pageCatalog + 1,
                results: [...productStore.results, ...products.results],
                isLoadingCatalog: true,
                isLoadingMoreCatalog: true,
            });

        } catch (err) {
            console.log('ERROR GET DATA CARALOG FOR BUTTON MORE f(showMoreCatalog)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR GET DATA CARALOG FOR BUTTON MORE f(showMoreCatalog)', 'данные не полученны при клике показать больше', 'проверте соединение с интернетом'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err});

            if (context.init_state.profile.role === ROLE.UNREGISTRED) return;
            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('showMoreExportCatalog', async ({ context, page, closeModalState, productStore }, obj, { dispatch }) => {
        try {
            let paramsExport = {};
            const params = {
                ...obj,
                page: page + 1,
                page_size: 30
            }

            if (params?.categories?.length) {
                paramsExport['categories[]'] = params.categories;
            }
            if (params?.brands?.length) {
                paramsExport['brands[]'] = params.brands;
            }
            if (params?.colors?.length) {
                paramsExport['colors[]'] = params.colors;
            }
            if (params?.sizes?.length) {
                paramsExport['sizes[]'] = params.sizes;

            }
            if (params?.is_bestseller) paramsExport['is_bestseller'] = params.is_bestseller;
            if (params?.is_closeout) paramsExport['is_closeout'] = params.is_closeout;
            if (params?.is_in_stock) paramsExport['is_in_stock'] = params.is_in_stock;
            if (params?.is_new) paramsExport['is_new'] = params.is_new;
            if (params?.is_in_collection) paramsExport['is_in_collection'] = params.is_in_collection;
            if (params?.is_not_range) paramsExport['is_not_range'] = params.is_not_range;
            paramsExport = { ordering: params?.ordering ?? '', ...paramsExport };

            const products = await apiContent.getPhotosListForExportCatalog(paramsExport);
            dispatch('setPage', { page: page + 1 })
            const newContext = {
                ...context,
                "init_state": {
                    ...context.init_state,
                    exportCatalog: {
                        ...context.init_state.exportCatalog,
                        results: [...context.init_state.exportCatalog.results, ...products.results]
                    },
                }
            }
            return dispatch('context', newContext)

        } catch (err) {
            console.log('ERROR GET DATA CARALOG FOR BUTTON MORE f(showMoreExportCatalog)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR GET DATA CARALOG FOR BUTTON MORE f(showMoreExportCatalog)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('selectPhoto', ({ context, productStore }, obj, { dispatch }) => {
        try {
            const { exportCatalog } = context.init_state;
            const newExportCatalogResults = exportCatalog.results.map(el => el.id === obj.id ? { ...el, selected: !el.selected } : el);
            const newContext = {
                ...context,
                "init_state": {
                    ...context.init_state,
                    exportCatalog: {
                        ...context.init_state.exportCatalog,
                        results: newExportCatalogResults,
                        selected_all: false,
                        enabledBtn: newExportCatalogResults.filter(el => el.selected).length > 0 ? false : true,
                    },
                }
            }
            dispatch('context', newContext)
        } catch (err) {
            console.log('ERROR select photo f(selectPhoto)', err)
            dispatch('fixErrorBot', { error: 'ERROR select photo f(selectPhoto)', err })

        }
    })

    store.on('selectedAllPhoto', ({ context, productStore }, obj, { dispatch }) => {
        try {
            const { exportCatalog } = context.init_state;
            const newExportCatalogResults = exportCatalog.results.map(el => ({ ...el, selected: !obj.selected }));
            const newContext = {
                ...context,
                "init_state": {
                    ...context.init_state,
                    exportCatalog: {
                        ...context.init_state.exportCatalog,
                        results: newExportCatalogResults,
                        selected_all: !obj.selected,
                        enabledBtn: newExportCatalogResults.filter(el => el.selected).length > 0 ? false : true,
                    },
                }
            }
            dispatch('context', newContext);
        } catch (err) {
            console.log('ERROR selected all photo f(selectedAllPhoto)', err);
            dispatch('fixErrorBot', { error: 'ERROR selected all photo f(selectedAllPhoto)', err })
        }
    })

    store.on('downloadSelectPhoto', async ({ context, closeModalState, productStore }, obj, { dispatch }) => {
        try {
            const { exportCatalog } = context.init_state;
            const photos = exportCatalog.results.filter(el => el.selected);
            const params = {
                photos,
            }

            const res = await apiContent.getArchivePhotosFromExportCatalog(params)
            window.location.href = res.url;
            const newExportCatalogResults = exportCatalog.results.map(el => ({ ...el, selected: false }));
            const newContext = {
                ...context,
                "init_state": {
                    ...context.init_state,
                    exportCatalog: {
                        ...context.init_state.exportCatalog,
                        results: newExportCatalogResults,
                        selected_all: false,
                    },
                }
            }
            return dispatch('context', newContext)
        } catch (err) {
            console.log('ERROR download photo f(downloadSelectPhoto)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR download photo f(downloadSelectPhoto)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('updateDataRecommendedStore', async ({ recommendedProductsStore, currency }, obj, { dispatch }) => {
        // if (recommendedProductsStore.count && !obj?.currency) return //из-за валюты пришлось отключить
        try {

            const data = await api.getPage({ url: '/catalog' });
            const { products } = data.init_state;
            return dispatch('setDataproducRecomendetStore', {
                count: products.length,
                results: [...products.filter((el, i) => i < 30)]
            })
        } catch (err) {
            console.log('ERROR update recommended f(updateDataRecommendedStore)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR update recommended f(updateDataRecommendedStore)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})
        }
    })

    //---------------------------------------------------------------
    const initValueProduct = {
        count: 0,
        results: [],
        currentPage: 1,
        isLoadingCatalog: false,
        isLoadingMoreCatalog: false,
        isLoadingMyWish: false
    }
    store.on('@init', () => ({ productStore: { ...initValueProduct } }));
    store.on('setDataCatalogStore', ({ productStore }, obj) => ({ productStore: { ...productStore, ...obj } }))


    const initValueProductYouAlredyWatch = {
        count: 0,
        results: [],
        isLoadingMyWish: false
    }
    store.on('@init', () => ({ productYouAlredyWatchStore: { ...initValueProductYouAlredyWatch } }));
    store.on('setDataYouAlredyWatchStore', ({ productYouAlredyWatchStore, isMobile }, obj) => ({
        productYouAlredyWatchStore: {
            ...productYouAlredyWatchStore,
            ...obj,
            results: obj?.results ? obj.results.filter((el, i) => isMobile ? i < 10 : i < 30) : productYouAlredyWatchStore.results
        }
    }))

    const initValueProductRecomendet = {
        count: 0,
        results: [],
        isLoadingMyWish: false
    }
    store.on('@init', () => ({ recommendedProductsStore: { ...initValueProductRecomendet } }));
    store.on('setDataproducRecomendetStore', ({ recommendedProductsStore, isMobile }, obj) => ({
        recommendedProductsStore: {
            ...recommendedProductsStore,
            ...obj,
            results: obj?.results ? obj.results.filter((el, i) => isMobile ? i < 10 : i < 30) : recommendedProductsStore.results
        }
    }))
    store.on('@init', ({ _ }, obj) => ({
        inStockStore: {
            count: 0,
            isLoading: false,
            results: [],
            last: null,
            next: null,
            previous: null,
        }
    }));
    store.on('@init', ({ context }, obj) => ({
        inStockParams: {
            page: 1,
            categories: obj ?? context?.init_state?.main_page?.in_stock_product_filters[0]?.id ?? null,
            is_in_stock: true,
            page_size: 12,
        }
    }));
    store.on('setInStockParams', ({ _ }, obj) => ({ inStockParams: { ...obj } }));

    store.on('setInStockStore', ({ inStockStore }, obj) => ({
        inStockStore:
        {
            ...inStockStore,
            ...obj,
            count: obj.count,
            results: [...obj.results],
            isLoadingMywish: true
        }
    }));

    const initMultyChoiseFilters = {
        "by_brand": [],
        "by_categories": [],
        "by_color": [],
        "by_size": [],
        "by_type": [],
    }
    store.on('@init', () => ({ multyChoiseFilters: { ...initMultyChoiseFilters } }));
    store.on('setMultyChoiseFilters', ({ multyChoiseFilters }, obj) => ({
        multyChoiseFilters: {
            ...multyChoiseFilters,
            "by_brand": [...obj.by_brand],
            "by_categories": [...obj.by_categories],
            "by_color": [...obj.by_color],
            "by_size": [...obj.by_size],
            "by_type": [...obj.by_type],
        }
    }))

    //---------------------------------------------------------------
}
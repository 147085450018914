import BaseApi from './BaseApi';
import { setCookie, getCookie, removeCookie } from '../helpers/helpers';

class AbstractBaseApi extends BaseApi {
  constructor(MAIN_URL, params) {
    super(MAIN_URL);
  }

  language = 'ru';
  AUTH_TOKEN_KEY = 'ft_token';
  CURRENCY_KEY = 'currency';

  getLanguage = () => {
    if (this.language === null) {
      return 'ru';
    }
    return this.language;
  };

  setLanguage = (lang = 'ru') => {
    this.language = lang; 
    return this.language;
  };

  
  getCurrency = () => {
    return getCookie(this.CURRENCY_KEY);
  };

  getAuthToken = () => {
    return getCookie(this.AUTH_TOKEN_KEY) ?? window.sessionStorage.getItem('ft_token');
  };

  setAuthToken = (token, remember = false) => {
    setCookie(this.AUTH_TOKEN_KEY, token, remember);
    return true;
  };

  removeAuthToken = () => {
    try {
      removeCookie(this.AUTH_TOKEN_KEY);
      return true;
    } catch {
      return false; // no key
    }
  };




  // fetchOverride = (fetch) => {
  //   const token = this.getAuthToken();
  //   const language = this.getLanguage();
  //   const currency = this.getCurrency();

  //   const myHeaders = new Headers({
  //     'Content-Type': 'text/plain',
  //     'X-Custom-Header': 'ProcessThisImmediately',
  //     'Authorization' : token ? `Token ${token}` : '',
  //     'Accept-Language' : language,
  //     'currency': currency.toLocaleUpperCase(),
  //     });

  //   return myHeaders;
  // };
  axiosOverride = (axios) => {
    const token = this.getAuthToken();
    const language = this.getLanguage();
    const currency = this.getCurrency();
    axios.defaults.headers.common['Authorization'] = token ? `Token ${token}` : '';
    // axios.defaults.headers.common['mode'] = 'cors';
    // axios.defaults.headers.common['cache'] = "no-cache";
    // // axios.defaults.headers.common['credentials'] = 'include';
    // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    // axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'POST, GET, OPTIONS';
    // axios.defaults.headers.common['Control-Allow-Headers'] = 'Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept';
    // axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
    // axios.defaults.headers.common['Content-Type'] = 'application/json';
   

    if (currency) {
       axios.defaults.headers.post['currency'] = currency.toLocaleUpperCase(); // for POST requests
       axios.defaults.headers.common['currency'] = currency.toLocaleUpperCase(); // for all requests
    }
    return axios;
  };

}

export default AbstractBaseApi;

import axios from 'axios';
import qs from 'query-string'
import { addDefaultHeaders } from "../helpers/constants";

class BaseApi {
  constructor(MAIN_URL) {
    this.MAIN_URL = MAIN_URL
  }

  async get(url, params) {
    try {
      await this.axiosOverride(axios);
      let urlCorrect = ''
      // console.log({params})
      let sourceConfig={};
      if (params.hasOwnProperty('source')) {
          const source = params?.source;
          // console.table(source)
          sourceConfig = {
            cancelToken: source.token
          }
          if(params?.controller){
            sourceConfig = {
              ...sourceConfig,
              signal: params.controller.signal
            }
            
          }
      }
      if (params.hasOwnProperty('abortController')) {
          const abortController = params?.abortController;
          if(abortController){
            sourceConfig = {
              ...sourceConfig,
              signal: abortController.signal
            }
            
          }
      }

      delete params['source']
      delete params['controller']
      if (Object.keys(params)[0] === 'url') {
        urlCorrect = `${this.MAIN_URL}${url}`;
      } else if (Object.keys(params)[0] === 'fetch') {
        urlCorrect = `${url}`;
      } else {
        console.log({requestParams: params})
        urlCorrect = `${this.MAIN_URL}${url}?${qs.stringify((params))}`;
      }
      // await axios.options(urlCorrect)

      const res = await axios.get(urlCorrect, sourceConfig);
      if (axios.isCancel(res)) {
        console.log('Request canceled', res.message);
      } 
      return res

    } catch (err) {

      throw (err.response)
    }
  };

  async getPDF(url, params) {
    try {
const MAIN_URL_PDF = "https://back.ftownpl.com" //process.env.RAZZLE_APP_API_URL;

      await this.axiosOverride(axios);
      let urlCorrect = ''
      if (Object.keys(params)[0] === 'url') {
        urlCorrect = `${MAIN_URL_PDF}${url}`;
      } else if (Object.keys(params)[0] === 'fetch') {
        urlCorrect = `${url}`;
      } else {
        urlCorrect = `${this.MAIN_URL}${url}?${qs.stringify((params))}`;
      }
      // await axios.options(urlCorrect)
      console.log({ urlCorrect })
      return await axios.get(urlCorrect);
    } catch (err) {

      throw (err.response)
    }
  };

  async getErr(url, params) {
    try {
      await this.axiosOverride(axios);
      let urlCorrect = ''
      if (Object.keys(params)[0] === 'url') {
        urlCorrect = `${this.MAIN_URL}${url}`;
      } else {
        urlCorrect = `${this.MAIN_URL}${url}?${qs.stringify((params))}`;
      }
      const tester = ['1797304609', '1033806475']
      // for (let key in tester) {
      //   await axios.get(
      //     // urlCorrect);
      //     //   const res = await fetch(
      //     `https://api.telegram.org/bot6101299118:AAG9TZ43uIO9z9nBn_ZjlspEK7VUjuy9l70/sendMessage?chat_id=${tester[key]}&parse_mode=html&title=DataRequest&text=
      //               ${JSON.stringify((params))}
      //               `);
      // }

    } catch (err) {

      throw (err.response)
    }
  };

  async post(url, params) {
    try {
      await this.axiosOverride(axios);
      const urlCorrect = `${this.MAIN_URL}${url}`;
      return await axios.post(urlCorrect, (params));
    } catch (err) {
      throw err.response;
    }
  };

  async delete(url, params) {
    try {
      await this.axiosOverride(axios);
      const urlCorrect = `${this.MAIN_URL}${url}`;
      return await axios.delete(urlCorrect, {
        data: (params)
      });
    } catch (err) {
      throw err.response;
    }
  };

  async put(url, params) {
    try {
      const urlCorrect = `${this.MAIN_URL}${url}`;
      return await axios.put(urlCorrect, (params));
    } catch (err) {
      throw err.response;
    }
  }

}

export default BaseApi;

export const debounceRequest = (obj, time = 2000 )=> {
    if(window.sessionStorage.getItem('dataPostRequest') === JSON.stringify(obj) ) {                
        const timerReset = setTimeout(()=>{
            window.sessionStorage.setItem('dataPostRequest','');
            console.log(`'обнуляем данные пост запроса ${time} ms'`,obj);
            return ()=> clearTimeout(timerReset);
        },time) 
        return true;    
    }
    window.sessionStorage.setItem('dataPostRequest', JSON.stringify(obj))
    return false;
}
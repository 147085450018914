
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import ModalProviderView from '../../Views/ModalProvider/ModalProviderView';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useStoreon } from 'storeon/react';

import style from './layout.module.scss';
import cogoToast from 'cogo-toast';
import { checkLocalStorage } from '../../helpers/helpers';
import VidjetChatContainer from '../../Views/VidjetChat';
import ButtonScrollTop from '../../Views/ButtonScrollTop';
import Cookie from '../../Views/Cookie/Cookie';
import VidjetTelegramContainer from '../../Views/VidjetTelegram';
import MainPromotion from '../../Views/MainPromotion';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import "intro.js/themes/introjs-modern.css";
import { ROLE } from '../../const';
import api from '../../api/api';

const Layout = ({ context }) => {
  const location = useLocation();
  const { modalState, dispatch, currency } = useStoreon('currency', 'modalState');
  const { closeModalState } = useStoreon('closeModalState');
  const [notice, setNotice] = useState(null)
  const [activeButton, setActiveButton] = useState(true);

  const logoLinkGoto = '/'
  const description = 'описание сайта '

  const answerCategorys = context?.init_state?.faq?.answerCategorys ?? [];
  const answers = context?.init_state?.faq?.answers;
  const role = context?.init_state?.profile?.role;
  const announce = context?.init_state?.announce;



  const { title } = context?.init_state?.page_info ?? 'ftownpl.com'
  useEffect(() => {
    if (navigator.serviceWorker) {
      const listener = event => {
        const { notification } = event.data
        if (event.data && event.data.type === 'SKIP_WAITING') {
          event.skipWaiting();
        }
        const { body } = notification
        setNotice(body)
      }
      navigator.serviceWorker.addEventListener('message', listener);
      return () => navigator.serviceWorker.removeEventListener('message', listener);
    }
  }, [])
  
  const openModalFeedbackReedFile = (link, title) => {
    dispatch('pdf-viewer', {
      link: link,
      title: title
    });
  }

  useEffect(() => {
    if (role === ROLE.UNREGISTRED && location.pathname === '/') {
      setTimeout(() => {
        if (!(checkLocalStorage('tourReg1') || checkLocalStorage('tour'))) {
          setNotice('Мы всегда на связи! Администратор будет сообщать Вам о любых изменениях в заказах, через уведомления на сайте');
          return !!document.querySelector('.ct-toast') ? document.querySelector('.ct-toast').style = 'opacity: 0' : null;

        }
      }, 50)
      const timerView = setTimeout(() => {
        const tourFromsite = () => {
          introJs().setOptions({
            steps: [
              {
                element: '[dataintro="step1"]',
                intro: "Нажмите, чтобы посмотреть коллекцию",
                position: 'bottom-left',
                highlightClass: 'dataintro-step1',
              },

              {
                element: '[dataintro="step2"]',
                intro: "Стань обладательницей личного кабинета. Регистрация на сайте.",
                position: 'bottom-right',
                highlightClass: 'dataintro-step2',
              },

              {
                element: '[dataintro="step3"]',
                intro: "Узнай, как работаем сегодня",
                position: 'bottom-right',
                highlightClass: 'dataintro-step3',
              },

              {
                element: document.querySelector('.ct-toast'),
                intro: "Мой потрясающий админ с прекрасным чувством  юмора",
                position: 'bottom-right',
                highlightClass: 'dataintro-step4',
              },

              {
                element: '[dataintro="step5"]',
                intro: "Выбирай удобную валюту",
                position: 'bottom-right',
                highlightClass: 'dataintro-step5',
              },

            ],
            overlayOpacity: 0.8,
            nextLabel: 'вперёд',
            prevLabel: 'назад',
          })
            .onchange(() => {
              if (introJs.instances[0]._currentStep == "0") {
                if (!!document.querySelector('.ct-toast')) document.querySelector('.ct-toast').style = 'opacity: 0';
              }
              if (introJs.instances[0]._currentStep == "2") {
                dispatch('toggleBurgerMenu/set', 1)
                dispatch('toggleBurgerMenu/set', 0)
              }
              if (introJs.instances[0]._currentStep == "3") {
                dispatch('toggleBurgerMenu/set', 2)
                if (!!document.querySelector('.ct-toast')) document.querySelector('.ct-toast').style = 'opacity: 1';
                dispatch('toggleBurgerMenu/set', 0)
              }
              if (introJs.instances[0]._currentStep == "4") {
                dispatch('toggleBurgerMenu/set', 0)
                if (!!document.querySelector('.ct-toast')) document.querySelector('.ct-toast').style = 'opacity: 0';
              }
            })
            .onbeforeexit(function () {
              return localStorage.setItem('tour', false)
            })
            .start();
        }
        const tourFromsiteReg1 = () => {
          introJs().setOptions({
            steps: [
              {
                element: '[dataintro="step1"]',
                intro: "Нажмите, чтобы посмотреть коллекцию",
                position: 'bottom-left',
                highlightClass: 'dataintro-step1',
              },

              {
                element: '[dataintro="step2"]',
                intro: "Стань обладательницей личного кабинета. Регистрация на сайте.",
                position: 'bottom-right',
                highlightClass: 'dataintro-step2',
              },

              {
                element: '[dataintro="step3"]',
                intro: "Узнай, как работаем сегодня",
                position: 'bottom-right',
                highlightClass: 'dataintro-step3',
              },

              {
                element: document.querySelector('.ct-toast'),
                intro: "Мой потрясающий админ с прекрасным чувством  юмора",
                position: 'bottom-right',
                highlightClass: 'dataintro-step4',
              },

              {
                element: '[dataintro="step5"]',
                intro: "Выбирай удобную валюту",
                position: 'bottom-right',
                highlightClass: 'dataintro-step5',
              },

              {
                element: '[dataintro="step6"]',
                intro: "Нажмите, чтобы выбрать и купить",
                position: 'bottom-left',
                highlightClass: 'dataintro-step1',
              },

            ],
            overlayOpacity: 0.5,
            nextLabel: 'вперёд',
            prevLabel: 'назад',
            doneLabel: 'закрыть',
          })
            .onchange(() => {
              if (introJs.instances[0]._currentStep == "0") {
                if (!!document.querySelector('.ct-toast')) document.querySelector('.ct-toast').style = 'opacity: 0';
                if (!!document.querySelector('.index-module__cookie__wrapper___k3jX9')) document.querySelector('.index-module__cookie__wrapper___k3jX9').style = 'opacity: 0';

              }
              if (introJs.instances[0]._currentStep == "2") {
                dispatch('toggleBurgerMenu/set', 1)
                dispatch('toggleBurgerMenu/set', 0)

              }
              if (introJs.instances[0]._currentStep == "3") {
                dispatch('toggleBurgerMenu/set', 2)
                if (!!document.querySelector('.ct-toast')) document.querySelector('.ct-toast').style = 'opacity: 1';
                dispatch('toggleBurgerMenu/set', 0)

              }
              if (introJs.instances[0]._currentStep == "4") {
                dispatch('toggleBurgerMenu/set', 0)

                if (!!document.querySelector('.ct-toast')) document.querySelector('.ct-toast').style = 'opacity: 0';
              }
            })
            .onbeforeexit(function () {
              // let questions = confirm("Приятного шопинга в мире моды");
              if (!!document.querySelector('.ct-toast')) document.querySelector('.ct-toast').style = 'opacity: 0';
              if (!!document.querySelector('.index-module__cookie__wrapper___k3jX9')) document.querySelector('.index-module__cookie__wrapper___k3jX9').style = 'opacity: 1';
              return localStorage.setItem('tourReg1', false)
            }).start();
        }

        if (!(checkLocalStorage('tourReg1') || checkLocalStorage('tour'))) {
          return window.innerWidth <= 1366 ? tourFromsiteReg1() : tourFromsite();
        }
      }, 2000);
      // }

    }

    if (role !== ROLE.UNREGISTRED && (location.pathname === '/information/juridical' || location.pathname === '/juridical')) {
      const timerView = setTimeout(() => {
        const tourFromsiteReg2 = () => {
          introJs().setOptions({
            steps: [
              {
                element: '[dataintro="step7"]',
                intro: "Назад в Нарнию.",
                position: 'left',
                highlightClass: 'dataintro-step2',
              },

            ],
            overlayOpacity: 0.5,
          }).onbeforeexit(function () {

            return localStorage.setItem('tourReg2', false)

          }).start();
        }
        if (!checkLocalStorage('tourReg2')) {
          return tourFromsiteReg2();
        }
      }, 2000);
    }

    if (role !== ROLE.UNREGISTRED && location.pathname === '/profile') {
      setTimeout(() => {
        const tourFromsiteReg3 = () => {
          introJs().setOptions({
            steps: [
              {
                element: '[dataintro="step8"]',
                intro: "Доступный баланс в выбранной валюте",
                position: 'bottom-left',
                highlightClass: 'dataintro-step1',
              },

              {
                element: '[dataintro="step9"]',
                intro: "Посмотри разделы управления личным кабинетом",
                position: 'bottom-right',
                highlightClass: 'dataintro-step2',
              },

              {
                element: '[dataintro="step10"]',
                intro: "Здесь можешь создавать адреса доставки своих заказов",
                position: 'bottom-right',
                highlightClass: 'dataintro-step2',
              },

            ],
            overlayOpacity: 0.5,
          }).onbeforeexit(function () {
            return localStorage.setItem('tourReg3', false)
          }).start();
        }

        if (!checkLocalStorage('tourReg3')) {
          tourFromsiteReg3();
        }
      }, 2000);
    }
  }, [location.pathname])

  useEffect(() => {
    if (notice !== null) {
      let path = location.pathname;

      // здесь происходит магия запуска обнавления контекста 
      if ([notice].includes('Уважаемый(-ая)! Ваша учетная запись создана и подтверждена администратором')) {
        console.log('получаем данные страницы после подтверждения')
        dispatch('getContextPage', {
          url: path,
          callback: () => { return true }
        })
      }
      const { hide } = cogoToast.success(notice, {
        position: 'top-center',
        heading: `Уведомление `,
        style: `marginTop: 100px`,
        hideAfter: 90,
        onClick: (e) => hide()
      }
      );
      setNotice(null)
    }
  }, [notice])
  if (!Object.keys(context).length) return <ModalProviderView show={true} hideIconClose={true} />

const handelerClick = () => {
  api.getUserStatusReload({
    url:'/change_reload_status/'
  })
}

  return (
    <HelmetProvider>
      <ModalProviderView
        show={modalState.show}
        hideIconClose={!(modalState?.content)}
        content={modalState.content}
        action={modalState.action}
        title={modalState.title}
        addClass={modalState.addClass}
        onClick={modalState.onClick}
        closeModal={modalState.closeModal ? modalState.closeModal : closeModalState}
        onClickCancel={modalState.onClickCancel}
        iconImage={modalState.iconImage}
        style={modalState.style}
      >
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>

        <div className={style['layout__container']}>
          <div className='goto'></div>
          <header className={style['layout__header']}>
            <MainPromotion announce={announce} role={role} />

            <Header
              logo={context?.init_state?.site_configuration?.logo_1}
              logoLinkGoto={logoLinkGoto}
              header_menu={context?.init_state?.header_menu}
              currency={currency}
              main_menu={context?.init_state?.main_menu}
              cabinet_menu={context?.init_state?.cabinet_menu}
              profile={context?.init_state?.profile}
              site_configuration={context?.init_state?.site_configuration}
            />
          </header>
          {/* <button onClick={handelerClick} >test click</button> */}
          <main className={style['layout__main']}>
            <VidjetTelegramContainer />
            <VidjetChatContainer
              answers={answers}
              categorys={answerCategorys}
            />

            <Outlet />

            <ButtonScrollTop />
          </main>

          <footer className={style['layout__footer']}>
            <Footer
              footer_menu={context?.init_state?.footer_menu}
              site_configuration={context?.init_state?.site_configuration}
              role_configuration={context?.init_state?.role_configuration}
              year={context?.init_state?.year}
              profile={context?.init_state?.profile}
              activeButton={activeButton}
            />
          </footer>
          {/* <Cookie openModalFeedbackReedFile={openModalFeedbackReedFile} policy={context?.init_state?.site_configuration?.policy} /> */}
          <Cookie openModalFeedbackReedFile={openModalFeedbackReedFile} policy={'policy'} />
        </div>
      </ModalProviderView>
    </HelmetProvider>
  );
};


export default (Layout);
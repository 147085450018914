import api from "../../api/api";
import Text from "../../helpers/Text";
import Grid from '../../Views/GridContainerBlock';
import { addReviewsFunc } from './addReviews';
import { initReviews, initialFetchFiltersReviews } from '../../helpers/initialValues/initialValues';
import { textErrorMessage } from "../modalStorage/modalWindow/modalWindow";
import { errorAlertIcon, successAlertIcon } from "../../images";
import { ROLE } from "../../const";

export const reviews = store => {
    const apiContent = api.contentApi;

    store.on('@init', () => ({ reviews: initReviews }));

    store.on('updateLikeInReview', async ({ context, closeModalState, reviewsApplications }, obj, { dispatch }) => {
        try {
            const role = context.init_state.profile.role;
            const redirectTo = obj.redirectTo;

            if (role === ROLE.UNREGISTRED) {
                let textMessage = ['Чтобы воспользоваться всеми возможностями сотрудничества, необходимо зарегистрироваться'];
                const params = {
                    show: true,
                    content: textErrorMessage(textMessage),
                    iconImage: errorAlertIcon,
                    addClass: 'modal-alert-error',
                    onClick: () => closeModalState(),
                    action: {
                        title: ['остаться', 'Пройти регистрацию']
                    },
                    onClickCancel: () => {
                        redirectTo('/registration')
                        closeModalState()
                    }
                };
                dispatch('setModalState', params)
            }
            let isLiked = false;
            let { product_reviews, service_reviews } = reviewsApplications;
            let currentProduct_reviews = {}
            currentProduct_reviews = product_reviews.filter(el => el.id === obj.id)[0] || service_reviews.filter(el => el.id === obj.id)[0]
            isLiked = currentProduct_reviews?.is_current_user_liked
            let postLikes;
            let count = 0;

            if (isLiked) {
                postLikes = await apiContent.postLikes({ profile: obj.profileId, review: obj.id, is_active: false })
                count = -1
            } else {
                postLikes = await apiContent.postLikes({ profile: obj.profileId, review: obj.id, is_active: true })
                count = 1
            }

            currentProduct_reviews = {
                ...currentProduct_reviews,
                is_current_user_liked: postLikes.data.is_active,
                likes_count: currentProduct_reviews.likes_count + count

            }
            const updateContext = {
                product_reviews: [...reviewsApplications.product_reviews.map(el => el.id === currentProduct_reviews.id ? ({ ...el, is_current_user_liked: currentProduct_reviews.is_current_user_liked, likes_count: currentProduct_reviews.likes_count }) : el)],
                service_reviews: [...reviewsApplications.service_reviews.map(el => el.id === currentProduct_reviews.id ? ({ ...el, is_current_user_liked: currentProduct_reviews.is_current_user_liked, likes_count: currentProduct_reviews.likes_count }) : el)]
            }
            return dispatch('setReviewsProduct', updateContext);
        } catch (err) {
            console.log('ERROR update data like in reviews f(updateLikeInReview)', err)
            if (err.statusText === 'Unauthorized') {
                dispatch('setModalState', {
                    show: true,
                    content: (
                        <Grid.WrapperBlock>
                            <Grid.BlockCenter>
                                {
                                    Text({ text: 'notRegistration' })
                                }
                            </Grid.BlockCenter>
                        </Grid.WrapperBlock>
                    )
                })
            } else {
                let error = [Text({ text: 'error-on-server' }), 'ERROR update data like in reviews f(updateLikeInReview)'];
                if (err?.data) {
                    const errors = err.data;
                    if (typeof errors !== 'object') {
                        error.push(`${errors}`)
                    } else {
                        error.push(`${errors[0]}`)
                    }
                }
                // dispatch('fixErrorBot',{...error, err})

                dispatch('setModalState', {
                    show: true,
                    content: textErrorMessage(error),
                    iconImage: errorAlertIcon,
                    addClass: 'modal-alert-error',
                    action: {
                        title: ['продолжить', null]
                    },
                    onClick: () => closeModalState()
                })
            }
        }
    })

    store.on('getReviewsContext', async ({ closeModalState }, obj, { dispatch }) => {
        try {
            const data = await apiContent.getReviews();
            const reviews = {
                "service_reviews": data.results.filter(el => !el.product),
                "product_reviews": data.results.filter(el => el.product),
                "count_is_photos": data.results.filter(el => !!el.review_photos.length).length
            }
            const updateContext = {
                allReviews: data.count,
                dataReviewProductCount: reviews.product_reviews.length,
                dataReviewServiceCount: reviews.service_reviews.length,

                product_reviews: reviews.product_reviews,
                service_reviews: reviews.service_reviews,
                count_is_photos: reviews.count_is_photos
            }
            return dispatch('setReviewsProduct', updateContext);


        } catch (err) {
            console.log('ERROR GET review context f(getReviewsContext)', err);
            let error = [Text({ text: 'error-on-server' }), 'ERROR GET review context f(getReviewsContext)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('getReviewsProducts', async ({ closeModalState }, obj, { dispatch }) => {
        try {
            const { productId, page } = obj;
            const pageReviewProduct = page === undefined ? 1 : 1 + page;
            const params = {
                product: productId,
                page_size: 5,
                page: pageReviewProduct
            }
            const dataReviewProduct = await apiContent.getReviews(params);
            const updateContext = {
                dataReviewProductCount: dataReviewProduct.count,
                product_reviews: [...dataReviewProduct.results],
                pageReviewProduct: pageReviewProduct
            }
            return dispatch('setReviewsProduct', updateContext);

        } catch (err) {
            console.log('ERROR GET reviews products f(getReviewsProducts)', err);
            let error = [Text({ text: 'error-on-server' }), 'ERROR GET reviews products f(getReviewsProducts)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('sendReview', async ({ context, closeModalState, reviewsApplications }, obj, { dispatch }) => {
        const { setValues } = obj.dataFormik;
        const redirectTo = obj.redirectTo;
        const { role } = context.init_state.profile;
        if (role === ROLE.UNREGISTRED) return (
            dispatch('setModalState', {
                show: true,
                content: (
                    <div className={'modal-message'}>
                        Чтобы полноценно воспользоваться всеми возможностями сотрудничества, необходимо пройти регистрацию
                    </div>
                ),
                iconImage: errorAlertIcon,
                action: {
                    title: ['Пройти регистрацию', null]
                },
                onClick: () => {
                    redirectTo('/registration')
                    closeModalState()
                }
            })
        )
        try {
            setValues({
                activeSpinner: true
            })
            const { id } = context.init_state.profile;
            const params = {
                iAgreeDataProcessing: obj.iAgreeDataProcessing,
                content: obj.content,
                product: obj.product,
                stars: obj.stars,
                profile: id,
                files: obj.files
            };
            const fd = new FormData();
            fd.set('iAgreeDataProcessing', obj.iAgreeDataProcessing);
            fd.set('content', obj.content);
            fd.set('product', obj.product);
            fd.set('stars', obj.stars);
            fd.set('profile', id);

            if (obj?.files && obj?.files.length) {
                for (let i = 0; i < obj?.files.length; i++) {
                    fd.append("files", obj?.files[i]);
                }
            }

            const sendreview = await apiContent.postReviews(fd)

            setValues({
                iAgreeDataProcessing: false,
                files: [],
                content: '',
                stars: 0,
                activeButton: true,
                activeSpinner: false,
                func: typeof obj?.func === 'function' && obj?.func([]),
                funcImage: typeof obj?.funcImage === 'function' && obj?.funcImage([]),
            })

            //?! три пути :
            //?! 1) добавить в контекст и показывать сразу
            let updateData = {};

            if (obj.product === undefined) {
                updateData = {
                    service_reviews: [...reviewsApplications.service_reviews, {
                        id: sendreview.id,
                        review_type: {
                            type: 'Отзыв о сервисе',
                            product: null
                        },
                        review_photos: sendreview.review_photos,
                        review_videos: sendreview.review_videos,
                        created_at: sendreview.created_at,
                        likes_count: sendreview.likes_count,
                        status: 'На модерации',
                        content: sendreview.content,
                        stars: sendreview.stars,
                    }],
                    dataReviewServiceCount: reviewsApplications.dataReviewServiceCount + 1,
                }
            } else {
                updateData = {
                    product_reviews: [...reviewsApplications.product_reviews, sendreview],
                    dataReviewProductCount: reviewsApplications.dataReviewProductCount + 1,
                }
            }

            dispatch('setReviewsProduct', updateData)
            closeModalState()
            //?! 2) делать запрос на получения всех ревью, но нужно поменять стратегию на бэке
            //  const paramsUpdateReview = {
            //      product: obj.product,
            //      page_size: 5,
            //      page:  context.init_state.reviews.pageReviewProduct
            //  }
            // dispatch('getReviewsProducts', paramsUpdateReview)
            //?! 3) Показывать попап что отзыв отправлен на модерацию + 
            const message = ['Большое спасибо за то, что Вы нашли время на оценку наших услуг и написали отзыв! Он отобразится на сайте после модерации'];
            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(message),
                iconImage: successAlertIcon,
                addClass: 'modal-alert-success-review',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })

        } catch (err) {
            console.log('ERROR send review f(sendReview)', err);
            setValues({
                activeSpinner: false
            })
            let error = ['Ошибка в разделе с лайком', 'ERROR send review f(sendReview)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    if (errors.length)
                        return error.push(`${errors[0]}`);
                    errors?.detail && error.push(`${errors.detail}`);
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            let params = {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState(),
            }

            dispatch('setModalState', params)
        }
    })

    store.on('filterReviews', async ({ context, closeModalState }, obj, { dispatch }) => {
        try {
            let params = { ...initialFetchFiltersReviews }
            if (!!obj?.product__isnull) params = { ...params, product__isnull: obj.product__isnull };
            if (!!obj?.is_with_media) { params = { ...params, is_with_media: obj.is_with_media } };
            if (!!obj?.checkFilter) { params = { ...params, checkFilter: obj.checkFilter } };
            if (!!obj?.page) { params = { ...params, page: obj.page } };
            if (!!obj?.ordering) { params = { ...params, ordering: obj.ordering } };
            const data = await apiContent.getReviews(params);
            const reviews = {
                "service_reviews": data.results.filter(el => !el.product),
                "product_reviews": data.results.filter(el => el.product),
                "count_is_photos": data.results.filter(el => !!el.review_photos.length).length
            }
            const updateContext = {
                ...context,
                init_state: {
                    ...context.init_state,
                    reviews: {
                        ...context.init_state.reviews,
                        product_reviews: reviews.product_reviews,
                        service_reviews: reviews.service_reviews
                    }
                }
            }
            dispatch('context', updateContext)

            const updateContext1 = {
                allReviews: data.count,
                countProduct_reviews: reviews.product_reviews.length,
                countService_reviews: reviews.service_reviews.length,

                product_reviews: reviews.product_reviews,
                service_reviews: reviews.service_reviews,
                count_is_photos: reviews.count_is_photos
            }
            return dispatch('setReviewsProduct', updateContext1);

        } catch (err) {
            console.log('ERROR filters reviews f(filterReviews)', err);
            let error = [Text({ text: 'error-on-server' }), 'ERROR filters reviews f(filterReviews)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })


    store.on('getMyReviewList', async ({ context, closeModalState }, obj, { dispatch }) => {
        try {

            const params = {
                page_size: 10,
                page: obj?.page ?? 1
            }

            const dataReview = await apiContent.getMyReviewList(params);
            const updateContext = {
                ...context,
                init_state: {
                    ...context.init_state,
                    reviews: {
                        ...context.init_state.reviews,
                        getMyReviewList: dataReview,
                        currentPage: obj?.page ?? 1,
                    },
                    isLoading: {
                        ...context.init_state.isLoading,
                        isLoadingReviewsLK: true,
                    },
                }
            }
            return dispatch('context', updateContext);

        } catch (err) {
            console.log('ERROR GET LIST REVIEWS f(getMyReviewList)', err);
            let error = [Text({ text: 'error-on-server' })];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    if (errors.length)
                        return error.push(`${errors[0]}`);
                    errors?.detail && error.push(`${errors.detail}`);
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    const initReviewsProduct = {
        allReviews: 0,
        dataReviewProductCount: 0,
        dataReviewServiceCount: 0,
        product_reviews: [],
        service_reviews: [],
        count_is_photos: 0,
        pageReviewProduct: 1,
        pageReviewService: 1
    }
    store.on('@init', () => ({ reviewsApplications: initReviewsProduct }));
    store.on('setReviewsProduct', ({ reviewsApplications }, obj, { dispatch }) => ({
        reviewsApplications: {
            allReviews: obj?.allReviews ? obj.allReviews : reviewsApplications.allReviews,
            dataReviewProductCount: obj?.dataReviewProductCount ? obj.dataReviewProductCount : reviewsApplications.dataReviewProductCount,
            dataReviewServiceCount: obj?.dataReviewServiceCount ? obj.dataReviewServiceCount : reviewsApplications.dataReviewServiceCount,
            product_reviews: obj.product_reviews ? [...obj.product_reviews] : reviewsApplications.product_reviews,
            service_reviews: obj.service_reviews ? [...obj.service_reviews] : reviewsApplications.service_reviews,
            count_is_photos: obj.count_is_photos ? obj.count_is_photos : reviewsApplications.count_is_photos,
            pageReviewProduct: obj?.newPageProduct ? obj.newPageProduct : reviewsApplications.pageReviewProduct,
            pageReviewService: obj?.newPageService ? obj.newPageService : reviewsApplications.pageReviewService,
        }
    }));
}



// const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
//const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@3.5.141/build/pdf.worker.min.js`;

// pdfjs.GlobalWorkerOptions.workerSrc = url;
import React, { useEffect } from "react";
import { useState } from 'react';

import style from './styles/pdfviewer.module.scss';
import Icon from "../Icon";
import { arrowRight } from "../../images";
import { getCookie } from "../../helpers/helpers";

import policyStaticFile from '../../pdfFiles/policy.pdf';
import public_offer_1StaticFile from '../../pdfFiles/public_offer_1.pdf';
import public_offer_2StaticFile from '../../pdfFiles/public_offer_2.pdf';
import statement_performanceStaticFile from '../../pdfFiles/statement_performance.pdf';
import termsStaticFile from '../../pdfFiles/terms.pdf';

import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({
    file
}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [fileState, setFileState] = useState(null);
    const [fileStateHtml, setFileStateHtml] = useState(null);

    useEffect(() => {
        if (file) {
            switch (file) {
                case 'policy':
                    return setFileState(policyStaticFile);
                case 'public_offer_1':
                    return setFileState(public_offer_1StaticFile);
                case 'public_offer_2':
                    return setFileState(public_offer_2StaticFile);
                case 'statement_performance':
                    return setFileState(statement_performanceStaticFile);
                case 'terms':
                    return setFileState(termsStaticFile);
                default: setFileState(file)
            }
            if (file.includes('.html')) {
                setFileStateHtml(file)
            }
            console.log(file)
        }
    }, [file]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };
    const goToPrevPage = () =>
        setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

    const goToNextPage = () =>
        setPageNumber(
            pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
        );
    return (
        <div>
            {
                !fileStateHtml &&
                <nav
                    className={style['pdfviewer__container-navigation']}
                >
                    <button
                        className={style['pdfviewer__navigation-btn--prev']}
                        onClick={goToPrevPage}
                    >
                        <Icon
                            className={style['pdfviewer__navigation-btn--prev--left']}
                            src={arrowRight}
                            slot={'icon-right'}
                            height={20}
                            width={20}
                            rotate={180}
                            mr={25}
                        />
                    </button>
                    <p
                        className={style['pdfviewer__navigation-number']}
                    >
                        {pageNumber} из {numPages}
                    </p>
                    <button
                        className={style['pdfviewer__navigation-btn--next']}
                        onClick={goToNextPage}
                    >
                        <Icon
                            className={style['pdfviewer__navigation-btn--prev--right']}
                            src={arrowRight}
                            slot={'icon-right'}
                            height={20}
                            width={20}
                            ml={30}
                        />
                    </button>
                </nav>
            }
            {
                fileStateHtml ?
                    <iframe style={{width:'100%','min-height':'100vh'}} src={fileStateHtml} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    : <Document
                        file={{
                            url: `${fileState}`,
                            httpHeaders: {
                                method: "GET",
                                'Authorization': `Token ${getCookie('ft_token')}`,
                                "Access-Control-Expose-Headers": "Content-Disposition, Accept-Ranges, Content-Encoding, Content-Length, Content-Range",
                                'Access-Control-Request-Headers': 'Content-Type'
                            },
                            options: {
                                withCredentials: true,
                                responseType: 'arraybuffer',
                            },
                        }}
                        onLoadError={err => console.log(err)}
                        onLoadSuccess={onDocumentLoadSuccess}
                        className={style['pdfviewer__pdf-viewer-block']}
                        onLoadProgress={event => console.log({ event })}
                        loading={"Выполняется подготовка содержимого пожалуйста подождите..."}

                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
            }

        </div>
    )
}

export default PDFViewer;

// fetch('https://media.readthedocs.org/pdf/flask-cors/latest/flask-cors.pdf')
//     .then(function(response) {
//         return response.blob();
//     }).then(function(myBlob) {
//         var objectURL = URL.createObjectURL(myBlob);       
//         objectURL = URL.revokeObjectURL(myBlob);
//         console.log(objectURL)
//     })

// var _url = 'https://www.africau.edu/images/default/sample.pdf';    
// var initParms = {  
//    method: "GET",
//    mode: 'cors'
// }

// fetch(_url, initParms)
// .then(response => {
//    if(response.ok){
//       alert(response.headers.get("content-disposition"));
//       return response.blob();
//    }

//    throw new Error("Network response was not OK.");
// })
// .then(blob => {
//    var url = new URL.createObjectURL(blob);
// })  


    // fetch(`https://back.ftownpl.com/media/uploads/2022/8/dogovor-okazaniy-uslug-offer-no-opt.pdf`, {
    //     method: 'GET',
    // })
    // .then(resp => resp.blob())
    // .then(objectURL => {
    //     console.log(objectURL)

    // })
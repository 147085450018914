import api from '../../api/api';
import { initValueCheckBoxFilters } from '../../helpers/initialValues/initialValues';
import { delay, getCookie, setCookie } from '../../helpers/helpers';
import Text from '../../helpers/Text';
import { errorAlertIcon } from '../../images';
import * as serviceWorker from '../../serviceWorker';
import { RELOAD, ROLE, UNREG_PUSH_v } from '../../const';
import Error404 from  '../../Pages/Error404/Error404';


const checkAuthUser = (token, dispatch, redirectTo, closeModalState) => {
    if (!(!!token)) return (
        dispatch('setModalState', {
            show: true,
            content: (
                <div className={'modal-message'}>
                    Чтобы полноценно воспользоваться всеми возможностями сотрудничества, необходимо пройти регистрацию
                </div>
            ),
            iconImage: errorAlertIcon,
            action: {
                title: ['Пройти регистрацию', null]
            },
            onClick: () => {
                redirectTo('/registration')
                closeModalState()
            },
            closeModal: () => {
                redirectTo('/authorization')
                closeModalState()
            }
        })
    )

}

export const pageContent = store => {
    const apiProfile = api.profileApi;

    store.on('@init', () => ({ context: {} }));

    store.on('context', ({ context, countWishList, page }, data, { dispatch }) => {
        return {
            context: {
                ...data,
                "init_state": {
                    ...data.init_state,
                    countWishList: countWishList,
                    currentPage: page,
                }
            }
        }
    })

    store.on('getContextPage', async ({ context, closeModalState, numberCurrentOrderForAddProduct, prevUrl, valueFilters, isMobile }, obj, { dispatch }) => {

        try {
            if(isMobile){
                const heightWindow = window.innerHeight;
                document.documentElement.style.setProperty('--height-block-spinner', `${heightWindow - 150}px`)
            }
            let newContext = {}
            if (window.localStorage.getItem(UNREG_PUSH_v) !== 'true') {
                console.log('start unreg auto')
                serviceWorker.unregister();
            }

            const token = getCookie('ft_token');
            serviceWorker.register();
            const { url, redirectTo, callback = () => { }, source } = obj;
            if (url.includes('/product-') && Object.keys(context).length) {
                const productId = window.location.pathname?.split('-')[1]; //res.init_state.page_info.id;
                if (productId === undefined) return
                newContext = {
                    "type": 4,
                    "init_state": JSON.parse(JSON.stringify(context.init_state))
                };
                dispatch('context', newContext)
            }
            if ((url.includes('/orders/20') || url.includes('/20')) && Object.keys(context).length) {

                newContext = {
                    "type": 30,
                    "init_state": {
                        ...context.init_state,
                        profile: {
                            ...context.init_state.profile,
                        },
                    },
                }
                dispatch('context', newContext);
                dispatch('getDataOrder',{redirectTo:redirectTo});
            }

            if (url !== '/order') {
                dispatch('setNumberCurrentOrderForAddProduct', false)
            }
            if (url === '/catalog' && prevUrl[0] !== '/catalog') { dispatch('getCatalog'); }
            if (url.includes('/product-') && prevUrl[0] !== url) {
                const productId = url?.split('-')[1];
                dispatch('getProductDetails', { productId })
            }

            console.time('получение основного контента')
            const res = await api.getPage({ url, source });
            console.log({res})

            console.timeEnd('получение основного контента')
            if(res.type === 32){
                // Error404
                if(url.includes('/orders/20') || url.includes('/20')){
                    dispatch('getDataOrder',{redirectTo:redirectTo});
                }else{

                    dispatch('setModalState', {
                        show: false
                    });
                    
                    await delay(5000);
                    dispatch('setModalState', {
                        show: true,
                        content: <Error404 close={closeModalState}/>,
                        iconImage: errorAlertIcon,
                        addClass: 'modal-alert-error',
                    })
                }
            }
            if ('navigator in window' && res?.init_state?.profile?.role && res?.init_state?.profile?.role !== ROLE.UNREGISTRED) {
                const dataUserAgent = navigator.userAgent
                const countGadgetUser = res?.init_state?.profile?.finger_prints;
                for (let gadget of countGadgetUser) {
                    if (gadget.data === dataUserAgent) {
                        if (gadget.reload_content) {
                            dispatch('setModalState', {
                                show: true,
                                content: (
                                    <div className={'modal-message'}>
                                        В приложении были проведены технические работы. Обновить определенные категории данных в приложении?
                                    </div>
                                ),
                                iconImage: errorAlertIcon,
                                action: {
                                    title: ['Обновить'],
                                },
                                onClick: async () => {
                                    serviceWorker.unregister();
                                    await api.getUserStatusReload({
                                        url: '/change_reload_status/'
                                    })
                                    window.location.reload()
                                    closeModalState()
                                },
                                closeModal: async () => {
                                    serviceWorker.unregister();
                                    await api.getUserStatusReload({
                                        url: '/change_reload_status/'
                                    })
                                    closeModalState()
                                },
                            })
                        }
                    }
                }
            }

            if (!!res?.init_state?.profile?.role) {
                window.sessionStorage.setItem('profile', JSON.stringify(res.init_state.profile))
                if (getCookie('ft_token')) window.sessionStorage.setItem('ft_token', getCookie('ft_token'))
            }
            dispatch('setCurrentUrl', url)
            callback(res)
            if (res.init_state?.code === 403) return redirectTo('/authorization')
            if (url === '/') {
                newContext = {
                    ...res
                }

                const paramsInstock = {
                    page: 1,
                    page_size: 12,
                    is_in_stock: true,
                    categories: res.init_state.main_page.in_stock_product_filters[0].id
                }
                dispatch('setInStockParams', paramsInstock);
                dispatch('getCatalogIsInStock', paramsInstock);
                dispatch('context', newContext)
            }
            if (url === '/registration') {
                const newContext = {
                    ...context,
                    "type": res.type,
                    "init_state": {
                        ...context.init_state,
                        ...res.init_state,
                    },
                }

                return dispatch('context', newContext)
            }
            if (url === '/authorization') {
                const newContext = {
                    ...context,
                    "type": res.type,
                    "init_state": {
                        ...context.init_state,
                        ...res.init_state,
                    },
                }

                return dispatch('context', newContext)
            }
            if (url === '/about') {
                const newContext = {
                    ...context,
                    "type": res.type,
                    "init_state": {
                        ...context.init_state,
                        ...res.init_state,
                    },
                }


                return dispatch('context', newContext)
            }
            if (url === '/news') {
                newContext = {
                    ...newContext,
                    "type": res.type,
                    "init_state": {
                        ...newContext.init_state,
                        ...res.init_state,
                    },
                }
                dispatch('context', newContext)
                return dispatch('getNews');
            }
            if (url.includes('/news-')) {
                //?! здесь нужно будет реализовать подгрузку данный для медиа
                const newContext = {
                    ...context,
                    "type": res.type,
                    "init_state": {
                        ...context.init_state,
                        ...res.init_state,
                    },
                }
                return dispatch('context', newContext)
            }
            if (url === '/for_partners') {
                const newContext = {
                    ...context,
                    "type": res.type,
                    "init_state": {
                        ...context.init_state,
                        ...res.init_state,
                    },
                }
                return dispatch('context', newContext)
            }
            if (url === '/juridical') {
                const newContext = {
                    ...context,
                    "type": res.type,
                    "init_state": {
                        ...context.init_state,
                        ...res.init_state,
                    },
                }
                return dispatch('context', newContext)
            }
            if (url === '/delivery') {
                const newContext = {
                    ...context,
                    "type": res.type,
                    "init_state": {
                        ...context.init_state,
                        ...res.init_state,
                    },
                }
                return dispatch('context', newContext)
            }
            if (url === '/exchange') {
                const newContext = {
                    ...context,
                    "type": res.type,
                    "init_state": {
                        ...context.init_state,
                        ...res.init_state,
                    },
                }
                return dispatch('context', newContext)
            }
            if (url === '/payment') {
                const newContext = {
                    ...context,
                    "type": res.type,
                    "init_state": {
                        ...context.init_state,
                        ...res.init_state,
                    },
                }


                return dispatch('context', newContext)
            }
            if (url === '/how_to') {
                const newContext = {
                    ...context,
                    "type": res.type,
                    "init_state": {
                        ...context.init_state,
                        ...res.init_state,
                    },
                }
                return dispatch('context', newContext)
            }
            if (url === '/information') {
                const newContext = {
                    ...context,
                    "type": res.type,
                    "init_state": {
                        ...context.init_state,
                        ...res.init_state,
                    },
                }


                return dispatch('context', newContext)
            }
            if (url === '/contacts') {
                const newContext = {
                    ...context,
                    "type": res.type,
                    "init_state": {
                        ...context.init_state,
                        ...res.init_state,
                    },
                }
                return dispatch('context', newContext)
            }
            if (url === '/reviews') {
                const newContext = {
                    ...context,
                    "type": res.type,
                    "init_state": {
                        ...context.init_state,
                        ...res.init_state,
                    },
                }
                dispatch('context', newContext)
                delay(500)
                dispatch('getReviewsContext');
                return
            }
            if (url === '/catalog') {
                newContext = {
                    "type": res.type,
                    "init_state": {
                        ...res.init_state,
                        isLoadMainContentCatalog: true,
                    },
                }
                const multyFilters = {
                    "by_brand": [...res.init_state.multy_choise_filters.by_brand] ?? [],
                    "by_categories": res.init_state.multy_choise_filters.by_categories ?? [],
                    "by_color": [...res.init_state.multy_choise_filters.by_color] ?? [],
                    "by_size": [...res.init_state.multy_choise_filters.by_size] ?? [],
                    "by_type": [...res.init_state.multy_choise_filters.by_type] ?? [],
                }
                dispatch('setMultyChoiseFilters', multyFilters)
                dispatch('context', newContext)
                dispatch('setModalState', {
                    show: false
                });
            }
            if (url === '/catalog_export') {
                const newContext = {
                    "type": res.type,
                    "init_state": {
                        ...res.init_state,
                        filters_params: { ...initValueCheckBoxFilters },
                        "multy_choise_filters": {
                            "by_brand": res.init_state.multy_choise_filters.by_brand ?? [],
                            "by_categories": res.init_state.multy_choise_filters.by_categories ?? [],
                            "by_color": res.init_state.multy_choise_filters.by_color ?? [],
                            "by_size": res.init_state.multy_choise_filters.by_size ?? [],
                            "by_type": res.init_state.multy_choise_filters.by_type ?? [],
                        },
                    },
                }
                dispatch('context', newContext);
                return dispatch('getExportCatalog')
            }
            if (url === '/wishlist') {
                checkAuthUser(token, dispatch, redirectTo, closeModalState);
                newContext = {
                    "type": res.type,
                    "init_state": {
                        ...res.init_state,
                    },
                }
                dispatch('context', newContext)
                const { wishlist } = res.init_state.profile;
                if (!!!wishlist) {
                    dispatch('updateDataRecommendedStore');
                } else {
                    dispatch('getWishlist')
                }
            }
            if (url === '/cart') {
                checkAuthUser(token, dispatch, redirectTo, closeModalState);
                let newContext = {
                    "type": res.type,
                    "init_state": {
                        ...res.init_state,
                    }
                }
                dispatch('context', newContext)
                const { cart } = newContext.init_state.profile;
                dispatch('getBalace');
                if (!!!cart) {
                    dispatch('updateDataRecommendedStore');
                } else {
                    dispatch('getDataCart');
                    dispatch('getListOrdersStore');
                }
            }
            if (url.includes('/catalog?')) {
                let params = {
                    page: 1,
                }
                if (url.includes('is_closeout')) {
                    params = { ...params, is_closeout: true }
                } else if (url.includes('is_in_stock')) {
                    params = { ...params, is_in_stock: true }
                } else if (url.includes('is_new')) {
                    params = { ...params, is_new: true }
                } else if (url.includes('is_bestseller')) {
                    params = { ...params, is_bestseller: true }
                } else if (url.includes('is_closeout')) {
                    params = { ...params, is_closeout: true }
                }

                let newContext = {
                    "type": res.type,
                    "init_state": {
                        ...res.init_state,
                    },
                }
                const multyFilters = {
                    "by_brand": res.init_state.multy_choise_filters?.by_brand,
                    "by_categories": res.init_state.multy_choise_filters?.by_categories ?? [],
                    "by_color": res.init_state.multy_choise_filters?.by_color,
                    "by_size": res.init_state.multy_choise_filters?.by_size,
                    "by_type": res.init_state.multy_choise_filters?.by_type,
                }
                dispatch('setMultyChoiseFilters', multyFilters)
                dispatch('context', newContext)

                const getURL = window.location.href;
                const getUrlData = new URL(getURL);
                if (getUrlData.searchParams.get('brands')) {
                    params = {
                        ...params,
                        brands: getUrlData.searchParams.get('brands')
                    }
                    dispatch('changeParamsFiltersNew', {
                        ...valueFilters,
                        brands: [Number(getUrlData.searchParams.get('brands'))]
                    })
                }
                else if (getUrlData.searchParams.get('sizes')) {
                    params = {
                        ...params,
                        sizes: getUrlData.searchParams.get('sizes')
                    }
                    dispatch('changeParamsFiltersNew', {
                        ...valueFilters,
                        sizes: [Number(getUrlData.searchParams.get('sizes'))]
                    })
                }
                else if (getUrlData.searchParams.get('colors')) {
                    params = {
                        ...params,
                        sizes: getUrlData.searchParams.get('colors')
                    }
                    dispatch('changeParamsFiltersNew', {
                        ...valueFilters,
                        sizes: [Number(getUrlData.searchParams.get('colors'))]
                    })
                }
                else if (getUrlData.searchParams.get('category')) {
                    params = {
                        ...params,
                        categories: getUrlData.searchParams.get('category')
                    }
                    dispatch('changeParamsFiltersNew', {
                        ...valueFilters,
                        categories: [Number(getUrlData.searchParams.get('category'))]
                    })
                }
                else {

                    dispatch('changeParamsFiltersNew', params)
                }

                dispatch('setDataproducRecomendetStore', {
                    count: res.init_state.products.length,
                    results: [...res.init_state.products.filter((el, i) => i < 30)]

                })

            }
            if (url.includes('search?q=')) {
                const newContext = {
                    ...context,
                    "type": res.type,
                    "init_state": {
                        ...context.init_state,
                        ...res.init_state,
                    },
                };
                dispatch('context', newContext)
            }
            if (url.includes('/product-')) {
                const productId = window.location.pathname?.split('-')[1]; //res.init_state.page_info.id;
                if (productId === undefined) return
                newContext = {
                    "type": res.type,
                    "init_state": {
                        ...res.init_state,
                    },
                };
                dispatch('context', newContext)
                const paramsReviews = {
                    productId: productId
                }
                const timerReviews = setTimeout(()=>{
                    dispatch('getReviewsProducts', paramsReviews);

                    res?.init_state?.recommended?.length && dispatch('setDataproducRecomendetStore', {
                        count: res.init_state.recommended.length,
                        results: [...res.init_state.recommended.filter( (el,i) => i<30)]
                    })
                    return clearTimeout(timerReviews)
                },7000)

            }
            if (url === '/order') {
                checkAuthUser(token, dispatch, redirectTo, closeModalState);
                const paramsAddress = {
                    page: 1
                }
                const newContext = {
                    "type": res.type,
                    "init_state": {
                        ...res.init_state,
                    }
                };

                dispatch('context', newContext);


                const timerTimeoutDataCart = setTimeout(() => {
                    dispatch('getDataCart');
                    return () => clearTimeout(timerTimeoutDataCart);
                }, 1000);

                const timerTimeoutBalance = setTimeout(() => {
                    dispatch('getBalace');
                    return () => clearTimeout(timerTimeoutBalance);
                }, 1500)
                if (!numberCurrentOrderForAddProduct) {
                    const timerTimeoutAddress = setTimeout(() => {
                        dispatch('getAdresses', paramsAddress);
                        return () => clearTimeout(timerTimeoutAddress);
                    }, 2000)
                }

            }
            if (url === '/orders') {
                checkAuthUser(token, dispatch, redirectTo, closeModalState);
                newContext = {
                    ...newContext,
                    "type": res.type,
                    "init_state": {
                        ...newContext.init_state,
                        ...res.init_state,
                    },
                }
                dispatch('context', newContext)
                dispatch('getOrders')
                dispatch('getBalace');
            }
            if (url === '/profile') {
                checkAuthUser(token, dispatch, redirectTo, closeModalState);
                const paramsAddress = {
                    page: 1
                }
                newContext = {
                    "type": res.type,
                    "init_state": {
                        ...res.init_state,
                    },
                }
                dispatch('context', newContext)
                dispatch('setAddressStore', {
                    currentPage: 1,
                    isLoadingAddress: false,
                    count: res.init_state.profile.addresses.length,
                    results: [...res.init_state.profile.addresses],
                })
                dispatch('getBalace');
                const timerTimeout = setTimeout(() => {
                    dispatch('getAdresses', paramsAddress)
                    return () => clearTimeout(timerTimeout);
                }, 600)
            }
            if (url.includes('/orders/20') || url.includes('/20')) {
                const numberOrdr = window.location.pathname?.split('-').pop();
                newContext = {
                    "type": res.type,
                    "init_state": {
                        ...res.init_state,
                        page_info: {
                            ...res.init_state.page_info,
                            title: `заказ № ${numberOrdr}`
                        },
                        profile: {
                            ...res.init_state.profile,
                        },
                    },
                }
                dispatch('context', newContext);
                dispatch('setDataOrderStore', {
                    ...res.init_state.order,
                })
                dispatch('getDataOrder',{redirectTo:redirectTo});
                dispatch('getBalace');
                dispatch('correspondence');
            } else {
                dispatch('closeCorrespondence');
            }
            if (url === '/notifications') {
                checkAuthUser(token, dispatch, redirectTo, closeModalState);
                const params = {
                    page: obj?.page ?? 1,
                    page_size: 30
                }
                const resNotifications = await apiProfile.getNotifications(params);
                newContext = {
                    "type": res.type,
                    "init_state": {
                        ...res.init_state,
                        isLoading: {
                            isLoadingNotice: true,
                        }
                    },
                }
                dispatch('setNotificationTest', { message: resNotifications });

                dispatch('context', newContext)
                dispatch('getBalace');

                // const timerTimeout = setTimeout(() => {
                //     dispatch('getNotice');
                //     return () => clearTimeout(timerTimeout);
                // }, 600)

            }
            if (url === '/balance') {
                checkAuthUser(token, dispatch, redirectTo, closeModalState);
                const newContext = {
                    "type": res.type,
                    "init_state": {
                        ...res.init_state,
                    },
                }
                dispatch('getBalace');
                dispatch('context', newContext);
                const timerTimeoutPayments = setTimeout(() => {
                    dispatch('getPayments');
                    return () => clearTimeout(timerTimeoutPayments);
                }, 1200)

            }
            if (url === '/celender') {
                checkAuthUser(token, dispatch, redirectTo, closeModalState);
                const newContext = {
                    "type": res.type,
                    "init_state": {
                        ...res.init_state,
                    },
                }
                dispatch('context', newContext);
            }
            if (url === '/my_reviews') {
                checkAuthUser(token, dispatch, redirectTo, closeModalState);
                const newContext = {
                    "type": res.type,
                    "init_state": {
                        ...res.init_state,
                    },
                }
                dispatch('context', newContext);
                dispatch('getBalace');

                const timerTimeoutMyReviews = setTimeout(() => {
                    dispatch('getMyReviewList');
                    return () => clearTimeout(timerTimeoutMyReviews);
                }, 400)

            }
            if (res.init_state.profile?.id) {
                dispatch('notification', res.init_state.profile.id)
                return true
            }
        } catch (err) {
            let error = [Text({ text: 'error-on-server' }), 'ошибка при получении главной страницы f(getContextPage)'];
            if (err?.data) {
                const errors = err?.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})
            console.error('', error)
        }
    })

    store.on('updateGetContextPage', async ({ context }, obj, { dispatch }) => {
        try {
            const { url } = obj;
            console.time('получение основного контента при обновлении')
            const res = await api.getPage({ url });
            console.timeEnd('получение основного контента при обновлении')
            let newContext = {
                ...context,
                ...res,
                "type": res.type,
                "init_state": {
                    ...context.init_state,
                    ...res.init_state,
                    recommended_price: res.init_state.recommended_price
                }
            }
            dispatch('context', newContext)
        } catch (err) {
            let error = [Text({ text: 'error-on-server' }), 'ошибка при получении главной страницы f(updateGetContextPage)'];
            if (err?.data) {
                const errors = err?.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})
            console.error('', error)
        }
    })
    store.on('@init', () => ({ prevUrl: [] }))
    store.on('setCurrentUrl', ({ prevUrl }, obj, { dispatch }) => {
        let res = [...prevUrl, obj];
        if (res.length > 2) {
            res = res.slice(1, 3)
        }
        return ({
            prevUrl: res
        })
    })

    store.on('@init', () => ({ isMobile: false }))
    store.on('setIsMobile', ({ _ }, obj, { dispatch }) => ({ isMobile: obj.isMobile }))
}

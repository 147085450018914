import * as React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { PATHS } from '../const';
import Combine from '../Pages/Combine/Combine';

// import CatalogPage from '../Pages/CatalogPage/CatalogPage';
// import Home from '../Pages/HomePage/Home';
// import CelenderPurchasePage from '../Pages/CelenderPurchasePage';
import { Authorization, Registration } from '../Pages/AuthRegPage';

// import About from '../Pages/AboutPage/About';
// import NewsPage from '../Pages/NewsPage/NewsPage';
// import PartnershipPage from '../Pages/PartnershipPage/PartnershipPage';
// import InformationJuridical from '../Pages/JuridicalPage/InformationJuridical';
// import InformationDelivery from '../Pages/DeliveryPage/InformationDelivery';
// import InformationExchange from '../Pages/ExchangePage/InformationExchange';
// import InformationPayments from '../Pages/PaymentPage/InformationPayments';
// import InformationHowto from '../Pages/HowToPage/InformationHowto';
// import Information from '../Pages/InformationPage/Information';
// import InformationContacts from '../Pages/InformationContactsPage/InformationContacts';
// import InformationReviews from '../Pages/InformationReviewsPage/InformationReviews';
// import CartPage from '../Pages/CartPage/CartPage';
// import OrderingPage from '../Pages/OrderingPage/OrderingPage';
// import OrdersPage from '../Pages/LK/OrdersPage/OrdersPage';
// import PersonalPage from '../Pages/LK/PersonalPage/PersonalPage';
// import NotificationsPage from '../Pages/LK/NotificationsPage/NotificationsPage';
// import ReviewsPersonalPage from '../Pages/LK/ReviewsPersonalPage/ReviewsPersonalPage';
// import BalancePage from '../Pages/LK/BalancePage/BalancePage';
// import ExportCatalog from '../Pages/ExportCatalog/ExportCatalog';
// import ProductDetails from '../Pages/ProductDetailsPage/ProductDetails/ProductDetailsContainer';
// import OrderDetailsPersonalPage from '../Pages/LK/OrderDetailsPersonalPage/OrderDetailsPersonalPage';
// import WishPage from '../Pages/WishPage/WishPage';



const LazyCatalog = React.lazy(()=> import('../Pages/CatalogPage/CatalogPage'))
const LazyHome = React.lazy(()=> import('../Pages/HomePage/Home'))
const LazyCelenderPurchasePage = React.lazy(()=> import('../Pages/CelenderPurchasePage'))
const LazyAuthorization = React.lazy(()=> import('../Pages/AuthRegPage'))
const LazyRegistration = React.lazy(()=> import('../Pages/AuthRegPage'))
const LazyAbout = React.lazy(()=> import('../Pages/AboutPage/About'))
const LazyNewsPage = React.lazy(()=> import('../Pages/NewsPage/NewsPage'))
const LazyPartnershipPage = React.lazy(()=> import('../Pages/PartnershipPage/PartnershipPage'))
const LazyInformationJuridical = React.lazy(()=> import('../Pages/JuridicalPage/InformationJuridical'))
const LazyInformationDelivery = React.lazy(()=> import('../Pages/DeliveryPage/InformationDelivery'))
const LazyInformationExchange = React.lazy(()=> import('../Pages/ExchangePage/InformationExchange'))
const LazyInformationPayments = React.lazy(()=> import('../Pages/PaymentPage/InformationPayments'))
const LazyInformationHowto = React.lazy(()=> import('../Pages/HowToPage/InformationHowto'))
const LazyInformation = React.lazy(()=> import('../Pages/InformationPage/Information'))
const LazyInformationContacts = React.lazy(()=> import('../Pages/InformationContactsPage/InformationContacts'))
const LazyInformationReviews = React.lazy(()=> import('../Pages/InformationReviewsPage/InformationReviews'))
const LazyCartPage = React.lazy(()=> import('../Pages/CartPage/CartPage'))
const LazyOrderingPage = React.lazy(()=> import('../Pages/OrderingPage/OrderingPage'))
const LazyOrdersPage = React.lazy(()=> import('../Pages/LK/OrdersPage/OrdersPage'))
const LazyPersonalPage = React.lazy(()=> import('../Pages/LK/PersonalPage/PersonalPage'))
const LazyNotificationsPage = React.lazy(()=> import('../Pages/LK/NotificationsPage/NotificationsPage'))
const LazyReviewsPersonalPage = React.lazy(()=> import('../Pages/LK/ReviewsPersonalPage/ReviewsPersonalPage'))
const LazyBalancePage = React.lazy(()=> import('../Pages/LK/BalancePage/BalancePage'))
const LazyExportCatalog = React.lazy(()=> import('../Pages/ExportCatalog/ExportCatalog'))
const LazyOrderDetailsPersonalPage = React.lazy(()=> import('../Pages/LK/OrderDetailsPersonalPage/OrderDetailsPersonalPage'))
const LazyWishPage = React.lazy(()=> import('../Pages/WishPage/WishPage'));
const LazyError404 = React.lazy(()=> import('../Pages/Error404/Error404'));

export const routes =  createBrowserRouter([
    
    {
        path: '/Error400',
        index: false,
        component: LazyError404,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/catalog',
        index: false,
        component: LazyCatalog,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    // {
    //     path: '/product-*',
    //     index: false,
    //     component: LazyProductDetails,
    //     caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
    //     fetchInitialData: PATHS.ALL.fetchInitialData,
    //     end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    // },
    {
        path: '/celender',
        index: false,
        component: LazyCelenderPurchasePage,
        lazy: () => import("../Pages/LK/OrderDetailsPersonalPage/OrderDetailsPersonalPage"),
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: 'orders/:id',
        index: false,
        component: LazyOrderDetailsPersonalPage,
        lazy: () => import("../Pages/LK/OrderDetailsPersonalPage/OrderDetailsPersonalPage"),
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },  
    {
        path: '/',
        component: LazyHome,
        index: true,
        caseSensitive: true, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: false, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/cart',
        index: false,
        component: LazyCartPage,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/about',
        index: false,
        component: LazyAbout,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/news',
        index: false,
        component: LazyNewsPage,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/registration',
        index: false,
        component: Registration,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/authorization',
        index: false,
        component: Authorization,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/for_partners',
        index: false,
        component: LazyPartnershipPage,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/juridical',
        index: false,
        component: LazyInformationJuridical,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/delivery',
        index: false,
        component: LazyInformationDelivery,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/exchange',
        index: false,
        component: LazyInformationExchange,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/payment',
        index: false,
        component: LazyInformationPayments,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/how_to',
        index: false,
        component: LazyInformationHowto,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/information',
        index: false,
        component: LazyInformation,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/contacts',
        index: false,
        component: LazyInformationContacts,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/reviews',
        index: false,
        component: LazyInformationReviews,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/order',
        index: false,
        component: LazyOrderingPage,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/orders',
        index: false,
        component: LazyOrdersPage,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/profile',
        index: false,
        component: LazyPersonalPage,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/notifications',
        index: false,
        component: LazyNotificationsPage,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/balance',
        index: false,
        component: LazyBalancePage,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/wishlist',
        index: false,
        component: LazyWishPage,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/my_reviews',
        index: false,
        component: LazyReviewsPersonalPage,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/catalog_export',
        index: false,
        component: LazyExportCatalog,
        caseSensitive: false, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
    },
    {
        path: '/*',
        index: false,
        component: Combine,
        lazy: () => import('../Pages/Combine/Combine'),
        caseSensitive: true, // Optional. Should be `true` if the static portions of the `path` should be matched in the same case.
        fetchInitialData: PATHS.ALL.fetchInitialData,
        end: true, // Optional. Should be `true` if this pattern should match the entire URL pathname
        // with this data loaded before rendering      
    },
]);
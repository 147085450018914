import api from '../../api/api';
import { initCloseModalState, initModalState } from '../../helpers/initialValues/initialValues';
import { errorAlertIcon, successAlertIcon } from '../../images';
// import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import Text from '../../helpers/Text';
import { getActiveColor, getActiveSize, getCookie } from '../../helpers/helpers';
import {
    payment,
    feedback,
    addToCart,
    getMyCash,
    openTableSize,
    accountDelete,
    contentMessage,
    contentInfoOrder,
    textErrorMessage,
    openVideoForSiew,
    openPhotoForSiew,
    listCurrentOrders,
    addAddressForPost,
    textSuccessMessage,
    contentInfoCollection,
} from './modalWindow/modalWindow';

// import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import SubTitle from '../../Views/InformationViews/HowTo/SubTitle';
import InfoBalanse from '../../Views/InfoBalance/InfoBalanse';
import PDFViewer from '../../Views/PDFViewer/PDFViewer';


const contentApi = api.contentApi;
const apiUser = api.userApi;

export const modalStorage = store => {

    store.on('@init', () => ({ modalState: initModalState }));
    store.on('@init', () => ({
        closeModalState: () => {
            store.dispatch('setModalState', initCloseModalState)
        }
    }))

    store.on('setModalState', ({ modalState }, obj) => {
        return {
            modalState: {
                show: obj.show,
                action: obj.action,
                addClass: obj.addClass,
                onClick: obj.onClick,
                closeModal: obj.closeModal,
                onClickCancel: obj.onClickCancel,
                content: obj.content,
                title: obj.title,
                iconImage: obj.iconImage,
                style: obj.style
            }
        }
    })
    /** ************************************************************************************************** */

    store.on('pdf-viewer', ({ context, closeModalState }, obj, { dispatch }) => {
        try {
            dispatch('setModalState', {
                show: true,
            })
            const addClass = obj?.addClass;
            const addId = obj?.addId;
            const file = obj.link;
            const title = obj.title;
            dispatch('setModalState', {
                show: true,
                title: title,
                addClass: addClass ? addClass : 'modal-file-views',
                content: (<PDFViewer file={file} />)
            })
        } catch (err) {
            console.log('ERROR open modal window view pdf f(pdf-viewer)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR open modal window view pdf f(pdf-viewer)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                // content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('modalRedirectToCart', ({ context, closeModalState, productDetails, currency }, obj, { dispatch }) => {
        try {
            const { minimum_rc, product_sku, product_rc, media, title, sizes, colors, is_collection } = productDetails;
            const { role } = context.init_state.profile;
            const size = sizes.filter(el => el.id === getActiveSize(sizes))[0].title;
            const color = colors.filter(el => el.id === getActiveColor(colors))[0].title;
            const { price, old_price } = productDetails.prices;
            const product_rcAmount = minimum_rc * price;
            const colorActive = getActiveColor(colors);
            const productSkuImage = product_sku.filter(el => el.color === colorActive)[0].image
            const image = productSkuImage ? productSkuImage : media[0].image;

            dispatch('setModalState', {
                show: true,
                title: title,
                addClass: 'modal-add-to-cart',
                content: addToCart(product_rcAmount, is_collection, product_rc, old_price, currency, color, price, image, title, size, role),
                action: {
                    title: ['продолжить покупки', 'перейти в корзину']
                },
                onClick: () => closeModalState(),
                onClickCancel: () => {
                    obj.redirectTo('/cart')
                    closeModalState()
                }
            })
        } catch (err) {
            console.log('ERROR popup f(modalRedirectToCart)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR popup f(modalRedirectToCart)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    });

    store.on('modalOpenListForAddProduct', async ({ context, closeModalState, currency, listOrdersStore }, obj, { dispatch }) => {
        try {

            const listOrders = listOrdersStore.results;

            const changeStatusOrder = (value) => {
                dispatch('setNumberOrderForAddProducts', { numberOrder: value })
                closeModalState()
            };

            const cancelOrders = () => {
                dispatch('setNumberOrderForAddProducts', { numberOrder: null })
                closeModalState()
            }


            dispatch('setModalState', {
                show: true,
                title: 'Добавить товары к существующему заказу',
                content: listCurrentOrders(listOrders, changeStatusOrder, currency),
                action: {
                    title: ['Отменить', null]
                },
                onClick: cancelOrders,
                addClass: 'modal-choose-number-order',
            })

        } catch (err) {
            console.log('ERROR GET LIST ORDERS f(modalOpenListForAddProduct)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR GET LIST ORDERS f(modalOpenListForAddProduct)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })
    //?! модалка оплаты
    store.on('modalCheckPayment', async ({ context, closeModalState, balanceStore, currency, isMobile }, obj, { dispatch }) => {
        try {
            dispatch('setModalState', {
                show: true,

            })
            const { first_name, last_name, middle_name } = context.init_state.profile.user;
            const { role } = context.init_state.profile;
            const { balance } = balanceStore;

            const total_orders_price_unpaid = obj?.total_orders_price_unpaid;
            const order_id = obj?.order_id;
            const total_price = obj?.total_price;

            const redirectTo = obj?.redirectTo;

            const closeRedirect = () => {
                if (redirectTo) redirectTo('/balance');
                closeModalState();
            }
            console.log({ _obj: total_orders_price_unpaid })

            dispatch('setModalState', {
                show: true,
                title: (
                    total_price && isMobile ?
                        <InfoBalanse
                            role={role}
                            total_orders_price_unpaid={total_orders_price_unpaid}
                            total_price={total_price}
                            now_balance={balance}
                            currency={currency}
                        />
                        : 'Пополнение баланса для оплаты'
                ),
                content: await payment(order_id, balance, total_price, currency, first_name, last_name, middle_name, dispatch, redirectTo, closeModalState, isMobile, total_orders_price_unpaid, role),
                addClass: 'modal-payment',
                closeModal: closeRedirect
            })
        } catch (err) {
            console.log('ERROR payment f(modalCheckPayment)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR payment f(modalCheckPayment)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })
    //?! модалка добавления адреса
    store.on('modalAddAddress', async ({ context, closeModalState, addressStore, currency }, obj, { dispatch }) => {
        /**
         * @typeModal - 'create' or 'change'
         */
        try {
            dispatch('setModalState', {
                title: 'Адрес доставки',
                show: true,
            })
            const typeModal = obj?.typeModal;
            const profileId = context.init_state.profile?.id;
            const { first_name, last_name, middle_name, email, phone } = context.init_state.profile.user;

            dispatch('setModalState', {
                show: true,
                title: 'Адрес доставки',
                content: await addAddressForPost(currency, first_name, last_name, middle_name, phone, email, dispatch, closeModalState, typeModal, profileId, context, addressStore),
                addClass: 'modal-add-address'
            })
        } catch (err) {
            console.log('ERROR popup add address f(modalAddAddress)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR popup add address f(modalAddAddress)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })
    //?! модалка изменения адреса
    store.on('modalChangeAddress', async ({ context, closeModalState, addressStore, currency }, obj, { dispatch }) => {
        /**
         * @typeModal - 'create' or 'change'
         */
        try {
            // dispatch('setModalState', {
            //     title: 'Адрес доставки',
            //     show: true,
            // })
            const typeModal = 'change';
            const profileId = context.init_state.profile?.id;
            const { first_name, last_name, middle_name, email, phone } = context.init_state.profile.user;
            const { idAddress } = obj;

            dispatch('setModalState', {
                show: true,
                title: 'Адрес доставки',
                content: await addAddressForPost(currency, first_name, last_name, middle_name, phone, email, dispatch, closeModalState, typeModal, profileId, context, addressStore, idAddress),
                addClass: 'modal-add-address'
            })
        } catch (err) {
            console.log('ERROR popup change current address f(modalChangeAddress)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR popup change current address f(modalChangeAddress)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })
    //?! модалка изменения телефона
    store.on('modalChangePhone', async ({ closeModalState }, obj, { dispatch }) => {
        try {
            dispatch('setModalState', {
                show: true,
                title: 'Смена номера телефона',
                content: 'Данный ресурс находиться в разработке, для изменения номера воспользуйтесь "Формой обратной связи"',
                action: {
                    title: ['Обратная связь', 'Отмена']
                },
                addClass: 'modal-change-phone',
                onClick: () => dispatch('feedback'),
                onClickCancel: closeModalState
            })
        } catch (err) {
            console.log('ERROR popup for change phone f(modalChangePhone)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR popup for change phone f(modalChangePhone)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })
    //?! модалка удаление учётных данных
    store.on('modalDeleteAccaunt', async ({ context, closeModalState }, obj, { dispatch }) => {
        try {
            const { id } = context.init_state.profile.user;
            const userId = id;
            const deleteAccountFunc = async (data, { setFieldValue }) => {
                try {
                    const params = {
                        comment: data.reasonDeletion
                    }
                    setFieldValue('isLoadingDelete', true)
                    const res = await apiUser.deleteUser(data.userId, params)
                    setFieldValue('isLoadingDelete', false)
                    if (res.status === 200) {
                        obj.redirectTo('/registration');
                    } else {
                        let error = [Text({ text: 'error-on-server' })];
                        dispatch('setModalState', {
                            show: true,
                            content: textErrorMessage(error),
                            iconImage: errorAlertIcon,
                            action: {
                                title: ['продолжить', null]
                            },
                            onClick: () => closeModalState()
                        })
                    }
                } catch (err) {
                    console.log('ERROR function delete accaunt f(deleteAccountFunc)', err)
                    let error = [Text({ text: 'error-on-server' }), 'ERROR function delete accaunt f(deleteAccountFunc)'];
                    if (err?.data) {
                        const errors = err.data;
                        if (typeof errors !== 'object') {
                            error.push(`${errors}`)
                        } else {
                            error.push(`${errors[0]}`)
                        }
                    }
                    // dispatch('fixErrorBot',{...error, err})

                    dispatch('setModalState', {
                        show: true,
                        content: textErrorMessage(error),
                        iconImage: errorAlertIcon,
                        addClass: 'modal-alert-error',
                        action: {
                            title: ['продолжить', null]
                        },
                        onClick: () => closeModalState()
                    })
                }
            }
            dispatch('setModalState', {
                show: true,
                title: 'Удаление аккаунта',
                content: await accountDelete(deleteAccountFunc, closeModalState, userId),
                addClass: 'modal-change-phone',
            })

        } catch (err) {
            console.log('ERROR modalDeleteAccaunt f(modalDeleteAccaunt)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR modalDeleteAccaunt f(modalDeleteAccaunt)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    //?! модалка изменения пароля
    store.on('modalChangePassword', async ({ closeModalState }, obj, { dispatch }) => {
        try {
            dispatch('setModalState', {
                show: true,
                title: 'Смена номера телефона',
                content: 'Данный ресурс находиться в разработке, для изменения пароля воспользуйтесь "Формой обратной связи"',
                action: {
                    title: ['Обратная связь', 'Отмена']
                },
                addClass: 'modal-change-phone',
                onClick: () => dispatch('feedback'),
                onClickCancel: closeModalState
            })
        } catch (err) {
            console.log('ERROR modal change password f(modalChangePassword)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR modal change password f(modalChangePassword)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })
    //?! модалка вы уверены удаление учётных данных
    store.on('modalQuestionAreYouSure', ({ context, closeModalState }, obj, { dispatch }) => {
        try {
            const { e, values, setValues } = obj;
            const handeChange = () => {
                setValues({
                    ...values,
                    'receive_newsletter': !e.checked
                });
                closeModalState();
            }
            if (!values.receive_newsletter) return handeChange();

            dispatch('setModalState', {
                show: true,
                content: contentMessage(),
                action: {
                    title: ['Я ПОНИМАЮ', 'Отмена']
                },
                addClass: 'modal-change-phone',
                onClick: () => handeChange(),
                onClickCancel: closeModalState
            })
        } catch (err) {
            console.log('ERROR modal question you are sure f(modalQuestionAreYouSure)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR modal question you are sure f(modalQuestionAreYouSure)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('modalGetMyCach', async ({ context, closeModalState }, obj, { dispatch }) => {
        const { first_name, last_name, middle_name } = context.init_state.profile.user;
        const redirectTo = obj?.redirectTo;
        try {
            dispatch('setModalState', {
                show: true,
            })
            dispatch('setModalState', {
                show: true,
                title: <SubTitle>Данные для возврата денежных средств:</SubTitle>,
                content: await getMyCash(first_name, last_name, middle_name, dispatch, redirectTo, closeModalState),
                addClass: 'modal-get-my-cash'
            })

        } catch (err) {
            console.log('ERROR getMyCach FROM BALANCE f(modalGetMyCach)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR getMyCach FROM BALANCE f(modalGetMyCach)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })
    // //////////////////////////////////////////////////////////////////////////////////////////////
    store.on('modalShowInfoOrder', ({ context, closeModalState }, obj, { dispatch }) => {
        try {
            const { status } = obj;
            const { role } = context.init_state.profile;
            const numberOrder = window.location.href?.split('/').pop();
            dispatch('setModalState', {
                show: true,
                content: contentInfoOrder(status, role, numberOrder),
                action: {
                    title: ['продолжить', null]
                },
                addClass: 'modal-default',
                onClick: () => closeModalState(),
            })
        } catch (err) {
            console.log('ERROR modal show info order f(modalShowInfoOrder)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR modal show info order f(modalShowInfoOrder)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('openModalCollections', ({ context, closeModalState, productDetails, currency }, obj, { dispatch }) => {
        try {
            const { recommended_price } = context.init_state;
            const { role } = context.init_state.profile;
            const product = productDetails;
            const { collections, title } = obj;

            dispatch('setModalState', {
                show: true,
                title: 'Иформация по открытым сборам',
                content: contentInfoCollection(collections, title, product, recommended_price, currency, role),
                action: {
                    title: ['продолжить', null]
                },
                addClass: 'modal-collections',
                onClick: () => closeModalState(),
            })
        } catch (err) {
            console.log('ERROR modal open colections f(openModalCollections)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR modal open colections f(openModalCollections)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('openModalPhoto', ({ context, closeModalState }, obj, { dispatch }) => {
        try {

            dispatch('setModalState', {
                show: true,
                title: null,
                content: openPhotoForSiew(obj.image, obj.urlProduct, closeModalState, obj?.controll),
                action: {
                    title: ['продолжить', null]
                },
                addClass: 'modal-preview-photo',
                onClick: () => closeModalState(),
            })
        } catch (err) {
            console.log('ERROR modal open photo f(openModalPhoto)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR modal open photo f(openModalPhoto)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('openModalVideo', ({ context, closeModalState }, obj, { dispatch }) => {
        try {

            dispatch('setModalState', {
                show: true,
                title: null,
                content: openVideoForSiew(obj.video, obj.preview, obj.urlProduct),
                action: {
                    title: ['продолжить', null]
                },
                addClass: 'modal-preview-photo',
                onClick: () => closeModalState(),
            })
        } catch (err) {
            console.log('ERROR modal open video for preview f(openModalVideo)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR modal open video for preview f(openModalVideo)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('openModalTableSize', ({ context, closeModalState }, obj, { dispatch }) => {
        try {

            dispatch('setModalState', {
                show: true,
                title: null,
                content: openTableSize(),
                action: {
                    title: ['продолжить', null]
                },
                addClass: 'modal-preview-table',
                onClick: () => closeModalState(),
            })
        } catch (err) {
            console.log('ERROR modal open table size f(openModalTableSize)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR modal open table size f(openModalTableSize)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('feedback', async ({ context, closeModalState }, obj, { dispatch }) => {
        try {
            const first_name = context.init_state.profile.user?.first_name;
            const last_name = context.init_state.profile.user?.last_name;
            const middle_name = context.init_state.profile.user?.middle_name;
            const email = context.init_state.profile.user?.email;
            let fullName = '';
            fullName = (first_name && last_name && middle_name) ? `${first_name} ${last_name} ${middle_name}`
                : (last_name && middle_name) ? `${last_name} ${middle_name}`
                    : (first_name && middle_name) ? `${first_name} ${middle_name}` :
                        (first_name && last_name) ? `${first_name} ${last_name}`
                            : '';

            dispatch('setModalState', {
                show: true,
            })
            const newContext = {
                ...context,
                "init_state": {
                    ...context.init_state,
                    activeButton: {
                        ...context.init_state.activeButton,
                        feedbackBtn: false
                    }
                }
            }
            const timerSetTimeout = setTimeout(() => {
                const newContext = {
                    ...context,
                    "init_state": {
                        ...context.init_state,
                        activeButton: {
                            ...context.init_state.activeButton,
                            feedbackBtn: true
                        }
                    }
                }
                dispatch('context', newContext)
                return () => clearTimeout(timerSetTimeout)
            }, 10000)

            dispatch('context', newContext)

            const onSubmit = async (data) => {
                try {
                    const fd = new FormData();
                    fd.set('problem_area', data.problem_area);
                    fd.set('name', data.name);
                    fd.set('email', data.email);
                    fd.set('message', data.message);
                    fd.set('files', data.files);

                    await contentApi.postFeedback(fd)
                    const text = 'Ваше обращение зарегистрировано и передано ответственному сотруднику. Благодарим Вас за сотрудничество!';
                    dispatch('setModalState', {
                        show: true,
                        content: textSuccessMessage(text),
                        iconImage: successAlertIcon,
                        action: {
                            title: ['продолжить', null]
                        },
                        onClick: () => closeModalState()
                    })

                } catch (err) {
                    console.log('ERROR feedback request submit f(feedback)', { err })
                    let error = [Text({ text: 'error-on-server' }), 'ERROR feedback request submit f(feedback)'];
                    if (err?.data) {
                        const errors = err.data;
                        for (let key in errors) {
                            if (data.hasOwnProperty(key)) {
                                error.push(`${errors[key]}`)
                            }
                        }
                    }
                    // dispatch('fixErrorBot',{...error, err})

                    dispatch('setModalState', {
                        show: true,
                        content: textErrorMessage(error),
                        iconImage: errorAlertIcon,
                        action: {
                            title: ['продолжить', null]
                        },
                        onClick: () => closeModalState()
                    })
                }
            }

            dispatch('setModalState', {
                show: true,
                title: 'Форма обратной связи',
                content: await feedback(onSubmit, dispatch, fullName, email, closeModalState),
                addClass: 'modal-feedback'
            })

        } catch (err) {
            console.log('ERROR feedback f(feedback)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR feedback f(feedback)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

}


import React from "react";
import style from './infoBalance.module.scss';
import classNames from "classnames";
import InfoPayRedText from "../PersonalPageViews/InfoPaidOrders/InfoPayRedText";
import { ROLE } from "../../const";

const InfoBalance = ({
    now_balance,
    total_price,
    currency,
    total_orders_price_unpaid,
    role,
}) => {
    console.log({total_orders_price_unpaid})

    const valueBalance = () => {
        if ((now_balance - total_price) > 0) {
            return true;
        }
        return false;
    }
    const styleBalancePosetive = classNames({
        [style['balance__now']]: true,
        [style['balance__now--red']]: !valueBalance(),
    })

    return (
        <React.Fragment>
            <div
                className={styleBalancePosetive}
            >Ваш текущий баланс <span>{now_balance}&nbsp;<span>{currency}</span></span></div>
            { 
                total_orders_price_unpaid && role === ROLE.WHOLESALE && <div
                    className={style['balance__all-price']}
                >Не достаточно для оплаты всех заказов <InfoPayRedText fz={16}>{(total_orders_price_unpaid - now_balance).toFixed(2)}&nbsp;<span>{currency}</span></InfoPayRedText></div>
            }
            {
                total_orders_price_unpaid && <div
                        className={style['balance__all-price']}
                    >Сумма неоплаченных заказов <span>{total_orders_price_unpaid}&nbsp;<span>{currency}</span></span></div>
            }
        </React.Fragment>
    )
}


export default React.memo(InfoBalance);
import React, { useState } from 'react';
import { useStoreon } from 'storeon/react';
import Form from '../Form';
import WrapperBlock from '../GridContainerBlock/WrapperBlock';
import BlockCenter from '../GridContainerBlock/BlockCenter';
import SelectCurrency from '../Select/SelectCurrency';
import { ROLE } from '../../const';

const DropDownList = ({ listItems, role, active, currenciesData, setCurrenciesData }) => {
    const [activeItem, setActiveItem] = useState(active);
    const {dispatch } = useStoreon();
    const handlerChangeDropDown = (e) => {

        role === ROLE.UNREGISTRED && dispatch('setModalState', {
            show: true,
            content: (
                <WrapperBlock>
                    <BlockCenter>
                        {
                            Text({ text: 'notRegistration' })
                        }
                    </BlockCenter>
                </WrapperBlock>
            )
        })
        e.preventDefault();
        setActiveItem(e.target.getAttribute('name'))
        setCurrenciesData({
            ...currenciesData,
            active: e.target.getAttribute('name'),
        })
    }
    return (
        <Form className = {'drop-down__form'}>
                <SelectCurrency
                className={'select__drop-down'}                
                value={activeItem}
                onClick={handlerChangeDropDown}
                options={listItems}
                placeholder={activeItem}
              />

        </Form>
    )
}

export default DropDownList;


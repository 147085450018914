import React, { useState } from 'react';
import ImageUpload from '../ImageUpload';
import Text from '../../helpers/Text';
import classNames from 'classnames';
import {garbageIcon} from '../../images'
import Icon from '../Icon';
import style from '../styles/addfiles.module.scss';
import { rus_to_latin } from '../../helpers/helpers';

/**
 * 
 * @param {
 * @ setFieldValue( name, files);
 * @ label - текст слева при выборе файла
 * @ accept=".png, .jpg, .jpeg, .mp4 ... "
 * @ textButton = ' какой либо информационный текст для добавления файла или иконка'
 * @ clearPreviewFiles = 
 * } param0 
 * @returns 
 */
const AddUploadFiles = ({ 
  accept, 
  label, 
  setFieldValue, 
  countFiles,
  name , 
  multiple = '', 
  textButton, 
  onBlur,
  className,
  onChange = () => {},
  callback = () => { },
}) => {
  const fileInputRef = React.useRef(null);
  const [images, setImages] = useState([]);

  return (
    <div className={classNames({
      [style['files-uploud__form-upload']]:true,
      [style[className]]: !!className
      })
    }
    >
      <p className={style['files-uploud__form-upload-desc']}>
        { 
          label || label === ''?
            <React.Fragment>
              {label}
            </React.Fragment>
              : <React.Fragment>
                  <Text text={'photo.or.video'} />:
                </React.Fragment>
        }
      </p>
      <ImageUpload
     //  clearPreviewFiles = { clearPreviewFiles } //?! необходимо допилить сброс изображения
      >
        {({ preview, onSelectFile, selectedFile, onSelectFiles, isDragActive, getRootProps }) => {
          const deleteImage = (elDel) => {
            setImages(c => [...c.filter((el, i) => i !== elDel)])
            setFieldValue([name], [...images.filter((el, i) => i !== elDel)]);
            // callback([...images.filter((el, i) => i !== elDel)]);
            onSelectFiles([...images.filter((el, i) => i !== elDel)]);
          }

          if (preview === undefined) preview = []
          if (!Array.isArray(preview)) {
            preview = [preview];
          }

          
          return (
            <>
            <ul className={style['files-uploud__form-upload-list']}>
                {
                  preview.map((el, i) => {
                    return (
                    <li key={i} className={style['files-uploud__form-upload-item']}>
                        <img
                          crossOrigin="anonymous"
                          className={style['files-uploud__form-upload-image']}
                          src={el}
                        />
                        <div
                          className={style['files-uploud__form-image--delete']}
                          onClick={() => deleteImage(i)}
                        >
                          <Icon
                            className={style['files-uploud__form-image-icon--delete']}
                            src={garbageIcon}
                            width={15}
                            height={15}
                          />
                        </div>
                      </li>
                    );
                  })
                }
              </ul>

              <div 
                // {...getRootProps()} //?! косяк открывает два окна

                className={style['files-uploud__form-addprod-image']}
              >
                <div className={style['files-uploud__form-addprod-image-wrap']}>
                  <div 
                    className={
                        classNames({
                          [style['files-uploud__form-upload-button']]: true,
                          [style['files-uploud__form-upload-button-text']]: !!textButton
                          
                        })
                      }
                  >
                    {
                      textButton?
                        textButton
                        :<Text text={'attach'} />
                    }
                    <input
                      ref={fileInputRef}
                      id = { 'image' }
                      type = { 'file' }
                      name = { 'image' }
                      onBlur = { onBlur }
                      accept = { accept }
                      multiple = { multiple }
                      className = { 'hidden' }
                      onChange = { e => {
                        onChange(e)
                        // const files = e.currentTarget.files;
                        // onSelectFiles(files);
                        // if(!!multiple) return setFieldValue(name, files);
                        // setFieldValue(name, files[0]);

                        // -----------------------------
                        let files = e.currentTarget.files;
                        let newFiles = [];  
                        if (files.length) {
                          let allFiles = [...images];
                          for(let i = 0; i< files.length; i++ ){
                            newFiles = [new File([files[i]], rus_to_latin(files[i].name), {type: files[i].type} )]
                            allFiles.push(...newFiles);
                          }                        
                          
                          const maxCountFile = allFiles.length;
                          if( maxCountFile > 8 ) {
                            alert('Привышен лимит добавления файлов, максимальное количество 8');
                            allFiles = allFiles.slice(0,8);
                            files = [...files].slice(0,8);
                          }
                          setImages(allFiles)
                          setFieldValue([name], allFiles);
                          setFieldValue('func',onSelectFiles);
                          setFieldValue('funcImage',setImages);
                          onSelectFiles(allFiles);
                        }

                      }}
                    />
                    </div>
                </div>
              </div>
            </>
          );
        }}
      </ImageUpload>
    </div>
  );
};

export default React.memo(AddUploadFiles);

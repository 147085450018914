import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { BrowserRouter } from 'react-router-dom';
import { StoreContext } from 'storeon/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <React.StrictMode>
        <StoreContext.Provider value={store} >
          <BrowserRouter >
              <App />
          </BrowserRouter>
        </StoreContext.Provider>
      </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

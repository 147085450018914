import React from 'react';

import style from './styles/info-pay.module.scss';

const InfoPayRedText = ({
    children,
    fz = 12,
}) => {

    return (
        <div
            style={{
                fontSize: `${fz}px`
            }}
            className = { style['info-pay__text--red'] }
        >{ children }</div>
    )
}

export default InfoPayRedText;
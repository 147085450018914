import api from "../../api/api";
import { ROLE } from "../../const";
import Text from "../../helpers/Text";
import { errorAlertIcon, successAlertIcon } from "../../images";
import { textErrorMessage } from "../modalStorage/modalWindow/modalWindow";

export const payment = store => {
    const orderApi = api.orderApi;

    store.on('payment', async ({ context, closeModalState, orderStore, balanceStore, currency, numberCurrentOrderForAddProduct }, obj, { dispatch }) => {

        try {
            const { role, user } = context.init_state.profile
            const { balance, total_orders_price_unpaid } = balanceStore;
            const { first_name = '', last_name = '', middle_name = '' } = user;
            const { priceDilivery } = orderStore;
            const { price, discount, total_price } = context.init_state.cart_content;

            let params = {
                payment_method: balance >= total_price ? 3 : 1, //obj.payment_methods,//
                delivery_method: obj.variant,
                delivery_address: obj.selectedAdress,
                agree_personal_data: obj.agree_personal_data,
                wait_call: obj.waitForCall,
                currency: currency?.toLocaleUpperCase(),
                order_cost: price,
                discount: discount,
                total_cost: price,
                add_goods_order_id: +numberCurrentOrderForAddProduct ?? 0, // localStore берём номер заказа куда добавить товар по старому отправляем 0 если нет добавления
            };
            if (role === ROLE.RETAIL && !!!numberCurrentOrderForAddProduct) {
                params = {
                    ...params,
                    delivery_cost: priceDilivery.price,
                }
            }
            const res = await orderApi.createOrder(params);
            obj.redirectTo('/orders');

            if (obj.numberCurrentOrderForAddProduct) {
                dispatch('setNumberOrderForAddProducts', { numberOrder: null });
                const message = [`Товары добавлены в Ваш заказ №${obj.numberCurrentOrderForAddProduct}`, 'Приятного шопинга в мире моды']
                dispatch('setModalState', {
                    show: true,
                    content: textErrorMessage(message),
                    iconImage: successAlertIcon,
                    addClass: 'modal-alert-error',
                    action: {
                        title: ['продолжить', null]
                    },
                    onClick: () => (
                        closeModalState()
                    ),
                    closeModal: () => (
                        closeModalState()
                    )
                })
                return
            }

            //?! если баланс меньше суммы заказа показать попап для оплаты суммы и прикладывания чека об оплаты
            if (balance < total_price) {
                const order_id = res.id;
                const params = {
                    order_id: order_id,
                    balance: balance,
                    total_price: total_price,
                    first_name,
                    last_name,
                    middle_name,
                    redirectTo: obj.redirectTo,
                    total_orders_price_unpaid: total_orders_price_unpaid,
                }
                return dispatch('modalCheckPayment', params)
            }

            //?! если больше переходим на страницу orders
            const message = ['Ваш заказ оформлен. При наличии на счету достаточной суммы денежных средств они будут списаны автоматически. Приятного шопинга в мире моды!']
            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(message),
                iconImage: successAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => (
                    obj.redirectTo('/orders'),
                    closeModalState()
                ),
                closeModal: () => (
                    obj.redirectTo('/orders'),
                    closeModalState()
                )
            })
            return

        } catch (err) {
            console.log('ERROR CREATE PAYMENT METHOD f(payment)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR CREATE PAYMENT METHOD f(payment)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }

    })


}
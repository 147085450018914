import React from 'react';

import style from './style/addtocartwrapper.module.scss';

const AddToCartDescriptionContentProductRc = ({
    product_rc,
}) => {

    return(
            <span>{product_rc}</span>                  
    )
}

export default AddToCartDescriptionContentProductRc;

export const delay = async (ms) => await new Promise(resolve => setTimeout(resolve, ms));

export const arrayClone = (arr) => {
    var i, copy;
    const copyObject = obj => ({ ...obj })
    if (Array.isArray(arr)) {
        copy = arr.slice(0);
        for (i = 0; i < copy.length; i++) {
            copy[i] = arrayClone(copyObject(copy[i]));
        }
        return copy;
    }
    else {
        return arr;
    }
}

export const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export const getCookie = (name) => {
    try {
        let nameEQ = name + '=';
        let ca = document.cookie?.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    } catch (e) {
        return null;
    }
}
export const setCookie = (name, value, days = 365) => {
    let expires = '';
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    }
    // document.cookie = name + '=' + (value || '') + expires + '; path=/; flavor=choco; SameSite=Lax; Secure'
    document.cookie = name + '=' + (value || '') + expires + '; path=/;'

}

export const removeCookie = (name) => {
    const CookiesDelete = (name) => {
        const cookies = document.cookie?.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const names = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = names + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
            document.cookie = names + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
    }
    CookiesDelete(name)
}

export const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
    return true;
};

export const getLocalStorage = (key) => {
    const value = localStorage.getItem(key);
    try {
        return JSON.parse(value);
    } catch {
        return value;
    }
};

export const removeLocalStorage = (key) => {
    localStorage.removeItem(key);
    return true;
};

export const checkLocalStorage = (key) => {

    for (let index = 0; index < localStorage.length; index++) {
        if (localStorage.key(index) === key) {
            return true;
        }
    }
    return false;
};

export const isTargetBlank = (isBlank) => {
    return isBlank ? '_blank' : '';
}

export const initialsName = (first_name, last_name) => {
    return `${first_name[0]}${last_name[last_name.length - 1]}`;
}

export const unicArr = (arr) => {
    const result = [];
    const duplicatesIndices = [];

    arr.forEach((current, index) => {
        if (duplicatesIndices.includes(index)) return;

        result.push(current);

        for (let comparisonIndex = index + 1; comparisonIndex < arr.length; comparisonIndex++) {
            const comparison = arr[comparisonIndex];
            const currentKeys = Object.keys(current);
            const comparisonKeys = Object.keys(comparison);

            if (currentKeys.length !== comparisonKeys.length) continue;

            const currentKeysString = currentKeys.sort().join('').toLowerCase();
            const comparisonKeysString = comparisonKeys.sort().join('').toLowerCase();
            if (currentKeysString !== comparisonKeysString) continue;

            // Проверяем индексы ключей
            let valuesEqual = true;
            for (let i = 0; i < currentKeys.length; i++) {
                const key = currentKeys[i];
                if (current[key] !== comparison[key]) {
                    valuesEqual = false;
                    break;
                }
            }
            if (valuesEqual) duplicatesIndices.push(comparisonIndex);
        }
    });
    return result;
}

export const rus_to_latin = (str) => {

    var ru = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
        'е': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'и': 'i',
        'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
        'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
        'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
        'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'u', 'я': 'ya',
        'ъ': 'ie', 'ь': '', 'й': 'i'
    }, n_str = [];

    for (var i = 0; i < str.length; ++i) {
        n_str.push(
            ru[str[i]]
            || ru[str[i].toLowerCase()] == undefined && str[i]
            || ru[str[i].toLowerCase()].replace(/^(.)/, function (match) { return match.toUpperCase() })
        );
    }

    return n_str.join('');
}


export const debounce = (functionContext, wait, immediate) => {
    debugger
    let timeout = null;
    return function () {
        let context = this,
            args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) functionContext.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) functionContext.apply(context, args);
    }();
};

export const rotateMatrix = (matrix) => {
    var result = [],
        y,
        h,
        x,
        w;
    for (y = 0, h = matrix.length; y < h; y++) {
        for (x = 0, w = matrix[y].length; x < w; x++) {
            if (!result[x]) result[x] = [];
            result[x][y] = matrix[y][x];
        }
    }
    return result;
};

export const getActiveColor = color => !Array.isArray(color) ? color : color.filter(el => el.selected)[0].id;

export const getActiveSize = size => !Array.isArray(size) ? size : size.filter(el => el.selected)[0].id;

export const randomNumberRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const unicArrFromObject = (arrObject, key) => {
    return arrObject.reduce((acc, cur, index, array) => {
        const value = `"${key}":${cur[key]}`
        if (JSON.stringify(acc).includes(value)) {
            return acc
        }
        acc = [...acc, cur];
        return acc
    }, [])

}

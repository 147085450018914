import api from "../../api/api";
import Text from "../../helpers/Text";
import { errorAlertIcon } from "../../images";
import { textErrorMessage } from "../modalStorage/modalWindow/modalWindow";


export const news = store => {

    const apiContent = api.contentApi;

    store.on('@init', () => ({ newsStore: [] }))

    store.on('setValueNews', ({ newsStore }, obj, { dispatch }) => {
        return { newsStore: obj }
    })

    store.on('getNews', async ({ context, closeModalState }, obj, { dispatch }) => {
        try {
            let params = {}
            if (obj?.id) params = {
                page: 1,
                page_size: 20,
                rubrics: obj.id
            };
            const dataNews = await apiContent.getNews(params);
            const contextNews = {
                ...context,
                'init_state': {
                    ...context.init_state,
                    news: dataNews.results,
                }
            }
            dispatch('context', contextNews)
            dispatch('setValueNews', dataNews.results); //?!! не знаю нужно оно 
        } catch (err) {
            console.log('ERROR storege get news f(getNews) ', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR storege get news f(getNews) '];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('getNewsDetails', async ({ context }, { id, ...obj }, { dispatch }) => {
        try {

            const params = {}
            const dataDetalsNews = await apiContent.getNewsDetails(id, params);
            const contextNews = {
                ...context,
                'init_state': {
                    ...context.init_state,
                    newsDetails: dataDetalsNews,
                }
            }
            dispatch('context', contextNews)
            return
        } catch (err) {
            console.log('ERROR storege get news f(getNewsDetails) ', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR storege get news f(getNewsDetails) '];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

        }
    })
}
import React from 'react';
import Title from '../Title';
import AsyncComponent from '../../helpers/asyncComponent';

const AsyncPricesContainer = AsyncComponent(() => {
    return import('../../Views/ProductDetailsViews/PricesContainer/PricesContainer');
});

const AsyncColorsButton = AsyncComponent(() => {
    return import('../../Views/ProductDetailsViews/ColorsItems/ColorsItems');
});

const AsyncSizesButton = AsyncComponent(() => {
    return import('../../Views/ProductDetailsViews/SizesItems/SizesItems');
});

const PhotoView = AsyncComponent(() => {
    return import('../../Views/PhotoView/ViewsImage');
});

const style = AsyncComponent(() => {
    return import('./styles/card-collections.module.scss');
});



const CardCollectionView = ({
    number,
    title,
    product,
    recommended_price,
    isLoadingDataProduct,
    image,
    prices,
    sizes,
    colors,
    currency,
    role,
    ...props
}) => {
    const { items } = props;
    const colorCollections = colors.map( el => el.id === items[0].size.color_id? { ...el, selected: true } : null ).filter( el => el !== null )
    const currentImageFromColor = image.filter( el => el.color === items[0].size.color_id ?? el)[0];
    const sizeCollections = sizes.map( item =>  {
        
        const currentSize = items.filter( el => {
            return el?.size.id === item.id
        } );
        if(currentSize[0]?.size)
            return {
                ...item,
                image: currentSize[0].size?.image,
                redeemed: currentSize[0].redeemed,
                selected: false
            }
            return currentSize ;
        }).filter( el => el !== undefined)

    return (  
        <div
            className={style['card-collections__container']}
        >
            <div
                className={style['card-collections__title-wrap']}
            >   
                <Title 
                    type= { 'h3' }
                >Сбор {number}</Title>
                <Title 
                    type= { 'h4' }
                >{title}</Title>
            </div>
            <div
                className={style['card-collections__photo-wrap']}
            >                
                <PhotoView image = { currentImageFromColor.image } />
            </div>
            <div
                className={style['card-collections__recommend-wrap']}
            >
                <AsyncPricesContainer
                    recommended_price = { recommended_price }
                    currency = { currency }
                    prices = { prices }
                    role = { role }
                />
            </div>
            <div
                className={style['card-collections__colors-wrap']}
            >
                <AsyncColorsButton
                    collection
                    productId = { product.id }
                    colors = { colorCollections }
                    sizes = { sizeCollections }
                />
            </div>
            <div
                className={style['card-collections__sizes-wrap']}
            >
                <AsyncSizesButton
                    collection
                    isLoadingDataProduct={isLoadingDataProduct}
                    productId={product.id}
                    colors={colorCollections}
                    sizes={sizeCollections}
                />
            </div>
        </div>
    )
}

export default CardCollectionView;
import api from "../../api/api";
import { putUserDataSerializer } from "../../helpers/serializers";
import Text from "../../helpers/Text";
import { errorAlertIcon } from "../../images";
import { textErrorMessage } from "../modalStorage/modalWindow/modalWindow";

export const profileLK = store => {

  const orderApi = api.orderApi;
  const userApi = api.userApi;

  store.on('getAdresses', async ({ context, closeModalState, orderStore }, obj, { dispatch }) => {
    try {
      const params = {
        page: obj?.page ?? 1
      }
      const res = await orderApi.getOrderAddressDeliviry(params);
      dispatch('setAddressStore', {
        count: res.count,
        results: [...res.results],
        textSearch: '',
        currentPage: obj?.page ?? 1
      })
    } catch (err) {
      console.log('ERROR GET DATA FROM REQUEST Adresses f(getAdresses)', err);
      let error = [Text({ text: 'error-on-server' }), 'ERROR GET DATA FROM REQUEST Adresses f(getAdresses)'];
      if (err?.data) {
        const errors = err.data;
        if (typeof errors !== 'object') {
          error.push(`${errors}`)
        } else {
          error.push(`${errors[0]}`)
        }
      }
      // dispatch('fixErrorBot',{...error, err})
      dispatch('setModalState', {
        show: true,
        content: textErrorMessage(error),
        iconImage: errorAlertIcon,
        addClass: 'modal-alert-error',
        action: {
          title: ['продолжить', null]
        },
        onClick: () => closeModalState()
      })
    }
  })

  store.on('deleteAddresDelivery', async ({ context, closeModalState, orderStore, addressStore }, obj, { dispatch }) => {
    try {
      const { idAddress } = obj;
      await orderApi.deleteByIdOrderAddressDeliviry(idAddress)
      dispatch('getAdresses')
    } catch (err) {
      console.log('ERROR delete addres delivery f(deleteAddresDelivery)', err)
      let error = [Text({ text: 'error-on-server' }), 'ERROR delete addres delivery f(deleteAddresDelivery)'];
      if (err?.data) {
        const errors = err.data;
        if (typeof errors !== 'object') {
          error.push(`${errors}`)
        } else {
          error.push(`${errors[0]}`)
        }
      }
      // dispatch('fixErrorBot',{...error, err})
      dispatch('setModalState', {
        show: true,
        content: textErrorMessage(error),
        iconImage: errorAlertIcon,
        addClass: 'modal-alert-error',
        action: {
          title: ['продолжить', null]
        },
        onClick: () => closeModalState()
      })
    }

  })

  store.on('searchAddress', async ({ context, closeModalState, addressStore }, obj, { dispatch }) => {
    try {
      const { q } = obj;
      dispatch('setAddressStore', {
        textSearch: q ?? addressStore.textSearch,
        currentPage: 1,
        isLoadingAddress: true,
        results: addressStore.results
      })

      const resSearch = await orderApi.getOrderAddressSearch({ q: q })
      dispatch('setAddressStore', {
        currentPage: 1,
        isLoadingAddress: false,
        count: resSearch.length,
        results: [...resSearch],
      })

    } catch (err) {
      console.log('ERROR get data search address recieve f(searchAddress)', err)
      let error = [Text({ text: 'error-on-server' }), 'ERROR get data search address recieve f(searchAddress)'];
      if (err?.data) {
        const errors = err.data;
        if (typeof errors !== 'object') {
          error.push(`${errors}`)
        } else {
          error.push(`${errors[0]}`)
        }
      }
      // dispatch('fixErrorBot',{...error, err})

      dispatch('setModalState', {
        show: true,
        content: textErrorMessage(error),
        iconImage: errorAlertIcon,
        addClass: 'modal-alert-error',
        action: {
          title: ['продолжить', null]
        },
        onClick: () => closeModalState()
      })
    }

  })

  store.on('moreAddress', async ({ context, closeModalState, addressStore }, obj, { dispatch }) => {
    try {
      const page = addressStore.currentPage + 1;
      const params = {
        page: page
      }
      const res = await orderApi.getOrderAddressDeliviry(params);

      dispatch('setAddressStore', {
        ...res,
        count: res.count,
        results: [...res.results],
        textSearch: '',
        currentPage: page,
      })

    } catch (err) {
      console.log('ERROR get data more address recieve f(moreAddress)', err)
      let error = [Text({ text: 'error-on-server' }), 'ERROR get data more address recieve f(moreAddress)'];
      if (err?.data) {
        const errors = err.data;
        if (typeof errors !== 'object') {
          error.push(`${errors}`)
        } else {
          error.push(`${errors[0]}`)
        }
      }
      // dispatch('fixErrorBot',{...error, err})

      dispatch('setModalState', {
        show: true,
        content: textErrorMessage(error),
        iconImage: errorAlertIcon,
        addClass: 'modal-alert-error',
        action: {
          title: ['продолжить', null]
        },
        onClick: () => closeModalState()
      })
    }
  })

  store.on('updateUserData', async ({ context, closeModalState }, obj, { dispatch }) => {
    const { setFieldValue, setFieldError } = obj;
    try {
      setFieldValue('isSaved', false)
      const userId = context.init_state.profile.user.id;
      const params = {
        lastname: obj.last_name,
        firstname: obj.first_name,
        patronymic: obj.middle_name,
        email: obj.email,
        receive_newsletter: obj.receive_newsletter,
        inn: obj.inn,
        companyName: obj.companyName,
        addresSite: obj.addresSite,
        vk: obj.vk,
        instagram: obj.instagram,
        otherSocialLink: obj.otherSocialLink,
      };

      const res = await userApi.putUser(userId, putUserDataSerializer(params));
      const newContext = {
        ...context,
        "init_state": {
          ...context.init_state,
          profile: {
            ...context.init_state.profile,
            organization: {
              ...context.init_state.profile.organization,
              inn: res.data?.inn ?? '',
              organization: res.data?.organization ?? '',
            },
            links: {
              ...context.init_state.profile.links,
              vk_link: res.data?.vk_link ?? '',
              insta_link: res.data?.insta_link ?? '',
              site_link: res.data?.site_link ?? ''
            },
            user: {
              ...context.init_state.profile.user,
              username: res.data.username,
              email: res.data.email,
              first_name: res.data.first_name,
              middle_name: res.data.middle_name,
              last_name: res.data.last_name,
            },
          }
        }
      }

      dispatch('context', newContext);

      const timerTimeout = setTimeout(() => {
        setFieldValue('isSaved', true)
        return () => clearTimeout(timerTimeout);
      }, 1700)

    } catch (err) {
      setFieldValue('isSaved', true)
      console.log('ERROR GET DATA FROM REQUEST update user data f(updateUserData)', err);
      let error = [Text({ text: 'error-on-server' }), 'ERROR GET DATA FROM REQUEST update user data f(updateUserData)'];

      if (err?.data) {
        const errors = err.data;
        if (typeof errors !== 'object') {
          error.push(`${errors}`)
        } else {
          error.push(`${errors[0]}`)
        }
        for (let key in errors) {
          error.push(`${errors[key]}`)
          const element = errors[key][0];
          const timerTimeout = setTimeout(() => {
            setFieldError(key, element);
            return () => clearTimeout(timerTimeout);
          }, 1000)
        }
      }
      // dispatch('fixErrorBot',{...error, err})
      dispatch('setModalState', {
        show: true,
        content: textErrorMessage(error),
        iconImage: errorAlertIcon,
        action: {
          title: ['продолжить', null]
        },
        onClick: () => closeModalState()
      })
    }
  })

  const initValueAddressStore = {
    count: 0,
    results: [],
    textSearch: '',
    currentPage: 1,
    isLoadingAddress: false,
  }
  store.on('@init', () => ({ addressStore: { ...initValueAddressStore } }));
  store.on('setAddressStore', ({ addressStore }, obj) => ({
    addressStore: {
      ...addressStore,
      ...obj,
      results: [...obj.results]
    }
  }))
}

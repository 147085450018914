import api from "../../api/api";
import dayjs from "../../helpers/dayjs";
import { debounceRequest } from "../../helpers/debounceRequest";
import { initialFiltersOrders } from "../../helpers/initialValues/initialValues";
import Text from "../../helpers/Text";
import { errorAlertIcon } from "../../images";
import { textErrorMessage } from "../modalStorage/modalWindow/modalWindow";


export const order = store => {
    const initialDate = new Date(new Date().getTime() - (3600000 * 60 * 7));
    const orderApi = api.orderApi;
    const apiCart = api.cartApi

    store.on('test', async ({ context, closeModalState, orderStore }, obj, { dispatch }) => {
        const url = '/' + window.location.href.split('/').pop();
        const getDataOrder = await api.getPage({ url });
    })

    store.on('removeItemFromOrder', async ({ context, closeModalState, orderStore }, obj, { dispatch }) => {
        try {
            dispatch('setDataOrderListItem', {
                isLoadingCancelItems: {
                    isLoading: true,
                    id: obj.id_goods
                }
            })
            const idOrder = orderStore.id;
            const params = {
                order_id: idOrder,
                id: obj.id_goods,
            }
            const res = await orderApi.cancelOrderItem(params)
            if (res.length && res[0]?.items) {
                let countItems = 0;
                const countDiscardItems = res.reduce((prev, cur) => {
                    countItems++;
                    cur.items.filter(el => {
                        if (el.status.id !== 'canceled') return prev = prev + 1;
                        return prev;
                    })
                    return prev
                }, 0);
                if (countItems === countDiscardItems) return obj?.redirectTo('/orders');
            } else {
                if (res.filter(el => el.status.id !== 'canceled').length === 0) return obj?.redirectTo('/orders');
            }
            dispatch('setDataOrderListItem', {
                listItemOrder: res,
                isLoadingCancelItems: {
                    isLoading: false,
                    id: obj.id_goods
                }

            })
            const url = '/' + window.location.href.split('/').pop();

            dispatch('updateGetDataOrder')
            return obj?.redirectTo('/orders' + url)
        } catch (err) {
            console.log('ERROR remove item from order f(removeItemFromOrder) ', err);
            let error = [Text({ text: 'error-on-server' }), 'ERROR remove item from order f(removeItemFromOrder) '];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})
            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('getDataOrder', async ({ closeModalState }, obj, { dispatch }) => {
        try {
            const url = window.location.href.split('/').pop();
            const numberId = +url.split('-').pop()
            if (typeof numberId !== 'number' || numberId === NaN) return console.log('no id orders');
            // if (debounceRequest(numberId, 10)) return;
            dispatch('setDataOrderStore', {
                isLoadingOrder: true
            })
            const res = await orderApi.getOrderItems({ order_id: numberId });
            dispatch('setDataOrderStore', {
                isLoadingOrder: false
            })

            dispatch('setDataOrderListItem', {
                listItemOrder: res,
            })

            //---------------------------------------------------------------
        } catch (err) {
            console.log('ERROR get data order f(getDataOrder) ', err);
            console.log({ test: window.location.href })
            if (!err?.data?.status) {

                dispatch('setModalState', {
                    show: true,
                    content: textErrorMessage([err.data.message]),
                    iconImage: errorAlertIcon,
                    addClass: 'modal-alert-error',
                    action: {
                        title: ['продолжить', null]
                    },
                    onClick: () => {
                        obj.redirectTo('/notifications')
                        closeModalState();
                    },
                    closeModal: () => {
                        obj.redirectTo('/notifications')
                        closeModalState();
                    }
                })
            }
            dispatch('fixErrorBot', { error: 'ERROR get data order f(getDataOrder) ', err })
        }
    })

    // updateGetDataOrder - сейчас не использую
    store.on('updateGetDataOrder', async ({ closeModalState }, obj, { dispatch }) => {

        try {
            const orderId = +window.location.href.split('/').pop().split('-').pop();
            if (!orderId) return;
            const res = await orderApi.getOrderItems({ order_id: orderId });
            dispatch('setDataOrderListItem', {
                listItemOrder: res,
            })
            const url = '/' + window.location.href.split('/').pop();

            const getDataOrder = await api.getPage({ url });

            dispatch('setBalanceStore', { balance: getDataOrder.init_state.profile.balance })
            dispatch('setDataOrderStore', {
                ...getDataOrder.init_state.order,
            })

        } catch (err) {
            console.log('ERROR update data in order f(updateGetDataOrder)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR update data in order f(updateGetDataOrder)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }


    })

    store.on('getCountryDeliviry', async ({ context, closeModalState, orderStore, currency }, obj, { dispatch }) => {
        try {
            if (!obj?.country) return

            const paramsCountryDilivery = {
                country: obj.country,
                currency: currency?.toLocaleUpperCase()
            }
            const res = await orderApi.getCountryDeliviry(paramsCountryDilivery);
            dispatch('setDataOrderStore', {
                ...orderStore,
                priceDilivery: res
            })
        } catch (err) {
            console.log('ERROR GET COUNTRY delivery f(getCountryDeliviry)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR GET COUNTRY delivery f(getCountryDeliviry)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})
            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('sendToArchive', ({ context, closeModalState, orderStore }, obj, { dispatch }) => {
        try {
            const params = {
                order_id: obj.id,
            }
            const res = api.orderApi.sendToArchive(params);

        } catch (err) {
            console.log('ERROR send order to archive dont work f(sendToArchive)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR send order to archive dont work f(sendToArchive)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})
            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }

    })

    store.on('cancelOrder', async ({ context, closeModalState, orderStore }, obj, { dispatch }) => {
        try {
            const params = {
                order_id: obj.id,
            }
            dispatch('setDataOrderStore', {
                isLoadingOrders: true,
            })
            await api.orderApi.cancelOrder(params);
            dispatch('getOrders')
            dispatch('setDataOrderStore', {
                isLoadingOrders: false,
            })
        } catch (err) {
            console.log('ERROR cancel order f(cancelOrder) ', err);
            let error = [Text({ text: 'error-on-server' }), 'ERROR cancel order f(cancelOrder) '];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }

    })

    store.on('clearAllFiltersOrders', async ({ listOrdersLk }, obj, { dispatch }) => {
        try {
            const date = {
                created_at__gte: initialDate,
                created_at__lte: new Date()
            }
            let params = {
                ...initialFiltersOrders,
            }
            dispatch('setListOrders', {
                tableBodyData: [],// list orders
                isLoadingOrders: true,
                dateFilterData: { ...date },
                searchOrderForFio: '',
                currentPage: 1,
                status: null,
                count: 0
            })
            const res = await orderApi.getOrders(params);
            const tableBodyData = res.results;

            dispatch('setListOrders', {
                tableBodyData: tableBodyData.length ? tableBodyData : [],// list orders
                dateFilterData: { ...date },
                searchOrderForFio: '',
                isLoadingOrders: false,
                currentPage: 1,
                status: null,
                count: res?.count ?? 0
            })
            return true
        } catch (err) {
            console.log('ERROR clear all filters orders f(clearAllFiltersOrders) ', err);
            let error = [Text({ text: 'error-on-server' }), 'ERROR clear all filters orders f(clearAllFiltersOrders) '];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})
        }
    });

    store.on('@init',()=>({ordeInitValueFiltersOrders: initialFiltersOrders}));
    store.on('setInitValueFiltersOrders',({_},obj)=>({ordeInitValueFiltersOrders: obj}));

    store.on('getOrders', async ({ context,ordeInitValueFiltersOrders, closeModalState, listOrdersLk }, obj, { dispatch }) => {
        try {
            const newDateFilterData = listOrdersLk.dateFilterData;
            const newSearchOrderForFio = listOrdersLk.searchOrderForFio;
            delete listOrdersLk['currentPage']
            delete listOrdersLk['tableBodyData']
            delete listOrdersLk['dateFilterData']
            delete listOrdersLk['searchOrderForFio']
            delete listOrdersLk['isLoadingOrders']
            delete listOrdersLk['count']

            let params = {
                ...ordeInitValueFiltersOrders,
            }
            let date = {
                created_at__lte: obj?.created_at__lte ? obj?.created_at__lte : newDateFilterData.created_at__lte,
                created_at__gte: obj?.created_at__gte ? obj?.created_at__gte : newDateFilterData.created_at__gte
            }

            if (obj?.created_at__gte) {
                params = {
                    ...params,
                    status: listOrdersLk?.status,
                    created_at__gte: dayjs(api.language, obj?.created_at__gte).format(),
                    created_at__lte: dayjs(api.language, newDateFilterData.created_at__lte).format(),
                    q: newSearchOrderForFio,
                }
            }

            if (obj?.created_at__lte) {
                params = { ...params, status: listOrdersLk?.status, created_at__lte: dayjs(api.language, obj?.created_at__lte).format(), q: newSearchOrderForFio, created_at__gte: dayjs(api.language, newDateFilterData.created_at__gte).format() }

            }

            if (obj?.status) {
                params = {
                    ...params,
                    ...listOrdersLk,
                    status: obj?.status,
                    created_at__gte: dayjs(api.language, newDateFilterData.created_at__gte).format(),
                    created_at__lte: dayjs(api.language, newDateFilterData.created_at__lte).format(),
                    q: obj?.q ?? newSearchOrderForFio,
                }
            } else {
                if (obj?.status === null) {
                    params = {
                        ...params,
                        ...listOrdersLk,
                        created_at__gte: dayjs(api.language, newDateFilterData.created_at__gte).format(),
                        created_at__lte: dayjs(api.language, newDateFilterData.created_at__lte).format(),
                        q: newSearchOrderForFio,
                    }
                    delete params['status']
                }
            }
            if (obj?.q) {
                params = {
                    ...params,
                    ...listOrdersLk,
                    status: listOrdersLk?.status,
                    created_at__gte: dayjs(api.language, newDateFilterData.created_at__gte).format(),
                    created_at__lte: dayjs(api.language, newDateFilterData.created_at__lte).format(),
                    q: obj?.q,
                }

            } else {
                if (obj?.q === '') {
                    params = {
                        ...params,
                        status: listOrdersLk?.status,
                        created_at__gte: dayjs(api.language, newDateFilterData.created_at__gte).format(),
                        created_at__lte: dayjs(api.language, newDateFilterData.created_at__lte).format(),
                        q: obj?.q,
                    }

                }
            }

            if (obj?.page) {
                params = {
                    ...params,
                    page: obj?.page
                }
            }

            dispatch('setListOrders', {
                dateFilterData: { ...date },
                isLoadingOrders: true,
                searchOrderForFio: obj?.q ?? newSearchOrderForFio,
            })
            dispatch('setInitValueFiltersOrders', params)
            const res = await orderApi.getOrders(params);
            const tableBodyData = res.results;

            dispatch('setListOrders', {
                tableBodyData: tableBodyData.length ? tableBodyData : [],// list orders
                dateFilterData: { ...date },
                // searchOrderForFio: obj?.q ?? newSearchOrderForFio,
                isLoadingOrders: false,
                currentPage: obj?.page ?? 1,
                status: obj?.status ?? listOrdersLk.status,
                count: res?.count ?? 0
            })
            return true
            //---------------------------------------------------------------
        } catch (err) {
            console.log('ERROR GET ORDERS f(getOrders)', err);
            let error = [Text({ text: 'error-on-server' }), 'ERROR GET ORDERS f(getOrders)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('changeAgreement-products', async ({ context, closeModalState, cartStore }, obj, { dispatch }) => {
        try {
            let tempElement = true;
            let amountTrueItem = 0;
            let allCount = 0;
            let valueEnableAllSelectFromServer = false;
            if (debounceRequest(obj)) return;

            const params = {
                id: obj.productId,
                change_agreement: !+obj.changeAgreement,
                qty: +obj.qty,
                selected: true
            }
            const res = await apiCart.updateCartData([params]);
            if (res.cartitem_set[0]?.items) {

                await res.cartitem_set.reduce((prev, cur, index, arr) => {
                    cur.items.filter(el => {
                        allCount += cur.items.length;
                        if (tempElement === el.selected) amountTrueItem++
                        if (allCount === amountTrueItem) return valueEnableAllSelectFromServer = true;
                        return valueEnableAllSelectFromServer = false
                    })
                }, 0)
            } else {
                await res.cartitem_set.reduce((prev, cur, index, arr) => {
                    const allCount = arr.length;
                    if (tempElement === cur.selected) amountTrueItem++
                    if (allCount === amountTrueItem) return valueEnableAllSelectFromServer = true;
                    return valueEnableAllSelectFromServer = false
                }, 0)
            }
            dispatch('setDataCartStore', {
                ...cartStore,
                ...res,
                cart_ids: res.cart_ids,
                cartitem_set: res.cartitem_set,
                in_stock: res.in_stock,
                enableAllSelect: valueEnableAllSelectFromServer,
                agreeWitheRegulations: true,
                isLoadingCart: true
            })
        } catch (err) {
            console.log('ERROR GET DATA CHANGE AGREEMENT f(changeAgreement-products)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR GET DATA CHANGE AGREEMENT f(changeAgreement-products)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('getSpecification', async ({ context, closeModalState, orderStore }, obj, { dispatch }) => {
        try {

            dispatch('setModalState', {
                show: true,
            })
            const params = {
                "order_id": obj.idOrder.split('-').pop()
            }
            const specific = await orderApi.postOrderSpecification(params)

            dispatch('setModalState', {
                show: false,
            })
            dispatch('pdf-viewer', {
                link: specific.specification,
                title: 'Спецификация',
                addClass: 'modal-specification',
                addId: 'pdfviewer-specif'
            })
        } catch (err) {
            dispatch('fixErrorBot', { error: 'get Specification', err })
            dispatch('setModalState', {
                show: true,
                content: 'данный ресурс отлаживается и может работать не стабильно, извините за неудобства',
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    }
    )


    //------------------------------------------------------------------------------------------------------------------

    const initValueOrderListItem = {
        dataOrderItems: [],
        isLoadingCancelItems: {
            isLoading: false,
            id: null
        },
    }
    store.on('@init', () => ({ orderStoreListItem: { ...initValueOrderListItem } }));
    store.on('setDataOrderListItemZero', ({ _ }, obj) => ({ orderStoreListItem: initValueOrderListItem }))
    store.on('setDataOrderListItem', ({ orderStoreListItem }, obj) => {
        return {
            orderStoreListItem: {
                ...orderStoreListItem,
                dataOrderItems: obj.listItemOrder ? [...obj.listItemOrder] : orderStoreListItem.dataOrderItems,
                isLoadingCancelItems: obj?.isLoadingCancelItems ? obj?.isLoadingCancelItems : orderStoreListItem.isLoadingCancelItems,
            }
        }
    }
    );

    const initValueOrder = {
        delivery_address: {},
        isLoadingOrder: false,
    }
    store.on('@init', () => ({ orderStore: { ...initValueOrder } }));
    store.on('setDataOrderStore', ({ orderStore }, obj) => {
        return {
            orderStore: {
                ...orderStore,
                ...obj,
                delivery_address: obj?.delivery_address ? obj?.delivery_address : orderStore.delivery_address,
                status: obj?.status ? obj?.status : orderStore.status,
            }
        }
    }
    )
    //------------------------------------------------------------------------------------------------------------------
    const initListOrdersLk = {
        tableBodyData: [],// list orders
        dateFilterData: {
            created_at__lte: new Date(),
            created_at__gte: initialDate
        },
        count: 0,
        currentPage: 1,
        searchOrderForFio: '',
        status: null,
        isLoadingOrders: false,
    }
    store.on('@init', () => ({ listOrdersLk: initListOrdersLk }))
    store.on('setListOrders', ({ listOrdersLk }, obj, { dispatch }) => ({
        listOrdersLk: {
            ...listOrdersLk,
            ...obj,
        }
    }))


    //------------------------------------------------------------------------------------------------------------------
    const initialListOrdersStore = {
        count: null,
        results: [],
        currentPage: 1,
    }

    store.on('@init', () => ({ listOrdersStore: initialListOrdersStore }));

    store.on('setListOrdersStore', ({ listOrdersStore }, obj) => ({
        listOrdersStore: { ...obj }
    }));

    store.on('getListOrdersStore', async ({ listOrdersStore }, obj, { dispatch }) => {
        const resList = await orderApi.listOrderItem();
        return dispatch('setListOrdersStore', { ...resList, results: [...resList.results] })
    })
    //------------------------------------------------------------------------------------------------------------------

}


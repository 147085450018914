import AbstractBaseApi from "./AbstractBaseApi";
import UserApi from "./UserApi";
import ContentApi from "./ContentApi";
import ProfileApi from "./ProfileApi";
import CartApi from "./CartApi";
import OrderApi from "./OrderApi";
import ErrorApi from "./ErrorApi/error";

class Api extends AbstractBaseApi  {
    constructor(MAIN_URL){
        super(MAIN_URL)
        this.userApi = new UserApi(MAIN_URL);
        this.contentApi = new ContentApi(MAIN_URL);
        this.profileApi = new ProfileApi(MAIN_URL);
        this.cartApi = new CartApi(MAIN_URL);
        this.orderApi = new OrderApi(MAIN_URL);
        this.errorApi = new ErrorApi(MAIN_URL_ERR);
    }

  getPage = async (params, axiosParams) => {
    try{
      const slug = !!params.url ?
      params.url === '/ru'? '/' : params.url === '/en'? '/' : params.url : '/';
          const res = await this.get(`/content/page${slug}`, params, axiosParams);

          return res.data;
      }catch(err){
          console.log(`ERROR `, err)
          return err;
      }
  }

  getPDFFile = async (params, axiosParams) => {
    try{
          const res = await this.getPDF(params.url, params, axiosParams);

          return res.data;
      }catch(err){
          console.log(`ERROR `, err)
          return err;
      }
  }

  getSearch = async (params = {}) => {
      try{
          const res = await this.get('/content/search/', params);
          return res.data;
          // return searchContentSerializer(res.data.results);
      }catch(err){
          new Error('Error has was request getSearch');
          return console.log('error message: ', err.message)
      }
  };


  getUserBalance = async (params) => {
    const res = await this.post('/user/get_user_balance/', params);
    return res.data;
  };
  getUserStatusReload = async (params) => {
    try{
      const res = await this.get('/user/change_reload_status/', params);
      console.log(res)
      return res.data;
    }catch(err){
      console.log(err)
    }
  };

  setPassword = async (params) => {
    const res = await this.post('/user/set_password/', params);
    return res.data;
  };

  restorePassword = async (params) => {
    const res = await this.post('/user/restore_password/', params);
    return res.data;
  };

  restorePasswordSetPassword = async (params) => {
    const res = await this.post('/user/restore_password_set_password/', params);
    return res.data;
  };
  getUser = async (params) => {
    const res = await this.get('/user/', params);
    return res.data;
  };
  createUser = async (params) => {
    const res = await this.post('/user/', params);
    return res.data;
  };

  getShop = async (params = {}) => {
    const res = await this.get('/shop/shop/', params);
    return res.data;
  };

  updateUser = async (id, params) => {
    const res = await this.patch(`/user/${id}/`, params);
    return res.data;
  };

  getCurrentUser = async () => {
    const res = await this.get('/user/current/');
    return res.data;
  };

  getMoreThanFiveProductsOfSearch = async (params = {}) => {
    const res = await this.get('/content/search/', params);
    return res.data;
  };

  getTotalPrice = async (cart) => {
    let promise = new Promise((resolve, reject) => {
      const total = Object.values(cart).reduce(
        (sum, item) => sum + Number(item.product.price) * Number(item.params.count),
        0,
      );
      setTimeout(() => resolve(total), 300);
    });
    return promise;
  };

  registerIosToken = async (params) => {
    const res = await this.post('/web_push_front/', params);
    return res.data;
  };

}

const MAIN_URL = "https://back.ftownpl.com/api/v1" //process.env.RAZZLE_APP_API_URL;
const MAIN_URL_ERR = "http://91.218.229.240:8050" //process.env.RAZZLE_APP_API_URL;
///https://back.ftownpl.com/api/v1/user/change_reload_status
const api = new Api(MAIN_URL)

export default api;

import api from "../../api/api";
import { serializeNotifications } from "../../api/ProfileApi/serializers";
import { getCookie, unicArrFromObject } from "../../helpers/helpers";
import Text from "../../helpers/Text";
import { errorAlertIcon } from "../../images";
import { textErrorMessage } from "../modalStorage/modalWindow/modalWindow";

let wsCorrespondence = {};
let wsNotification = {};

export const websocket = store => {
  const orderApi = api.orderApi;

  store.on('@init', () => ({
    correspondence: {
      order_chat: [],
      order_items_chat: [],
    }
  }));

  store.on('chatOrdersMessage/set', ({ correspondence }, obj, { dispatch }) => {

    // debugger
    const order_items_chat = obj?.order_items_chat;
    const order_chat = obj?.order_chat;
    const newOrderItemsChat = order_items_chat.reduce((acum, cur, index) => {
      const val = cur?.chat_order_items;
      const newChatOrderItems = val.reduce((acum, cur, index) => {
        acum = [...acum, {
          ...cur,
          images: [...cur.images]
        }]
        return acum;
      }, [])
      const chat_order_items = [...newChatOrderItems]
      acum = [...acum, {
        chat_order_items: [...chat_order_items],
        item_id: cur.item_id
      }]
      return acum;
    }, [])

    const newOrder_chat = order_chat.reduce((acum, cur) => {
      acum = [...acum, {
        ...cur,
        images: [...cur.images]
      }]
      return acum;
    }, [])

    return {
      correspondence: {
        ...correspondence,
        order_chat: [...newOrder_chat],
        order_items_chat: [...newOrderItemsChat]
      }
    }
  });

  store.on('@init', () => ({
    statusChat: {
      chat: false,
    }
  }));

  store.on('setCorrespondenceStatus', ({ _ }, obj) => ({
    statusChat: {
      chat: obj.chat
    }
  }));

  store.on('correspondence', async ({ context }, obj, { dispatch }) => {
    const order_id = context.init_state.page_info.id;
    const urlChatItem = `wss://back.ftownpl.com:8443/ws/chat/${order_id}/?token=$${getCookie('ft_token')}`;

    if (wsCorrespondence?.hasOwnProperty('url') && wsCorrespondence.url !== urlChatItem) {
      wsCorrespondence.close(1000, "Deliberate disconnection");
    }

    if (wsCorrespondence?.readyState === WebSocket.OPEN || wsCorrespondence?.readyState === WebSocket.CONNECTING) {
      return;
    }

    const gettingData = () => {
      if (!wsCorrespondence) return;
      wsCorrespondence.onmessage = e => {                //подписка на получение данных по вебсокету
        const message = JSON.parse(e.data);
        if (Object.keys(message)) {
          dispatch('chatOrdersMessage/set', message)
        }
      }
    }

    const reconnectWS = () => {
      if (wsCorrespondence?.readyState > 1) {
        setupWS();
      }
    }

    const setupWS = (status = null) => {
      if (!!getCookie('ft_token') && !!wsCorrespondence) {
        wsCorrespondence = new WebSocket(urlChatItem);

        if (status !== null) {
          if (wsCorrespondence.readyState === WebSocket.OPEN || wsCorrespondence.readyState === WebSocket.CONNECTING) {
            wsCorrespondence.close(1000, "Deliberate disconnection");
            return
          }
        }

        wsCorrespondence.onopen = (open) => {
          dispatch('setCorrespondenceStatus', { chat: true })
          gettingData();
        }
        wsCorrespondence.onclose = (close) => {
          dispatch('setCorrespondenceStatus', { chat: false })
          if (close.wasClean) {
            console.log(`[close] Соединение закрыто чисто, код=${close.code} причина=${close.reason}`);
          } else {
            // например, сервер убил процесс или сеть недоступна
            // обычно в этом случае event.code 1006
            console.log(`[close] Соединение прервано, код=${close.code}`);
            const timer = setTimeout(() => {
              console.log('error reconnect ws 1 second')
              setupWS();
              return ()=> clearTimeout(timer);
            }, 1000);
          }
        }

        wsCorrespondence.onerror = (error) => {
          // const timer = setTimeout(() => {
          //   console.log('error reconnect ws 1 second', error)
          //   setupWS();
          //   return ()=> clearTimeout(timer);
          // }, 1000);
        };
      }
    }

    setupWS();

    document.addEventListener("visibilitychange", () => {
      if (!document.hidden) {
        reconnectWS();    
      }
    });

    //if(obj && Object.keys(obj).length && obj.hasOwnProperty('isOpenWebsocket')){
    //  if(obj?.isOpenWebsocket){
    //    dispatch('setCorrespondenceStatus', {chat: false})
    //    setupWS(obj?.isOpenWebsocket);
    //  }else{
    //    dispatch('setCorrespondenceStatus', {chat: true})
    //    setupWS(obj?.isOpenWebsocket);
    //  }
    //}
  });

  store.on('closeCorrespondence', async ({ context }, obj, { dispatch }) => {
    if (wsCorrespondence?.url) {
      wsCorrespondence.close(1000, "Deliberate disconnection");
    }
  });

  store.on('changeStateIsnewMessage', async ({ context, correspondence, closeModalState }, obj, { dispatch }) => {

    try {
      const { idProduct } = obj;
      const messagesItemsOrderFromContext = correspondence?.order_items_chat;
      let massiveIdIsnew = []
      let resChangeIsnew = []
      const newMessage = messagesItemsOrderFromContext.map(item => {
        if (item.item_id === idProduct) {
          item.chat_order_items.filter(el => {
            if (el.is_new) massiveIdIsnew.push(el.message_id)
          })
          item = {
            chat_order_items: item.chat_order_items.map(el => ({ ...el, is_new: false })),
            item_id: item.item_id
          }
        }
        return item
      })
      if (!!massiveIdIsnew.length) {
        resChangeIsnew = await orderApi.postCorrespondence_order_item_remake_is_new({
          order_item_id: idProduct,
          ids: massiveIdIsnew
        })
      }
      dispatch('chatOrdersMessage/set', {
        ...correspondence,
        order_items_chat: [...newMessage]
      });

      dispatch('getDataOrder')

    } catch (err) {
      console.log('ERROR change is new f(changeStateIsnewMessage) ', err);
      let error = [Text({ text: 'error-on-server' }), 'ERROR change is new f(changeStateIsnewMessage) '];
      if (err?.data) {
        const errors = err.data;
        if (typeof errors !== 'object') {
          error.push(`${errors}`)
        } else {
          error.push(`${errors[0]}`)
        }
      }
      // dispatch('fixErrorBot', { ...error, err })
      dispatch('setModalState', {
        show: true,
        content: textErrorMessage(error),
        iconImage: errorAlertIcon,
        addClass: 'modal-alert-error',
        action: {
          title: ['продолжить', null]
        },
        onClick: () => closeModalState()
      })
    }
  });

  store.on('notification', async ({ context }, obj, { dispatch }) => {
    let newContext = context;
    const urlChatItem = `wss://back.ftownpl.com:8443/ws/notifications/${obj}/?token=$${getCookie('ft_token')}`;

    if (wsNotification?.hasOwnProperty('url') && wsNotification.url !== urlChatItem) {
      wsNotification.close(1000, "Deliberate disconnection");
    }

    if (wsNotification?.readyState === WebSocket.OPEN || wsNotification?.readyState === WebSocket.CONNECTING) {
      return;
    }

    const reconnectWS = () => {
      if (wsNotification?.readyState > 1) {
        setupWS();
      }
    }

    const gettingData = () => {
      if (!wsNotification) return;
      wsNotification.onmessage = async e => {                //подписка на получение данных по вебсокету
        let message = JSON.parse(e.data);

        if (message?.notifications?.length) {
          message = serializeNotifications({ results: message.notifications })
          dispatch('setNotificationTest', { message })
        } else if (message?.notification) {
          message = serializeNotifications({ results: [message.notification] })
          dispatch('setNotificationTest', { message: { oneMessage: message.results } })
          newContext = {
            ...newContext,
            "init_state": {
              ...newContext.init_state,
              profile: {
                ...newContext.init_state.profile,
                notifications: newContext.init_state.profile.notifications + 1,
              }
            },
          }
          dispatch('context', newContext);
          if (message.results[0].message.includes('Вы пополнили баланс на')) {
            console.log('получаем обновление баланса Вы пополнили баланс на ***websoket.js')
            dispatch('updateObserverBalace');
          }
        }
      };
    };

    const setupWS = () => {
      if (!!getCookie('ft_token') && !!wsNotification) {
        wsNotification = new WebSocket(urlChatItem);

        wsNotification.onopen = (open) => {
          gettingData();
        }
        wsNotification.onclose = (close) => {
          if (close.wasClean) {
            console.log(`[close] Соединение закрыто чисто, код=${close.code} причина=${close.reason}`);
          } else {
            // например, сервер убил процесс или сеть недоступна
            // обычно в этом случае event.code 1006
            console.log(`[close] Соединение прервано, код=${close.code}`);
            const timer = setTimeout(() => {
              console.log('error reconnect ws 1 second')
              setupWS();
              return ()=> clearTimeout(timer);
            }, 1000);
            // dispatch('fixErrorBot',{
            //   action: 'web socket get onclose f(notification) part_2',
            //   close_code_connection_reset: close?.code,
            //   why_close: close?.reason,
            //   ...close
            // })
          }
        }

        wsNotification.onerror = (error) => {
          // setTimeout(() => {
          //   setupWS();
          // }, 1000);

          console.log(`[-*-*-*-*-*-*-ERROR-2*-*-*-*-*-*-*-*-*-] ${JSON.stringify(error, ["message", "arguments", "type", "name"])}`);
          // dispatch('fixErrorBot',{ ws: JSON.stringify(wsNotification),decoder: JSON.stringify(error)})
        };
      }
    }

    setupWS();

    document.addEventListener("visibilitychange", () => {
      if (!document.hidden) {
        reconnectWS();
      }
    });
  });

  store.on('closeNotification', async ({ context }, obj, { dispatch }) => {
    if (wsNotification?.url) {
      wsNotification.close(1000, "Deliberate disconnection");
    }
  });

  store.on('@init', () => ({
    notificationTest: {
      count: 0,
      selectItemsNotice: [],
      isSelectAllItems: false,
      results: []
    }
  })
  )
  store.on('setStoreNotifications', ({ _ }, obj) => ({ notificationTest: { ...obj } })
  )

  store.on('setNotificationTest', ({ notificationTest }, obj, { dispatch }) => {
    let { message } = obj;
    if (message?.oneMessage) {
      message = {
        ...notificationTest,
        results: [message.oneMessage[0], ...notificationTest.results]
      }
    }

    dispatch('setStoreNotifications', {
      ...notificationTest,
      count: message?.results?.length,
      results: message?.results?.map(el => ({
        ...el,
        message: el.message.replace(/https:\/\/ftownpl.com/isg, '/orders')
      })).filter(el => message.results.length = 30),
      selectItemsNotice: [],
    });
  });
}

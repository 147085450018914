import React, { useEffect, useRef, useState } from 'react';
import { Player, BigPlayButton, LoadingSpinner } from 'video-react';
import { redWomen } from '../../images';
import style from './styles/video-view.module.scss';

const ViewsVideo = ({ 
  preview = redWomen, 
  video,
  autoPlay = false, 
}) => {

  return (
    <div className={style['preview-video']}>
      <Player 
        className="news-details-page__slider_item" 
        style={{
          backgroundImage:
            'url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkZGRkZGIiBoZWlnaHQ9IjE4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTE5IDloLTRWM0g5djZINWw3IDcgNy03ek01IDE4djJoMTR2LTJINXoiLz4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'absolute',
            zIndex: '-1'
        }}
        fluid={false} 
        poster={preview} 
        src={video}
        controls={false}  
        width={'100%'}
        height={'100%'}
        muted={true}
        autoPlay={autoPlay} 
        preLoad={"auto"}
        loop={true}

        clickable={false}
      >
        {/* <BigPlayButton position="center"></BigPlayButton> */}
        {/* <LoadingSpinner /> */}
      </Player>
    </div>
  );
};

export default React.memo(ViewsVideo);

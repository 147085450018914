import React, { useState } from 'react';
import ImageUpload from '../../../ImageUpload';
import Text from '../../../../helpers/Text';

import style from '../styles/addreview.module.scss';
import { rus_to_latin } from '../../../../helpers/helpers';
import { garbageIcon } from '../../../../images';
import Icon from '../../../Icon';

const FormAddReviewUploadImage = ({ 
  accept, 
  label, 
  setFieldValue, 
  countFiles,
  name , 
  multiple = '', 
  textButton, 
  onBlur,
  className,
  onChange = () => {},
  callback = () => { },
}) => {
  const fileInputRef = React.useRef(null);
  const [images, setImages] = useState([]);
  return (
    <div className={style['productreviews__form-upload']}>
      <p className={style['productreviews__form-upload-desc']}>
        <Text text={'photo.or.video'} />:
      </p>
      <ImageUpload
        setFieldValue={(e)=>console.log({e})}//?! необходимо допилить сброс изображения
      >
        {({ preview, onSelectFile, selectedFile, onSelectFiles, isDragActive, getRootProps }) => {
          const deleteImage = (elDel) => {
            setImages(c => [...c.filter((el, i) => i !== elDel)])
            setFieldValue([name], [...images.filter((el, i) => i !== elDel)]);
            // callback([...images.filter((el, i) => i !== elDel)]);
            onSelectFiles([...images.filter((el, i) => i !== elDel)]);
          }

          if (preview === undefined) preview = []
          if (!Array.isArray(preview)) {
            preview = [preview];
          }

          return (
            <>
              <ul className={style['files-uploud__form-upload-list']}>
                {
                  preview.map((el, i) => {
                    return (
                      <li key={i} className={style['files-uploud__form-upload-item']}>
                        <img
                          crossOrigin="anonymous"
                          className={style['files-uploud__form-upload-image']}
                          src={el}
                        />
                        <div
                          className={style['files-uploud__form-image--delete']}
                          onClick={() => deleteImage(i)}
                        >
                          <Icon
                            className={style['files-uploud__form-image-icon--delete']}
                            src={garbageIcon}
                            width={15}
                            height={15}
                          />
                        </div>
                      </li>
                    );
                  })
                }
              </ul>
              <div 
                // {...getRootProps()} 
                className={style['productreviews__form-addprod-image']}
              >
                <div className={style['productreviews__form-addprod-image-wrap']}>
                  <div 
                    className={style['productreviews__form-upload-button']}
                  >
                    <Text text={'attach'} />
                    <input
                      multiple
                      ref={fileInputRef}
                      className={'hidden'}
                      id="image"
                      type="file"
                      accept=".png, .jpg, .jpeg, .mp4"
                      name={'image'}
                      onChange={(e) => {
                        let files = e.currentTarget.files;
                        let newFiles = [];
                        if (files.length) {
                          let allFiles = [...images];
                          for(let i = 0; i< files.length; i++ ){
                            newFiles = [new File([files[i]], rus_to_latin(files[i].name), {type: files[i].type} )]
                            allFiles.push(...newFiles);
                          }                        
                          
                          const maxCountFile = allFiles.length;
                          if( maxCountFile > 8 ) {
                            alert('Привышен лимит добавления файлов, максимальное количество 8');
                            allFiles = allFiles.slice(0,8);
                            files = [...files].slice(0,8);
                          }
                          setImages(allFiles)
                          setFieldValue([name], allFiles);
                          setFieldValue('func',onSelectFiles);
                          setFieldValue('funcImage',setImages);
                          onSelectFiles(allFiles);
                        }

                      }}
                    />
                    </div>
                </div>
              </div>
            </>
          );
        }}
      </ImageUpload>
    </div>
  );
};

export default React.memo(FormAddReviewUploadImage);

import api from '../../api/api';
import { errorAlertIcon } from '../../images';
import { textErrorMessage } from '../modalStorage/modalWindow/modalWindow';


const search = store => {

    store.on('@init', () => ({ search: {} }))
    store.on('@init', (_, text = '') => ({ textSearch: text }))
    store.on('@init', (_, text = '') => ({ textSearchStore: text }))

    store.on('resSearch', ({ search }, q) => ({ search: q }))

    store.on('changeTextSearch', (_, t) => ({ textSearch: t }))
    store.on('changeTextSearchStore', (_, t) => ({ textSearchStore: t }))

    store.on('setInputSearchValue', async ({ context, search, closeModalState }, value, { dispatch }) => {
        try {
            dispatch('changeTextSearch', value)
            dispatch('changeTextSearchStore', value)
            const { role } = context.init_state.profile;

            let params = {
                q: value,
                role: role,
            }

            const resultSearch = await api.getSearch(params)
            dispatch('resSearch', { ...resultSearch, currentPage: 1 });
        } catch (err) {
            console.log('ERROR set input search value f(setInputSearchValue)', err);
            let error = [Text({ text: 'error-on-server' }), 'ERROR set input search value f(setInputSearchValue)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })
    store.on('getProductSearch', async ({ context, textSearchStore, closeModalState }, obj, { dispatch }) => {
        try {
            const { role } = context.init_state.profile;

            let params = {
                page: obj?.page || 1,
                q: textSearchStore,
                page_size: 30,
                role: role,
            }
            const resultSearch = await api.getSearch(params)
            dispatch('resSearch', { ...resultSearch, currentPage: obj?.page ?? 1 });

        } catch (err) {
            console.log('ERROR GET PRODUCT SEARCH f(getProductSearch)', err);
            let error = [Text({ text: 'error-on-server' }), 'ERROR GET PRODUCT SEARCH f(getProductSearch)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })
}

export { search };
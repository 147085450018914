import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { arrowRightRoze, zoom } from '../../images';
import Button from '../Button';
import Icon from '../Icon';

import style from './styles/photo-view.module.scss';

const ViewsImage = ({
  image,
  url = '#',
  closeModalState,
  controll = false,

}) => {
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(0);


  const handlerClickControlButtonRotate = (value) => {
    setRotate(c => c + value)
  }
  const handlerClickControlButtonScale = (value) => {
    if (scale === 1200) value = -1200
    setScale(c => c + value)
  }

  return (
    <React.Fragment>
      {
        controll ?
          <div
            className={style['preview-image__container-control-button']}
          >
            <Button
              onClick={() => handlerClickControlButtonRotate(-90)}
            >
              <Icon
                src={arrowRightRoze}
                width={30}
                height={30}
                rotateY={175}
                rotate={75}
              />

            </Button>
            <Button
              onClick={() => handlerClickControlButtonScale(1200)}

            > 
              <Icon
                src={zoom}
                width={30}
                height={30}
              />
            </Button>
            <Button
              onClick={() => handlerClickControlButtonRotate(90)}

            > 
              <Icon
                src={arrowRightRoze}
                width={30}
                height={30}
                rotate={75}
              /> 
            </Button>
          </div>
          : null
      }
      <div
        className={style['preview-image__container-preview']}
      >
        <div className={style['preview-image']}>
          <NavLink to={
              url?.includes('https://')? 
                  window.open(url) 
                  : url? 
                    `/${url}` 
                    : console.log('no link image')
              } 
              onClick={() => { closeModalState() }}>
            <img
              src={image}
              style={{
                // backgroundImage: `url(${image})`,
                display: 'block',
                transform: `rotate(${rotate}deg)`,
                maxWidth: `${!scale? '100%' : scale + 'px'}`

              }}
              //scale(${scale}) translate(${scale !==1 ? 30 : 0}%, ${scale !==1 ? 30 : 0}%)
            />
            {/* <div
              style={{
                backgroundImage: `url(${image})`,
                transform: `rotate(${rotate}deg) scale(${scale})`
              }}
            ></div> */}
          </NavLink>
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(ViewsImage);

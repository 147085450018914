import React, { useEffect } from 'react';
import Icon from '../Icon';
import { arrowTop } from '../../images';

import style from './styles/goto.module.scss';

const ButtonScrollTop = () => {
    const goToTop = () => {
        // document.querySelector('.layout-module__layout__container___WJXh4')?.scrollTo(0, 0)
        document.querySelector('.goto').scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    const handleScroll = () => {
        console.log('change')
    };

    return (
        <React.Fragment>
            <div
                className={style['go-to__container']}
            >
                <Icon
                    src={arrowTop}
                    width={80}
                    height={80}
                    onClick={goToTop}
                />
            </div>
        </React.Fragment>
    )
}
export default ButtonScrollTop;
import { DEFAULT_CURRENCIES, COOKIE_KEYS, ROLE, ONE_YEARS } from "../../const";
import { getCookie, setCookie } from "../../helpers/helpers";


export const currency = store => {

    store.on('@init', ()=>({currency: getCookie(COOKIE_KEYS.CURRENCIES)?.toLocaleUpperCase() ?? DEFAULT_CURRENCIES}))
    store.on('setCurrency', ({ context , inStockParams, valueFilters, paramsWishList, countWishList}, obj, {dispatch})=>{
        const getURL = window.location.href;
        const getUrlData = new URL(getURL);

        switch (getUrlData.pathname){

            case '/catalog':
                    dispatch('getCatalog', valueFilters);
                    dispatch('getYouAlreadyWatch',{currency:obj})                    
                break;
            case '/':
                    dispatch('getCatalogIsInStock', {...inStockParams});
                break;           
            case '/wishlist':
                    if( !countWishList ) {
                        dispatch('updateDataRecommendedStore',{currency:obj});
                        break;
                    }
                    dispatch('getWishlist', {...paramsWishList});
                break;
            case '/cart':
                    const countCart = context.init_state.profile.cart;
                    if( !countCart ) {
                        dispatch('updateDataRecommendedStore',{currency:obj});
                        break;
                    }
                    
                    dispatch('getDataCart', {isLoadingCart : true});
                    dispatch('updateGetContextPage',{url: getUrlData.pathname});

                break;
            case '/order':
                    dispatch('getDataCart');
                    dispatch('updateGetContextPage',{url: getUrlData.pathname});
                break;
            case '/orders':
                    dispatch('updateGetContextPage',{url: getUrlData.pathname});
                break;

                default: 
                    
                    const productId = getUrlData.pathname.split('-')[1]; 
                    if(getUrlData.pathname.includes('/product-')){
                        dispatch('getProductDetails',{productId})
                        dispatch('updateDataRecommendedStore',{currency:obj})                    
                        dispatch('getYouAlreadyWatch')
                        dispatch('updateGetContextPage',{url: getUrlData.pathname});                    
                    }
                    if(getUrlData.pathname.includes('/orders/20')){
                        dispatch('updateGetDataOrder');            
                    }
                    break;
        }
        if(context.init_state.profile.role !== ROLE.UNREGISTRED){
            dispatch('getBalace',{currency: obj?.toLocaleUpperCase() ?? DEFAULT_CURRENCIES})
        }
        setCookie(COOKIE_KEYS.CURRENCIES, obj?.toLocaleUpperCase() ?? DEFAULT_CURRENCIES, ONE_YEARS)
       return ({        
        currency: obj?.toLocaleUpperCase() ?? DEFAULT_CURRENCIES
       })
    })
}